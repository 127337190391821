import {
	Accordion as MUIAccordion,
	AccordionSummary,
	AccordionDetails,
	AccordionProps,
} from "@mui/material"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import styled, { useTheme } from "styled-components"
import { mediaQuery } from "@/util/stylingUtils"
import { useTranslation } from "react-i18next"

const Accordion: React.FC<
	AccordionProps & {
		children: React.ReactNode
		title: JSX.Element | string
		icon?: JSX.Element
	}
> = ({ children, icon, title, ...props }) => {
	const theme = useTheme()
	const { t } = useTranslation()

	const isInfoOrContact = title === "venueInfo" || title === "contact_plural"

	if (isInfoOrContact) {
		return (
			<HeaderContent>
				{icon}
				<Text>{t(title)}</Text>
			</HeaderContent>
		)
	} else {
		return (
			<StyledAccordion {...props}>
				<StyledAccordionSummary
					expandIcon={<FontAwesomeIcon color={theme.techPackIcon} icon="chevron-up" />}
					// v5 hack to remove ui jump when expanding/collapse
					sx={{
						"&.Mui-expanded": {
							minHeight: 0,
						},
						"&.MuiAccordionSummary-root": {
							padding: 0,
						},
						"& .MuiAccordionSummary-content.Mui-expanded": {
							// margin from https://github.com/mui-org/material-ui/blob/cc0e2ab63e8be9ec4d51a49bfde17ef28fc77b9c/packages/mui-material/src/AccordionSummary/AccordionSummary.js#L64-L64
							margin: "12px 0",
						},
					}}
				>
					<HeaderContent>
						{icon}
						<Text>{t(title)}</Text>
					</HeaderContent>
				</StyledAccordionSummary>
				<AccordionDetails>{children}</AccordionDetails>
			</StyledAccordion>
		)
	}
}

const StyledAccordion = styled(MUIAccordion)`
	color: ${({ theme }) => theme.techPackText} !important;
	width: 100%;

	> :not(:first-child) {
		margin-top: -20px !important;
	}

	> :first-child {
		margin-top: -10px !important;
	}

	& .MuiAccordionSummary-root {
		color: ${({ theme }) => theme.techPackText} !important;
	}
`
const StyledAccordionSummary = styled(AccordionSummary)`
	display: flex;
	align-items: center;
`
const HeaderContent = styled.div`
	display: flex;
	align-items: center;
`
const Text = styled.p`
	font-family: "Signika-Regular" !important;
	font-size: 22px;
	margin-left: 16px !important;

	${mediaQuery("lg")`
		font-size: 18px;
	`}
`

export default Accordion
