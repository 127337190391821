import { mediaQuery } from "@/util/stylingUtils"
import LoadingPage from "./LoadingPage"
import { Outlet, useLocation, useNavigate } from "react-router-dom"
import { useVenuesContext } from "@/context/venues"
import { MobileHeader } from "../organisms/MobileHeader"
import { NavigationBar } from "../organisms/NavigationBar"
import { NavigationContextProvider } from "@/context/navigation"
import { styled } from "@mui/material"
import { useEffect } from "react"
import { useUserContext } from "@/context/user"

const App = () => {
	const location = useLocation()
	const navigate = useNavigate()
	const { isLoading, getVenuesSuccess, venues } = useVenuesContext()
	const { getUserLoading } = useUserContext()

	useEffect(() => {
		if (getVenuesSuccess && venues.length === 0 && location.pathname !== "/profile") {
			navigate("/profile")
		}
	}, [location, getVenuesSuccess, venues, navigate])

	return (
		<NavigationContextProvider>
			<LoadingPage isLoading={isLoading || getUserLoading} initialLoading={true}>
				<ContainerOrganizer>
					<MobileHeader />
					<NavigationBar />

					<MainContainer id="mainContainer">
						<Outlet />
					</MainContainer>
				</ContainerOrganizer>
			</LoadingPage>
		</NavigationContextProvider>
	)
}

const ContainerOrganizer = styled("div")`
	display: flex;
	width: 100%;
	height: 100%;
	overflow-y: hidden;

	background: ${({ theme }) => theme.colorPalette.surface.bright};
	flex-direction: row;

	${mediaQuery("md")`
		flex-direction: column;
	`}
`

const MainContainer = styled("div", {
	shouldForwardProp: prop => prop !== "navigationHidden",
})<{ navigationHidden?: boolean }>`
	overflow-y: hidden;
	position: fixed;

	top: 0;
	bottom: 0;
	left: 288px;
	right: 0;

	padding: 0px;
	width: auto;
	height: auto;

	background: ${({ theme }) => theme.colorPalette.surface.bright};
	color: ${({ theme }) => theme.text};

	${mediaQuery("md")`
		top: 60px;
		left: 0;
	`}
`

export default App
