import { CloseOutlined, HighlightOff } from "@mui/icons-material"
import {
	Autocomplete as MuiAutocomplete,
	AutocompleteProps as MuiAutocompleteProps,
	styled,
	useTheme,
	TextField as MuiTextField,
	Popper as MuiPopper,
	createFilterOptions,
	AutocompleteRenderInputParams,
	AutocompleteRenderGetTagProps,
	Chip,
	css,
} from "@mui/material"
import { useRef, useState } from "react"
import { MenuItem } from "../atoms/MenuItem"
import useDarkMode from "@/hooks/useDarkMode"

interface AutocompleteProps<
	Value extends { label: string; support?: string } | string,
	Multiple extends boolean | undefined,
	DisableClearable extends boolean | undefined,
	FreeSolo extends boolean | undefined,
> extends Omit<MuiAutocompleteProps<Value, Multiple, DisableClearable, FreeSolo>, "renderInput"> {
	/** Displays Label on top left legend */
	selectLabel?: string
	/** Placeholder value when no text is input */
	placeholder?: string

	required?: boolean

	error?: boolean
}
const Autocomplete = <
	Value extends { label: string; support?: string } | string,
	Multiple extends boolean | undefined,
	DisableClearable extends boolean | undefined,
	FreeSolo extends boolean | undefined,
>({
	selectLabel,
	placeholder,
	multiple,
	value,
	required = false,
	error = false,
	...rest
}: AutocompleteProps<Value, Multiple, DisableClearable, FreeSolo>) => {
	const { colorPalette } = useTheme()
	const { darkMode } = useDarkMode()
	const [focused, setFocused] = useState(false)
	const inputRef = useRef<HTMLInputElement>(null)

	const filterOptions = createFilterOptions<Value>({
		stringify: option => {
			if (typeof option === "string") {
				return option
			}

			return option.label + (option.support ? ` ${option.support}` : "")
		},
	})

	function renderOption(
		props: React.HTMLAttributes<HTMLLIElement>,
		option:
			| {
					label: string
					support?: string
			  }
			| string,
	) {
		return typeof option === "string" ? (
			<MenuItem {...props} key={option}>
				{option}
			</MenuItem>
		) : (
			<MenuItem {...props} supportText={option.support} key={JSON.stringify(option)}>
				{option.label}
			</MenuItem>
		)
	}

	function getHelperText(optionValue?: any) {
		if (!optionValue || typeof optionValue === "string" || Object.hasOwn(optionValue, "length")) {
			if (!optionValue && !focused && placeholder) {
				return <StyledPrimaryText isPlaceholder={true}>{placeholder}</StyledPrimaryText>
			}
			return undefined
		}

		if (optionValue.support !== undefined && !focused) {
			return (
				<>
					<StyledPrimaryText>{optionValue.label}</StyledPrimaryText>
					<StyledSupportText>{optionValue.support}</StyledSupportText>
				</>
			)
		}

		return undefined
	}

	const renderTags = (value: Value[], getTagProps: AutocompleteRenderGetTagProps) => {
		return value.map((option: Value, index: number) => {
			const optionValue = typeof option === "string" ? option : option.label
			const { key, ...rest } = getTagProps({ index })
			return (
				<CustomTag
					key={`${key} ${optionValue}`}
					variant="outlined"
					label={optionValue}
					deleteIcon={<CloseOutlined />}
					{...rest}
				/>
			)
		})
	}

	const renderInput = (params: AutocompleteRenderInputParams) => {
		const helperText = getHelperText(value)
		return (
			<StyledTextField
				required={required}
				error={error}
				ref={inputRef}
				hasSupportText={
					value !== undefined &&
					value !== null &&
					typeof value !== "string" &&
					Object.hasOwn(value, "support")
				}
				fieldFocused={focused}
				{...params}
				label={selectLabel}
				helperText={helperText}
				FormHelperTextProps={{
					component: HelperText,
				}}
				onFocus={() => setFocused(true)}
				onBlur={() => setFocused(false)}
				darkMode={darkMode}
			/>
		)
	}

	return (
		<StyledAutocomplete
			autoComplete
			value={value}
			limitTags={1}
			PopperComponent={StyledPopper}
			clearIcon={<HighlightOff htmlColor={colorPalette.surface.onVariant} />}
			multiple={multiple}
			filterOptions={filterOptions}
			renderInput={renderInput}
			renderOption={renderOption}
			renderTags={renderTags}
			filterSelectedOptions
			{...rest}
		/>
	)
}

const StyledAutocomplete: any = styled(MuiAutocomplete)`
	width: ${({ fullWidth }) => (fullWidth ? "100%" : "272px")};
	user-select: none;
	cursor: pointer;

	.MuiAutocomplete-popupIndicator {
		display: none;
		visibility: hidden;
		opacity: 0;
	}

	.MuiAutocomplete-clearIndicator {
		opacity: 1;
		visibility: visible;
		transform: opacity 150ms ease-out, visibility 150ms ease-out;
	}

	&:has(.MuiInputBase-input[value=""]) .MuiAutocomplete-popupIndicator {
		visibility: visible;
		opacity: ${({ multiple }) => (multiple ? 0 : 1)};
		display: ${({ multiple }) => (multiple ? "none" : "inline-flex")};
	}

	&.Mui-expanded .MuiButtonBase-root.MuiIconButton-root.MuiAutocomplete-popupIndicator {
		visibility: hidden;
		opacity: 0;
		display: none;
	}

	&.Mui-expanded {
		box-shadow: 0px 2px 6px 2px #00000026 0px 1px 2px 0px #0000004d;
	}
`

const CustomTag = styled(Chip)`
	height: 36px;
	background-color: ${({ theme }) => theme.colorPalette.primary.fixed.dim};

	font: 400 14px/20px Signika-Regular;
	color: ${({ theme }) => theme.colorPalette.primary.fixed.on};

	& .MuiChip-deleteIcon {
		color: ${({ theme }) => theme.colorPalette.primary.fixed.on};
		height: 16px;
		width: 16px;
	}

	& .MuiChip-label {
		max-width: 110px;
	}
`

const HelperText = styled("div")`
	pointer-events: none;
	position: absolute;
	top: 0;
	left: 0;
	height: 100%;
	width: 100%;
	visibility: visible;
	opacity: 1;

	&.MuiFormHelperText-root {
		margin: 0px;
		padding: 6px 0px 6px 16px;
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: center;
	}
`

const StyledPrimaryText = styled("p")<{ isPlaceholder?: boolean }>`
	font: 400 16px Signika-Regular;
	letter-spacing: 0.5px;
	width: 100%;
	line-height: 24px;
	text-align: left;
	color: ${({ theme, isPlaceholder }) =>
		isPlaceholder ? theme.colorPalette.surface.onVariant : theme.colorPalette.surface.on};
`

const StyledSupportText = styled("p")`
	width: 100%;
	font: 500 14px Signika-Regular;
	line-height: 20px;
	letter-spacing: 0px;
	color: ${({ theme }) => theme.colorPalette.surface.onVariant};
`

const StyledPopper = styled(MuiPopper)`
	width: 100%;
	padding-top: 0px;
	overflow-y: hidden;

	visibility: ${({ open }) => (open ? "visible" : "hidden")};
	opacity: ${({ open }) => (open ? 1 : 0)};
	transition: opacity 285ms cubic-bezier(0.4, 0, 0.2, 1),
		visibility 285ms cubic-bezier(0.4, 0, 0.2, 1);

	& .MuiPaper-root {
		background-color: ${({ theme }) => theme.colorPalette.surface.container.value};
		border-radius: "4px";
		overflow-y: hidden;

		.MuiAutocomplete-noOptions {
			color: ${({ theme }) => theme.colorPalette.surface.on};
		}

		.MuiAutocomplete-listbox {
			overflow-y: scroll;
			max-height: 200px;
			padding: 0;
			box-shadow: 0px 2px 6px 2px #00000026, 0px 1px 2px 0px #0000004d;
		}
	}
`

const StyledTextField = styled(MuiTextField, {
	shouldForwardProp: prop =>
		prop !== "hasSupportText" && prop !== "fieldFocused" && prop !== "darkMode",
})<{
	hasSupportText: boolean
	fieldFocused: boolean
	darkMode: boolean
}>`
	user-select: none;
	cursor: pointer;
	padding: 0px;
	${({ fieldFocused }) =>
		!fieldFocused
			? css`
					max-height: "56px";
					overflow-y: "clip";
			  `
			: ""}

	& .MuiAutocomplete-endAdornment svg {
		color: ${({ darkMode }) => (darkMode ? "#FFFFFF" : "#000000")};
	}

	.MuiFormLabel-root {
		color: ${({ theme }) => theme.colorPalette.surface.onVariant};
		font-family: Signika-Regular;
		transform: translate(14px, -9px) scale(0.75);
		-webkit-transform: translate(14px, -9px) scale(0.75);

		opacity: ${({ disabled }) => (disabled ? 0.38 : 1)};

		&.Mui-error {
			color: ${({ theme }) => theme.colorPalette.error.value};

			.MuiFormLabel-asterisk {
				color: ${({ theme }) => theme.colorPalette.error.value};
			}
		}

		&.Mui-focused {
			color: ${({ theme }) => theme.colorPalette.primary.value};

			&.Mui-error {
				color: ${({ theme }) => theme.colorPalette.error.value};

				.MuiFormLabel-asterisk {
					color: ${({ theme }) => theme.colorPalette.error.value};
				}
			}
		}
	}

	.MuiInputBase-root {
		padding: 4px 0px 4px 16px;
		min-height: 56px;
		gap: 0px;

		.MuiOutlinedInput-notchedOutline {
			border: 1px solid ${({ theme }) => theme.colorPalette.outline.value}D9;

			legend {
				max-width: 100%;
			}
		}

		&.Mui-focused .MuiOutlinedInput-notchedOutline {
			border: 2px solid ${({ theme }) => theme.colorPalette.primary.value};
		}

		.MuiInputBase-input {
			font: 400 16px Signika-Regular;
			padding: 0px;
			line-height: 24px;
			caret-color: ${({ theme }) => theme.colorPalette.primary.value};
			color: ${({ theme }) => theme.colorPalette.surface.on};

			opacity: ${({ hasSupportText, fieldFocused }) => (hasSupportText && !fieldFocused ? 0 : 1)};
		}

		& > span.MuiAutocomplete-tag {
			color: ${({ theme }) => theme.colorPalette.surface.on};
		}
	}

	.Mui-disabled {
		.MuiOutlinedInput-notchedOutline {
			border-color: ${({ theme }) => theme.colorPalette.surface.onVariant};
			opacity: 0.38;
		}
	}

	& .MuiInputBase-root.Mui-error .MuiOutlinedInput-notchedOutline {
		border-color: ${({ theme }) => theme.colorPalette.error.value};
	}
`

export default Autocomplete
