/** Accepts an any value, and returns an array object. If the input object is partially an
 * array type, the returned object will be a non nullable array of the same type. */
export const ensureVarIsArray = <T, V>(value?: Array<T> | null | V): Array<T> => {
	const valueIsArray = <T, V>(v?: Array<T> | null | V): v is Array<T> => {
		if (Object.prototype.toString.call(v) === "[object Array]") {
			return true
		}

		return false
	}

	if (valueIsArray(value)) {
		return value
	}
	return []
}

/** Validates based on if entry has been made thats more than just spaces. */
export const isEntryInvalid = (input: string) => {
	return input.trim().length < 1
}

/** Function that recursively checks object for any value that is not falsey
 * - Example: Check Stage object for empty values to show/hide entire section. */
export function isObjectEmptyRecursive(obj: any) {
	for (let key in obj) {
		if (obj[key] instanceof Object === true) {
			//check recursion
			if (isObjectEmptyRecursive(obj[key]) === false) return false
		} else {
			//check value
			if (obj[key] && obj[key].length !== 0) return false
		}
	}
	return true
}

/* Converts stringified falsy and truthy values to a stringified boolean value */
export const normalizeStringBoolean = (value: string) => {
	if (value === "0" || value === "false") {
		return "false"
	}

	return "true"
}
