import { ArrowDropDown, ArrowRight } from "@mui/icons-material"
import { Check } from "@mui/icons-material"
import {
	InputLabel as MuiInputLabel,
	FormControl as MuiFormControl,
	Select as MuiSelect,
	SelectProps as MuiSelectProps,
	Fade,
	styled,
	useTheme,
} from "@mui/material"
import { useRef, useState } from "react"
import { MenuItem } from "../atoms/MenuItem"

type SelectProps = {
	optionValues: string[]
	optionSize?: "lg" | "md" | "sm"
	/** Gives stripped down input used in modals */
	simple?: boolean
} & Omit<MuiSelectProps<string>, "IconComponent" | "onOpen" | "onClose" | "onBlur" | "onFocus">

export const Select: React.FC<SelectProps> = ({
	value,
	onChange,
	optionValues,
	label,
	fullWidth,
	optionSize = "sm",
	simple,
	title,
	required,
	disabled,
	...rest
}) => {
	const { colorPalette } = useTheme()
	const InputRef = useRef<HTMLElement>(null)
	const [isOpen, setIsOpen] = useState(false)
	const [focused, setFocused] = useState(false)
	return (
		<StyledFormControl data-cy-component="Component-Select" fullWidth={fullWidth} title={title}>
			<StyledInputLabel shrink={false} isEmpty={value === ""} simple={simple} disabled={disabled}>
				{label ? (required ? label + "*" : label) : ""}
			</StyledInputLabel>
			<StyledSelectBase
				{...rest}
				disabled={disabled}
				required={required}
				simple={simple}
				value={value}
				IconComponent={!isOpen && focused && value !== "" ? StyledCheck : StyledArrow}
				onChange={onChange}
				onOpen={() => setIsOpen(true)}
				onClose={() => setIsOpen(false)}
				onFocus={() => setFocused(true)}
				onBlur={() => setFocused(false)}
				ref={InputRef}
				MenuProps={{
					sx: {
						...rest.MenuProps?.sx,
						"& .MuiPaper-root": {
							width: InputRef.current?.offsetWidth + "px" || "auto",
							backgroundColor: colorPalette.surface.container.value,
							borderRadius: simple ? "0px 0px 4px 4px" : "4px",
							margin: "0px",
							".MuiList-root": {
								padding: 0,
								boxShadow: "0px 2px 6px 2px #00000026, 0px 1px 2px 0px #0000004d",
							},
							...(rest.MenuProps?.sx as any)?.["& .MuiPaper-root"],
						},
					},
					TransitionComponent: Fade,
					anchorOrigin: simple
						? {
								vertical: "top",
								horizontal: "left",
						  }
						: {
								vertical: "bottom",
								horizontal: "left",
						  },

					transformOrigin: {
						vertical: "top",
						horizontal: "left",
					},
				}}
				data-cy-component="Component-Select_Dropdown"
			>
				{simple &&
					(value === "" ? (
						<MenuItem
							selected
							disabled
							optionSize="lg"
							key={`${label}`}
							value={""}
							endIcon={<ArrowDropDown />}
							sx={{
								position: "sticky",
								top: 0,
								zIndex: 101,
								"&.MuiButtonBase-root.MuiMenuItem-root.Mui-selected.MuiMenuItem-root": {
									opacity: "1",
								},
							}}
							data-cy-component={`Component-Select_MenuItem-Placeholder`}
						>
							{label}
						</MenuItem>
					) : (
						<MenuItem
							selected
							optionSize="lg"
							key={`${value}`}
							value={value}
							endIcon={<ArrowDropDown />}
							sx={{ position: "sticky", top: 0, zIndex: 101 }}
							data-cy-component={`Component-Select_MenuItem-Selected`}
						>
							{value}
						</MenuItem>
					))}
				{(simple ? optionValues.filter(val => val !== value) : optionValues).map((val, indx) => (
					<MenuItem
						key={`${val}`}
						value={val}
						optionSize={optionSize}
						data-cy-component={`Component-Select_MenuItem-${indx}`}
					>
						{val}
					</MenuItem>
				))}
			</StyledSelectBase>
		</StyledFormControl>
	)
}

const StyledArrow = styled(ArrowRight)`
	&.MuiSvgIcon-root.MuiSelect-icon {
		right: 12px;
	}
`

const StyledCheck = styled(Check)`
	&.MuiSvgIcon-root.MuiSelect-icon {
		right: 12px;
	}
`

const StyledSelectBase = styled(MuiSelect<string>, {
	shouldForwardProp: prop => prop !== "simple",
})<{ simple?: boolean }>`
	height: 56px;

	& {
		font-family: Signika-Regular;
	}

	.MuiOutlinedInput-notchedOutline {
		border: 1px solid ${({ theme }) => theme.colorPalette.outline.value};
		border-radius: 0;
		height: 56px;
		margin-top: 5px;

		legend {
			display: none;
		}
	}

	&.MuiInputBase-root .MuiInputBase-input {
		display: flex;
		align-items: ${({ simple }) => (simple ? "center" : "start")};

		color: ${({ theme }) => theme.colorPalette.surface.onVariant};

		font: 400 16px Signika-Regular;
		letter-spacing: 0.5px;
		text-align: left;
		opacity: 1;

		transition: background-color 150ms ease-out;
		height: 40px;
		padding: 8px 12px;
	}

	&:has(.MuiSelect-select[aria-expanded="true"]) {
		.MuiOutlinedInput-notchedOutline {
			border-width: 1px;
			border-color: ${({ theme }) => theme.colorPalette.outline.value};
		}

		${({ value }) =>
			value !== ""
				? `
						.MuiSelect-select {
							align-items: center;
						}
				  `
				: ""}
	}

	&.Mui-focused {
		.MuiOutlinedInput-notchedOutline {
			border: 1px solid ${({ theme }) => theme.colorPalette.outline.value};
		}

		.MuiSelect-select {
			box-shadow: 0px 2px 6px 2px #00000026, 0px 1px 2px 0px #0000004d;
		}

		&:has(.MuiSelect-select[aria-expanded="false"]) {
			${({ value, theme }) =>
				value !== ""
					? `
							.MuiOutlinedInput-notchedOutline {
								border: 1px solid ${theme.colorPalette.primary.value};
							}

							.MuiSelect-icon {
								fill: ${theme.colorPalette.primary.value};
							}
					  `
					: ""}

			.MuiSelect-select {
				align-items: center;
				box-shadow: none;
			}
		}
	}

	& .MuiSelect-iconOpen {
		transform: rotate(90deg);
		-webkit-transform: rotate(90deg);
	}

	& .MuiInputBase-input.MuiOutlinedInput-input.Mui-disabled {
		color: ${({ theme }) => theme.colorPalette.surface.onVariant};
		-webkit-text-fill-color: ${({ theme }) => theme.colorPalette.surface.on};
		opacity: 0.38;
	}

	&.Mui-disabled {
		opacity: 80%;

		.MuiSelect-select {
			background-color: ${({ theme }) => theme.colorPalette.surface.on}14;
		}

		.MuiOutlinedInput-notchedOutline {
			border-color: ${({ theme }) => theme.colorPalette.outline.value};
		}
	}

	&:hover {
		.MuiOutlinedInput-notchedOutline {
			border-color: ${({ theme }) => theme.colorPalette.outline.value};
		}
		.MuiSelect-select {
			background-color: ${({ theme }) => theme.colorPalette.surface.on}14;
		}

		:has(.MuiInputBase-root.Mui-error) {
			border-color: ${({ theme }) => theme.colorPalette.error.value} !important;
		}
	}

	&.Mui-disabled {
		.MuiOutlinedInput-notchedOutline {
			border-color: ${({ theme }) => theme.colorPalette.surface.onVariant};
			opacity: 0.38;
		}
		.MuiSelect-select {
			background: none;
		}

		svg {
			opacity: 0.38;
		}
	}

	&:active {
		box-shadow: none;
	}

	& svg {
		color: ${({ theme }) => theme.colorPalette.surface.onVariant};
	}

	&.Mui-error {
		svg {
			color: ${({ theme }) => theme.colorPalette.error.value} !important;
		}
		fieldset.MuiOutlinedInput-notchedOutline {
			border-width: 1px;
			border-color: ${({ theme }) => theme.colorPalette.error.value};
		}
	}
`

const StyledInputLabel = styled(MuiInputLabel, {
	shouldForwardProp: prop => prop !== "isEmpty" && prop !== "simple" && prop !== "disabled",
})<{ isEmpty: boolean; simple?: boolean; disabled?: boolean }>`
	display: flex;
	color: ${({ theme }) => theme.colorPalette.surface.onVariant};
	transform: none;
	padding: 8px 12px;
	gap: 12px;
	height: 56px;
	background: none;

	font-family: Signika-Regular;

	${({ isEmpty, simple }) =>
		isEmpty || simple
			? `
					font-size: 16px;
					letter-spacing: 0.5px;
					align-items: center;
			  `
			: `
					align-items: end;
					font-size: 14px;
					letter-spacing: 0.25px;
			  `};

	opacity: ${({ isEmpty, simple, disabled }) => (simple && !isEmpty ? 0 : disabled ? 0.38 : 1)};

	&.Mui-focused {
		color: ${({ theme }) => theme.colorPalette.surface.onVariant};

		visibility: ${({ isEmpty }) => (isEmpty ? "visible" : "hidden")};
		opacity: ${({ isEmpty }) => (isEmpty ? 1 : 0)};
	}

	&:has(+ .MuiInputBase-root.Mui-error) {
		color: ${({ theme }) => theme.colorPalette.error.value};
	}

	transition: opacity 100ms ease-out;
`

const StyledFormControl = styled(MuiFormControl)`
	height: 56px;
`
