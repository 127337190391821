import { Autocomplete, styled } from "@mui/material"
import FormField from "@atoms/FormField"
import { Chip } from "@atoms/Chip"
import { useTranslation } from "react-i18next"
import { useProductionContext } from "@/context/production"
import { useEffect, useState } from "react"
import { useVenuesContext } from "@/context/venues"

export const PreviousNames: React.FC<{}> = () => {
	const {
		state: { venue },
		setState,
	} = useProductionContext()
	const { selectedVenue } = useVenuesContext()

	const [previousNames, setPreviousNames] = useState(selectedVenue?.previousNames ?? [])

	const { t } = useTranslation()

	const addPreviousName = (name: string) => {
		setPreviousNames(prev => [...prev, { name: name }])
	}

	const removePreviousName = (names: string[]) => {
		setPreviousNames(prev => prev.filter(p => names.indexOf(p.name) !== -1))
	}

	useEffect(() => {
		const delayDebounceFn = setTimeout(() => {
			setState(prev => ({
				...prev,
				venue: {
					...prev.venue,
					previousNames: previousNames,
				},
			}))
		}, 500)

		return () => clearTimeout(delayDebounceFn)
	}, [previousNames, setState])

	useEffect(() => {
		if (selectedVenue !== null && selectedVenue.id !== venue.id) {
			setPreviousNames(selectedVenue?.previousNames ?? [])
		}
	}, [setPreviousNames, selectedVenue, venue])

	return (
		<Autocomplete
			multiple={true}
			freeSolo
			fullWidth
			autoSelect
			disableClearable
			limitTags={undefined}
			onChange={(_, updatedPreviousNames, eventType) => {
				if (eventType === "removeOption") {
					removePreviousName(updatedPreviousNames)
				} else {
					addPreviousName(updatedPreviousNames[updatedPreviousNames.length - 1])
				}
			}}
			open={false}
			value={previousNames.map(p => p.name) || []}
			options={previousNames.map(p => p.name) || []}
			renderTags={(value: readonly string[], getTagProps) =>
				value.map((option: string, index: number) => (
					<Chip label={option} {...getTagProps({ index })} key={option} />
				))
			}
			renderInput={params => (
				<TaggedFormField
					{...params}
					label={t("previousNames")}
					data-cy="generalvenueinfo-previousnames"
					multiline
				/>
			)}
		/>
	)
}

const TaggedFormField = styled(FormField)`
	& .MuiInputBase-root.MuiOutlinedInput-root.MuiInputBase-formControl.MuiAutocomplete-inputRoot {
		position: static;
		align-items: start;
		padding: 8px 0px 8px 16px;
		gap: 10px;
		max-height: 92px;
		height: 92px;
		overflow-y: scroll;
		scrollbar-width: thin;
	}
`
