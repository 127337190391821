import { useConfirmCloseContext } from "../../../context/confirmClose"
import { useNavigationContext } from "../../../context/navigation"
import { mediaQuery } from "../../../util/stylingUtils"
import React, { useCallback, useMemo, useState } from "react"
import { useTranslation } from "react-i18next"
import { useNavigate } from "react-router-dom"
import styled, { useTheme } from "styled-components"

function MenuItem({
	active,
	to,
	text,
	IconComponent,
	onClick,
	iconSize,
	...rest
}: {
	active?: boolean
	text: string
	IconComponent?: React.FC<UpdatedIconTypes>
	iconSize?: number
	to?: string
	onClick?: () => void
	"data-cy"?: string
}) {
	const { t } = useTranslation()
	const { colorPalette } = useTheme()
	const navigate = useNavigate()
	const { setMobileMenuOpen } = useNavigationContext()
	const { handleConfirmNavgate } = useConfirmCloseContext()
	const [pressed, setPressed] = useState(false)
	const [hovered, setHovered] = useState(false)

	const handleClick = useCallback(() => {
		if (text === t("techPack") && onClick !== undefined) {
			onClick()
		} else {
			handleConfirmNavgate(() => {
				if (to !== undefined) {
					navigate(to)
					setMobileMenuOpen(false)
				} else if (onClick !== undefined) {
					onClick()
				}
			})
		}
	}, [to, onClick, navigate, handleConfirmNavgate, text, t, setMobileMenuOpen])

	const textColor = useMemo(() => {
		if (active) {
			return colorPalette.primary.on
		}

		if (hovered) {
			return colorPalette.primary.fixed.on
		}

		return colorPalette.surface.on
	}, [
		active,
		hovered,
		colorPalette.primary.on,
		colorPalette.primary.fixed.on,
		colorPalette.surface.on,
	])

	return (
		<ItemContainer
			onMouseDown={() => setPressed(true)}
			onMouseUp={() => setPressed(false)}
			onMouseEnter={() => setHovered(true)}
			onMouseLeave={() => setHovered(false)}
			active={active}
			onClick={handleClick}
			data-cy-component="navMenuItem_ItemContainer"
			{...rest}
		>
			<IconContainer data-cy-component="navMenuItem_IconContainer">
				{IconComponent && (
					<IconComponent
						color={textColor}
						size={iconSize ? iconSize : undefined}
						fill={pressed || active}
					/>
				)}
			</IconContainer>
			<TextContainer textColor={textColor} data-cy-component="navMenuItem_TextContainer">
				{text}
			</TextContainer>
		</ItemContainer>
	)
}

const IconContainer = styled.div`
	display: flex;
	justify-content: center;
	align-items: center;

	width: 24px;
	height: 24px;
`

const ItemContainer = styled.div<{ active?: boolean }>`
	display: flex;
	align-items: center;
	justify-content: start;

	width: 276px;
	height: 56px;
	padding: 16px 24px 16px 24px;
	margin-left: auto;
	margin-right: 0;
	gap: 12px;

	border-radius: 100px 0px 0px 100px;
	background-color: ${({ theme, active }) =>
		active ? theme.colorPalette.primary.value : "transparent"};

	user-select: none;
	cursor: ${({ active }) => (active ? "default" : "pointer")};

	${mediaQuery("md")`
		border-radius: 100px;
		margin: 0;
	`}

	&:hover {
		background-color: ${({ theme, active }) =>
			active ? theme.colorPalette.primary.fixed.onVariant : theme.colorPalette.primary.fixed.value};
	}

	&:active {
		background-color: ${({ theme, active }) =>
			active ? theme.colorPalette.primary.value : theme.colorPalette.primary.fixed.dim};
	}

	transition: all 150ms ease-in;
`

const TextContainer = styled.p<{ textColor: string }>`
	font: 14px Signika-Semibold;
	text-transform: capitalize;
	text-align: left;
	color: ${({ textColor }) => textColor};
`

export default MenuItem
