import { TextField, TextFieldProps, styled } from "@mui/material"
import { forwardRef, ForwardRefRenderFunction } from "react"

const FormField: ForwardRefRenderFunction<HTMLDivElement, TextFieldProps> = (props, ref) => {
	return (
		<StyledTextField
			{...props}
			InputLabelProps={{
				shrink: true,
				...props.InputLabelProps,
			}}
			variant="outlined"
			data-cy-component="Component-FormField"
			ref={ref}
		/>
	)
}

const StyledTextField = styled(TextField)`
	& .MuiInputBase-root {
		height: 56px;
	}

	& .MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline {
		border-color: ${({ theme }) => theme.colorPalette.surface.on};
	}

	& .MuiInputLabel-root {
		color: ${({ theme }) => theme.colorPalette.surface.onVariant};
		font: 400 12px/16px Signika-Regular;
		transform: translate(12px, -8px);
		padding: 0px 4px 0px 4px;
		letter-spacing: 0;

		&.Mui-focused {
			color: ${({ theme }) => theme.colorPalette.primary.value};

			.MuiFormLabel-asterisk {
				color: ${({ theme }) => theme.colorPalette.primary.value};
			}
		}

		&.Mui-error,
		&.Mui-focused.Mui-error {
			color: ${({ theme }) => theme.colorPalette.error.value};

			.MuiFormLabel-asterisk {
				color: ${({ theme }) => theme.colorPalette.error.value};
			}
		}

		&.Mui-disabled {
			opacity: 0.38;
			color: ${({ theme }) => theme.colorPalette.surface.onVariant};

			.MuiFormLabel-asterisk {
				color: ${({ theme }) => theme.colorPalette.surface.onVariant};
			}
		}
	}

	& .MuiInputLabel-root.Mui-focused {
		color: ${({ theme }) => theme.colorPalette.primary.value};

		.MuiFormLabel-asterisk {
			color: ${({ theme }) => theme.colorPalette.primary.value};
		}
	}

	& .MuiOutlinedInput-root.Mui-disabled .MuiOutlinedInput-notchedOutline {
		opacity: 0.38;
	}

	& .MuiInputBase-input {
		color: ${({ theme }) => theme.colorPalette.surface.on};
		font: 400 16px/24px Signika-Regular;
		letter-spacing: 0.5px;
		padding: 8px 16px;
		height: 40px;

		&.Mui-disabled {
			pointer-events: none;
		}
	}

	& .MuiOutlinedInput-notchedOutline {
		border-color: ${({ theme }) => theme.colorPalette.outline.value};
		padding-left: 11px;
		padding-right: 11px;

		legend span {
			padding: 0;
			font: 400 12px/16px Signika-Regular;
			letter-spacing: 0;
			padding: 0px 4px 0px 4px;
		}
	}

	& .MuiInputBase-root.Mui-focused .MuiOutlinedInput-notchedOutline {
		border-color: ${({ theme }) => theme.colorPalette.primary.value};
	}

	& .MuiInputBase-root.Mui-disabled .MuiOutlinedInput-notchedOutline {
		border-color: ${({ theme }) => theme.colorPalette.surface.onVariant};
	}

	& .MuiInputBase-root.Mui-disabled .MuiInputBase-input {
		color: ${({ theme }) => theme.colorPalette.surface.onVariant};
		opacity: 0.38;
		-webkit-text-fill-color: inherit;

		.MuiFormLabel-asterisk {
			color: ${({ theme }) => theme.colorPalette.surface.onVariant};
		}
	}

	& .MuiInputBase-root.Mui-error .MuiOutlinedInput-notchedOutline {
		border-color: ${({ theme }) => theme.colorPalette.error.value};
	}

	& .MuiInputBase-multiline {
		height: auto;
		padding: 8px 0px;
	}
`

export default forwardRef(FormField)
