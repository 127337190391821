import React, { forwardRef, ForwardRefRenderFunction, useMemo, useState } from "react"
import styled, { css } from "styled-components"
import NewButton from "../atoms/NewButton"
import { useTranslation } from "react-i18next"
import Grid from "@mui/material/Unstable_Grid2"

type PermalinkProps = {
	baseLink: string
	editText: string
	setEditText: (newValue: string) => void
	maxLength?: number
	/** Determines if and how the user's input should be tested
	 * "RemoveOnSave" (Default) - Automatically removes invalid characters when user presses save
	 * "PreventSave" - Deactivates save button when invalid characters are present in input
	 * "PreventInput" - Prevents user from inputting invalid characters
	 * "None" - Permalink does not test the user's input at all
	 */
	validateInput?: "RemoveOnSave" | "PreventSave" | "PreventInput" | "None"
}

const Permalink: ForwardRefRenderFunction<HTMLDivElement, PermalinkProps> = (
	{ baseLink, editText, setEditText, maxLength, validateInput },
	ref,
) => {
	const { t } = useTranslation()
	const [editMode, setEditMode] = useState(false)
	const [newLink, setNewLink] = useState(editText)

	const filterUrlChars = (testString: string) => {
		return testString
			.split("")
			.filter(c => /^[a-zA-Z0-9_-]*$/.test(c))
			.join("")
			.toLowerCase()
	}

	const saveEditOnClick = () => {
		if (editMode) {
			if (validateInput === undefined || validateInput === "RemoveOnSave") {
				const newUrl = filterUrlChars(newLink.replaceAll(" ", "-"))
				setEditText(newUrl)
			} else {
				setEditText(newLink)
			}

			setEditMode(false)
		} else {
			setNewLink(editText)
			setEditMode(true)
		}
	}

	const cancelCopyOnClick = () => {
		if (editMode) {
			setNewLink(editText)
			setEditMode(false)
		} else {
			navigator.clipboard.writeText(baseLink + "/" + editText)
		}
	}

	const inputOnKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
		switch (e.code) {
			case "Enter":
				if (newLink.length > 0) {
					if (validateInput === undefined || validateInput === "RemoveOnSave") {
						const newUrl = filterUrlChars(newLink.replaceAll(" ", "-"))
						setEditText(newUrl)
					} else {
						setEditText(newLink)
					}
				}
				setEditMode(false)
				break
			case "Escape":
			case "Tab":
				setEditMode(false)
		}
	}

	const processedBaseLink = useMemo(() => {
		return baseLink.split(/([/_-])/g)
	}, [baseLink])

	return (
		<PermalinkContainer ref={ref} container xs={12}>
			<Grid xs>
				<StyledPermalink data-cy-component="permalink-container">
					<span className="permalink-caption">{`${t("permalink")}: `}</span>
					<a
						href={`${baseLink}/${editText}`}
						aria-disabled={editMode}
						target="_blank"
						rel="noreferrer"
					>
						{processedBaseLink.map(val =>
							val.match(/([/_-])/g) ? (
								<>
									{val}
									<wbr />
								</>
							) : (
								val
							),
						)}
						/{!editMode && editText}
					</a>
					{editMode && (
						<EditableInput
							autoFocus
							size={newLink.length > 1 ? newLink.length : 1}
							onChange={e =>
								setNewLink(
									validateInput === "PreventInput"
										? filterUrlChars(e.target.value)
										: e.target.value,
								)
							}
							value={newLink}
							spellCheck="false"
							maxLength={maxLength}
							onKeyDown={inputOnKeyDown}
							data-cy="permalink-value"
						/>
					)}
				</StyledPermalink>
			</Grid>

			<Grid xs="auto">
				<NewButton
					text={editMode ? t("save") : t("edit")}
					buttonType="Outline"
					buttonStyles={css`
						height: 28px;
						width: 57px;
					`}
					stateLayerStyles={css`
						height: 28px;
					`}
					textStyles={css`
						height: auto;
					`}
					onClick={saveEditOnClick}
					disabled={
						editMode &&
						(newLink.length === 0 ||
							(validateInput === "PreventSave" && !/^[a-zA-Z0-9_-]*$/.test(newLink)))
					}
					data-cy={editMode ? "permalink-save" : "permalink-edit"}
				/>
			</Grid>

			<Grid xs="auto">
				<NewButton
					text={editMode ? t("cancel") : t("copy")}
					buttonType={editMode ? "Text" : "Outline"}
					buttonStyles={css`
						height: 28px;
						width: 63px;
					`}
					stateLayerStyles={css`
						height: 28px;
					`}
					textStyles={css`
						height: auto;
					`}
					onClick={cancelCopyOnClick}
					data-cy={editMode ? "permalink-cancel" : "permalink-copy"}
				/>
			</Grid>
		</PermalinkContainer>
	)
}

const EditableInput = styled("input")`
	display: inline;
	font: 400 14px/20px Signika-Regular;
	color: ${({ theme }) => theme.colorPalette.primary.value};
	max-width: 100%;

	&:focus {
		border-color: ${({ theme }) => theme.colorPalette.primary.value};
	}
`

const StyledPermalink = styled("div")`
	display: inline;

	.permalink-caption {
		color: ${({ theme }) => theme.colorPalette.surface.on};
		font: 600 14px/20px Signika-Semibold;
	}

	& > a {
		font: 400 14px/20px Signika-Regular;
		color: ${({ theme }) => theme.colorPalette.primary.value};
		overflow-wrap: break-word;
		&[aria-disabled="true"] {
			pointer-events: none;
			cursor: default;
		}

		& > span {
			font: inherit;
		}
	}
`

const PermalinkContainer = styled(Grid)`
	gap: 8px;

	letter-spacing: normal;
`

export default forwardRef(Permalink)
