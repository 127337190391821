import useAxios from "@/hooks/useAxios"
import { useProductRoles } from "@/hooks/useProductRoles"
import { useVenuesContext } from "@context/venues"
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query"
import React, { useContext, useMemo, useState } from "react"
import { createContext } from "react"
import { useTranslation } from "react-i18next"
import { toast } from "react-toastify"
import baseAxios from "axios"
import { getQueryFilters } from "@/util/queryFilters"
import { normalizeNameObject } from "@/util/userUtils"
import { DEFAULT_STAFF_CREW_ROW } from "@/util/constants/defaults/DEFAULT_STAFF_CREW"

function isKey<T extends object>(x: T, k: PropertyKey): k is keyof T {
	return k in x
}

const DEFAULT_STAFF_CREW_CONTEXT_STATE: StaffCrewContextState = {
	addStaffModalOpen: false,
	editStaffModalOpen: false,
	selectedChips: {
		all: true,
		venueAdmin: true,
		venueManager: true,
		venueStaff: true,
	},
	searchEmail: "",
}

export const StaffCrewContext = createContext<StaffCrewContextType>({
	setState: () => null,
	state: DEFAULT_STAFF_CREW_CONTEXT_STATE,
	getStaffCrewData: [],
	createStaffCrewMember: async () => undefined,
	updateStaffCrewMember: async () => undefined,
	deleteStaffCrewMember: async () => undefined,
	resendInvite: () => undefined,
	foundStaffCrewEmail: undefined,
	productRoles: [],
	isPending: false,
	foundStaffCrewEmailPending: false,
	staffCrewPermissions: {
		userId: "",
		view: [],
		update: [],
	},
	staffCrewTotals: {
		all: 0,
		venueAdmin: 0,
		venueManager: 0,
		venueStaff: 0,
	},
	filteredData: [],
})

export const StaffCrewContextProvider: React.FC<React.PropsWithChildren<unknown>> = ({
	children,
}) => {
	const { t } = useTranslation()
	const [state, setState] = useState<StaffCrewContextState>(DEFAULT_STAFF_CREW_CONTEXT_STATE)
	const { selectedStage, venuePermissions } = useVenuesContext()
	const { productRoles } = useProductRoles()
	const axios = useAxios()
	const queryClient = useQueryClient()
	const { token } = localStorage

	const { data: getStaffCrewData = [], isPending: getStaffCrewPending } = useQuery({
		queryKey: [
			"staff-crew",
			{
				venueId: selectedStage?.venueId || "",
			},
		],
		queryFn: async () => {
			return axios
				.get<{ data: StaffCrewRow[] }>(`/venues/v1/venues/${selectedStage?.venueId}/staff-crew`)
				.then(res => res.data.data)
		},
		enabled: selectedStage !== null && !!token,
		meta: {
			errorMessage: t("errorTryAgain"),
		},
		retry: false,
	})

	const { data: selectedStaffCrewMember, isPending: getStaffCrewMemberPending } = useQuery({
		queryKey: [
			"staff-crew-member",
			{
				venueId: selectedStage?.venueId || "",
				selectedUserId: state.selectedUserId || "",
			},
		],
		queryFn: async () => {
			return axios
				.get<{ data: StaffCrewRow }>(
					`/venues/v1/venues/${selectedStage?.venueId}/staff-crew/${state.selectedUserId}`,
					{
						validateStatus: null,
					},
				)
				.then(res => {
					if (res.status !== 200) {
						setState(prev => ({
							...prev,
							addStaffModalOpen: false,
							editStaffModalOpen: false,
							searchEmail: "",
							selectedUserId: undefined,
						}))
						toast.error(t("errorTryAgain"))
						return DEFAULT_STAFF_CREW_ROW
					}
					return {
						...res.data.data,
						name: normalizeNameObject(res.data.data.name),
					}
				})
		},
		enabled:
			state.selectedUserId !== undefined &&
			!!token &&
			(state.addStaffModalOpen || state.editStaffModalOpen),
		meta: {
			errorMessage: t("errorTryAgain"),
		},
		retry: false,
	})

	const { data: foundStaffCrewEmail, isPending: foundStaffCrewEmailPending } = useQuery({
		queryKey: [
			"search-staff-crew-member",
			{
				venueId: selectedStage?.venueId || "",
				emailSearch: state.searchEmail,
			},
		],
		queryFn: async () => {
			return await baseAxios
				.post<{ data: SearchResponse }>(
					`${process.env.REACT_APP_API_URL}/venues/v1/user/search`,
					{
						username: state.searchEmail,
					},
					{
						headers: {
							Authorization: `Bearer ${token}`,
						},
					},
				)
				.then(res => ({
					user: {
						...res.data.data.user,
						name: normalizeNameObject(res.data.data.user.name),
					},
					profile: {
						...res.data.data.profile,
						name: normalizeNameObject(res.data.data.profile.name),
					},
				}))
		},
		enabled: state.searchEmail !== "" && !!token,
		meta: undefined,
		retry: false,
	})

	const { mutate: createStaffCrewMember, isPending: createStaffCrewMemberPending } = useMutation({
		mutationKey: [
			"create-staff-crew",
			{
				venueId: selectedStage?.venueId || "",
			},
		],
		mutationFn: async (data: CreateUpdateStaffCrewBody) => {
			if (selectedStage === undefined) {
				return undefined
			}

			const uploadData = {
				...data,
				title: data.title.join("; "),
			}

			if (uploadData.preferredName === "") {
				delete uploadData.preferredName
			}

			if (uploadData.middleInitial === "") {
				delete uploadData.middleInitial
			}

			return axios
				.post<{ data: StaffCrewRow }>(
					`/venues/v1/venues/${selectedStage?.venueId}/staff-crew`,
					JSON.stringify(uploadData),
				)
				.then(res => res.data.data)
		},
		onSuccess: () => {
			queryClient.invalidateQueries(
				getQueryFilters(["staff-crew"], {
					venueId: selectedStage?.venueId || "",
				}),
			)
		},
		onError: () => {
			toast.error(t("errorTryAgain"))
		},
	})

	const { mutate: updateStaffCrewMember, isPending: updateStaffCrewMemberPending } = useMutation({
		mutationKey: [
			"update-staff-crew",
			{
				venueId: selectedStage?.venueId || "",
			},
		],
		mutationFn: async (data: { userId: string; updateData: CreateUpdateStaffCrewBody }) => {
			if (selectedStage === undefined) {
				return undefined
			}

			const initialData = {
				...data.updateData,
				title: data.updateData.title.join("; "),
				preferredName:
					data.updateData.preferredName === "" ? undefined : data.updateData.preferredName,
				middleInitial:
					data.updateData.middleInitial === "" ? undefined : data.updateData.middleInitial,
			}

			const uploadData: Partial<
				Omit<CreateUpdateStaffCrewBody, "title" | "includeInTechPack"> & {
					title: string
					includeInTechPack: number
				}
			> = {}

			for (const keyVal in initialData) {
				if (isKey(initialData, keyVal)) {
					if (
						keyVal === "firstName" ||
						keyVal === "middleInitial" ||
						keyVal === "lastName" ||
						keyVal === "preferredName"
					) {
						if (selectedStaffCrewMember?.name[keyVal] !== initialData[keyVal]) {
							uploadData[keyVal] = initialData[keyVal]
						}
					} else if (keyVal === "includeInTechPack" || keyVal === "username") {
						if (initialData.includeInTechPack !== selectedStaffCrewMember?.includedInTechpack) {
							if (initialData["includeInTechPack"]) {
								uploadData["includeInTechPack"] = 1
							} else {
								uploadData["includeInTechPack"] = -1
							}
						}
						if (initialData.username !== selectedStaffCrewMember?.email) {
							uploadData["username"] = initialData["username"]
						}
					} else if (initialData[keyVal] !== selectedStaffCrewMember?.[keyVal]) {
						uploadData[keyVal] = initialData[keyVal]
					}
				}
			}

			const returnVal = await axios
				.post<{ data: StaffCrewRow }>(
					`/venues/v1/venues/${selectedStage?.venueId}/staff-crew/${data.userId}`,
					JSON.stringify({
						...uploadData,
						nickName: undefined,
					}),
				)
				.then(res => {
					return res.data.data
				})

			return returnVal
		},
		onSuccess: data => {
			queryClient.invalidateQueries(
				getQueryFilters(["staff-crew-member", "staff-crew"], {
					venueId: selectedStage?.venueId ?? "",
					userId: data?.userId ?? "",
				}),
			)
		},
		onError: () => {
			toast.error(t("errorTryAgain"))
		},
	})

	const { mutate: deleteStaffCrewMember, isPending: deleteStaffCrewMemberPending } = useMutation({
		mutationKey: [
			"delete-staff-crew",
			{
				venueId: selectedStage?.venueId || "",
			},
		],
		mutationFn: async (userId: string) => {
			if (selectedStage === undefined) {
				return undefined
			}

			return {
				res: await axios.delete(`/venues/v1/venues/${selectedStage?.venueId}/staff-crew/${userId}`),
				deletedUserId: userId,
			}
		},
		onSuccess: data => {
			queryClient.invalidateQueries(
				getQueryFilters(["staff-crew-member", "staff-crew"], {
					venueId: selectedStage?.venueId ?? "",
					userId: data?.deletedUserId ?? "",
				}),
			)
		},
		onError: () => {
			toast.error(t("errorTryAgain"))
		},
	})

	const { mutate: resendInvite, isPending: resendInvitePending } = useMutation({
		mutationKey: ["resendInvite"],
		mutationFn: (userEmail: string) =>
			axios.post<{ data: "" }>("/venues/v1/user/resend-welcome-email", {
				username: userEmail,
			}),
		onSuccess: () => {
			toast.success(t("emailSent"))
			queryClient.invalidateQueries({
				queryKey: ["staff-crew-member"],
			})
		},
	})

	const staffCrewTotals = useMemo(() => {
		return getStaffCrewData.reduce(
			(prev, curr) => {
				if (curr.roleName === "Venue Admin") {
					return {
						...prev,
						all: prev.all + 1,
						venueAdmin: prev.venueAdmin + 1,
					}
				}
				if (curr.roleName === "Venue Manager") {
					return {
						...prev,
						all: prev.all + 1,
						venueManager: prev.venueManager + 1,
					}
				}
				if (curr.roleName === "Venue Staff") {
					return {
						...prev,
						all: prev.all + 1,
						venueStaff: prev.venueStaff + 1,
					}
				}

				return prev
			},
			{
				all: 0,
				venueAdmin: 0,
				venueManager: 0,
				venueStaff: 0,
			},
		)
	}, [getStaffCrewData])

	const filteredData = useMemo(() => {
		if (state.selectedChips.all) {
			return getStaffCrewData
		}

		const selectedRoles = Object.entries(state.selectedChips)
			.filter(val => val[1])
			.map(val => {
				switch (val[0]) {
					case "venueAdmin":
						return "Venue Admin"
					case "venueManager":
						return "Venue Manager"
					case "venueStaff":
						return "Venue Staff"
					default:
						return "all"
				}
			}) as string[]

		return getStaffCrewData.filter(val => val.roleName && selectedRoles.includes(val.roleName))
	}, [getStaffCrewData, state.selectedChips])

	return (
		<StaffCrewContext.Provider
			value={{
				state,
				setState,
				getStaffCrewData,
				selectedStaffCrewMember,
				createStaffCrewMember,
				updateStaffCrewMember,
				deleteStaffCrewMember,
				resendInvite,
				productRoles,
				isPending:
					getStaffCrewPending ||
					(state.selectedUserId !== undefined &&
						!!token &&
						(state.addStaffModalOpen || state.editStaffModalOpen) &&
						getStaffCrewMemberPending) ||
					createStaffCrewMemberPending ||
					updateStaffCrewMemberPending ||
					deleteStaffCrewMemberPending ||
					resendInvitePending,
				staffCrewPermissions: {
					userId: venuePermissions.userId,
					...venuePermissions.staffCrew,
				},
				staffCrewTotals,
				filteredData,
				foundStaffCrewEmail,
				foundStaffCrewEmailPending,
			}}
		>
			{children}
		</StaffCrewContext.Provider>
	)
}

export const useStaffCrew = () => {
	return useContext(StaffCrewContext)
}
