import { Tabs as MuiTabs, styled } from "@mui/material"

export const Tabs = styled(MuiTabs)`
	& .MuiTabs-flexContainer {
		display: inline-flex;
		align-items: center;
		padding: 4px 0px;
	}

	& .MuiTab-root {
		margin-left: -1px;
	}

	& .MuiTab-root:first-child {
		border-radius: 100px 0px 0px 100px;
		margin-left: 0px;
	}

	& .MuiTab-root:last-child {
		border-radius: 0px 100px 100px 0px;
	}

	& .MuiTabs-indicator {
		display: none;
		visibility: none;
	}
`
