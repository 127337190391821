import { useState, useMemo, useRef, useEffect } from "react"
import { PageHeader } from "../organisms/PageHeader"
import NewButton from "../atoms/NewButton"
import { AddOutlined, Check } from "@mui/icons-material"
import AddStageModal from "../organisms/venueStage/AddStageModal"
import { Box } from "@mui/material"
import { VenueStageDetail } from "../organisms/venueStage/VenueStageDetail"
import styled from "styled-components"
import { useTranslation } from "react-i18next"
import { useVenuesContext } from "@/context/venues"
import { useConfirmCloseContext } from "@/context/confirmClose"
import { mediaQuery } from "@/util/stylingUtils"
import { Tabs } from "../molecules/Tabs"
import { Tab } from "../atoms/Tab"
import { ScrollableStack } from "../atoms/ScrollableStack"
import { PageRootContainer } from "../atoms/PageRootContainer"
import { PlaceholderContent } from "../atoms/PlaceholderContent"
import { Footer } from "../organisms/Footer"

export const VenueStage: React.FC<{}> = () => {
	const { t } = useTranslation()
	const scrollableRef = useRef<HTMLDivElement>(null)
	const [addStageModalOpen, setAddStageModalOpen] = useState(false)
	const [tabIndex, setTabIndex] = useState(0)
	const { selectedVenue } = useVenuesContext()
	const { shouldPreventClose, setShouldPreventClose, handleConfirmNavgate } =
		useConfirmCloseContext()

	const [stageHasChanges, setStageHasChanges] = useState<Record<string, boolean>>({})

	const { mainStage, activeStages, inactiveStages } = useMemo(() => {
		if (selectedVenue === null) {
			return {
				mainStage: null,
				activeStages: [],
				inactiveStages: [],
			}
		}

		return selectedVenue.stages.reduce(
			(prev, curr) => {
				if (curr.isMainStage) {
					return {
						...prev,
						mainStage: curr,
					}
				}

				if (curr.isActive) {
					return {
						...prev,
						activeStages: [...prev.activeStages, curr],
					}
				}

				return {
					...prev,
					inactiveStages: [...prev.inactiveStages, curr],
				}
			},
			{
				mainStage: null,
				activeStages: [],
				inactiveStages: [],
			} as {
				mainStage: null | Stage
				activeStages: Stage[]
				inactiveStages: Stage[]
			},
		)
	}, [selectedVenue])

	useEffect(() => {
		scrollableRef.current?.scrollTo({ top: 0, left: 0 })
	}, [tabIndex])

	useEffect(() => {
		const isEdited = Object.values(stageHasChanges).find(val => val === false) !== undefined
		if (isEdited && !shouldPreventClose) {
			setShouldPreventClose(true)
		} else if (!isEdited && shouldPreventClose) {
			setShouldPreventClose(false)
		}
	}, [stageHasChanges, setShouldPreventClose, shouldPreventClose])

	return (
		<PageRootContainer>
			<AddStageModal
				selectedVenueId={selectedVenue?.id || null}
				modalOpen={addStageModalOpen}
				closeModal={() => setAddStageModalOpen(false)}
			/>
			<PageHeader pageTitle={t("venueStage")}>
				<NewButton
					text={t("addNewStage")}
					buttonType="Outline"
					borderColor="primary"
					iconElement={<AddOutlined />}
					onClick={() => setAddStageModalOpen(true)}
					data-cy="venuestage-addstagebutton"
				/>
			</PageHeader>
			<StyledTabs
				value={tabIndex}
				onChange={(_, buttonId) => handleConfirmNavgate(() => setTabIndex(buttonId))}
				aria-label="production tabs"
				variant="scrollable"
				scrollButtons={false}
			>
				<Tab
					value={0}
					label={t("activeStage_plural")}
					disableRipple
					icon={<Check />}
					iconPosition="start"
					data-cy="venuestage-activestages"
				/>
				<Tab
					value={1}
					label={t("inactiveStage_plural")}
					disableRipple
					icon={<Check />}
					iconPosition="start"
					data-cy="venuestage-inactivestages"
				/>
			</StyledTabs>
			{tabIndex === 1 && (
				<InactivePlaceholderBox>
					<InactiveWarningHeader>{t("inactiveStagesNoticeHeader")}</InactiveWarningHeader>
					<InactiveWarningBody>{t("inactiveStagesNoticeBody")}</InactiveWarningBody>
				</InactivePlaceholderBox>
			)}
			<ScrollableStack ref={scrollableRef}>
				{tabIndex === 0 ? (
					<>
						{mainStage && (
							<VenueStageDetail
								stageHasChanges={stageHasChanges}
								setStageHasChanges={setStageHasChanges}
								stageId={mainStage.id}
								techPackUrl={mainStage.publicUrls.customSlugUrl || mainStage.publicUrls.techPackUrl}
								{...mainStage}
							/>
						)}
						{activeStages.length > 0
							? activeStages.map(stage => {
									return (
										<VenueStageDetail
											key={stage.id}
											stageHasChanges={stageHasChanges}
											setStageHasChanges={setStageHasChanges}
											stageId={stage.id}
											techPackUrl={stage.publicUrls.customSlugUrl || stage.publicUrls.techPackUrl}
											{...stage}
										/>
									)
							  })
							: mainStage === null && (
									<PlaceholderContent text={t("noActiveStage_plural")} height={"192px"} />
							  )}
					</>
				) : inactiveStages.length > 0 ? (
					inactiveStages.map((stage, idx) => {
						return (
							<VenueStageDetail
								key={stage.id}
								stageHasChanges={stageHasChanges}
								setStageHasChanges={setStageHasChanges}
								hideDivider={idx === 0}
								stageId={stage.id}
								techPackUrl={stage.publicUrls.customSlugUrl || stage.publicUrls.techPackUrl}
								{...stage}
								data-cy={`venuestage-container-${stage.name.replace(/\s+/g, "").toLowerCase()}`}
							/>
						)
					})
				) : (
					<PlaceholderContent text={t("noInactiveStage_plural")} height={"192px"} />
				)}
				<Footer />
			</ScrollableStack>
		</PageRootContainer>
	)
}

const StyledTabs = styled(Tabs)`
	padding: 0px 48px;
	margin-bottom: 24px;

	${mediaQuery("md")`
		margin-bottom: 16px;
		padding: 0px 16px;
	`}
`

const InactivePlaceholderBox = styled(Box)`
	padding-bottom: 24px;
	padding: 0 48px;

	${mediaQuery("md")`
        padding: 24px 16px;
    `}
`

const InactiveWarningBody = styled("p")`
	font: 400 16px/24px Signika-Regular;
	color: ${({ theme }) => theme.colorPalette.surface.on};
	padding-bottom: 24px;

	${mediaQuery("md")`
        padding-bottom: 0px;
    `}
`

const InactiveWarningHeader = styled("p")`
	font: 600 16px/24px Signika-Semibold;

	&::before {
		content: "*";
	}
`
