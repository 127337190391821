import { Table, TableBody, TableContainer, TableHead, TableProps } from "@mui/material"
import { ReactNode, Ref } from "react"
import { TableCellProps } from "@mui/material/TableCell"
import { getObjectEntries } from "@util/typedObjectMapping"
import { ArrowDownward, ArrowUpward, DragIndicator } from "@mui/icons-material"
import { closestCenter, DndContext, PointerSensor, useSensor } from "@dnd-kit/core"
import { arrayMove, rectSortingStrategy, SortableContext, useSortable } from "@dnd-kit/sortable"
import { CSS } from "@dnd-kit/utilities"
import { restrictToParentElement, restrictToVerticalAxis } from "@dnd-kit/modifiers"
import { TableCell } from "@atoms/TableCell"
import { TableRow } from "@atoms/TableRow"

export type OrderedCellData<DataType extends string | boolean | number> =
	| DataType
	| {
			value: DataType
			displayContent: ReactNode
			cellProps?: TableCellProps
	  }

export type OrderedRowData<TableKeyUnion extends string> = Record<
	TableKeyUnion,
	OrderedCellData<string | boolean | number>
> & {
	id: string
}

export type OrderedHeaderCell<TableKeyUnion extends string> = {
	cellKey: TableKeyUnion
	label: string
	cellProps?: TableCellProps
}

interface OrderedListRowProps<TableKeyUnion extends string> {
	idx: number
	rowData: OrderedRowData<TableKeyUnion>
	totalRowsDisplayed: number
	setListData: React.Dispatch<React.SetStateAction<OrderedRowData<TableKeyUnion>[]>>
	excludeId: boolean
}

const OrderedListRow = <TableKeyUnion extends string>({
	idx,
	rowData,
	totalRowsDisplayed,
	setListData,
	excludeId,
}: OrderedListRowProps<TableKeyUnion>) => {
	const { setNodeRef, attributes, listeners, transition, transform } = useSortable({
		id: rowData.id,
	})

	const firstCellData = rowData[Object.keys(rowData)[1] as TableKeyUnion]
	const firstCellValue =
		typeof firstCellData === "object" && firstCellData !== null
			? firstCellData.displayContent
			: firstCellData

	return (
		<TableRow
			sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
			className={idx % 2 !== 0 ? "off-color" : ""}
			ref={setNodeRef}
			style={{
				position: "relative",
				transition,
				transform: CSS.Transform.toString(transform),
			}}
			data-cy-component={`orderedlist-container-${firstCellValue
				?.toString()
				.replace(/\s+/g, "")
				.toLowerCase()}`}
		>
			<TableCell
				sx={{
					maxWidth: "32px !important",
					display: "flex",
					flexDirection: "column",
					justifyContent: "center",
					alignContent: "center",
				}}
			>
				<DragIndicator
					{...attributes}
					{...listeners}
					sx={{
						color: theme => theme.colorPalette.primary.value,
						outline: "none",
						cursor: "grab",
					}}
					data-cy={`orderedlist-draghandle`}
				/>
			</TableCell>
			{getObjectEntries(rowData).reduce<ReactNode[]>((prev, [cellKey, cellData]) => {
				if (excludeId && cellKey === "id") {
					return prev
				}

				let cellProps = {}
				let displayData: ReactNode
				if (
					!(
						typeof cellData === "string" ||
						typeof cellData === "number" ||
						typeof cellData === "boolean"
					)
				) {
					cellProps = cellData.cellProps || {}
					displayData = cellData.displayContent
				} else {
					displayData = cellData
				}

				return [
					...prev,
					<TableCell
						key={`${prev.length}-${cellKey}`}
						align="left"
						variant="smallText"
						{...cellProps}
						data-cy={`orderedlist-cell-${cellKey.replace(/\s+/g, "").toLowerCase()}`}
					>
						{displayData}
					</TableCell>,
				]
			}, [])}
			<TableCell
				sx={{
					width: "48px",
					display: "flex",
					justifyContent: "center",
					alignItems: "center",
					marginRight: "24px",
				}}
			>
				<ArrowUpward
					sx={{
						color: theme =>
							idx === 0 ? theme.colorPalette.outline.variant : theme.colorPalette.primary.value,
						cursor: idx === 0 ? "default" : "pointer",
					}}
					onClick={() => {
						if (idx !== 0) {
							setListData(prev => arrayMove(prev, idx, idx - 1))
						}
					}}
					data-cy={`orderedlist-uparrow`}
				/>
				<ArrowDownward
					sx={{
						cursor: idx === totalRowsDisplayed - 1 ? "default" : "pointer",
						color: theme =>
							idx === totalRowsDisplayed - 1
								? theme.colorPalette.outline.variant
								: theme.colorPalette.primary.value,
					}}
					onClick={() => {
						if (idx !== totalRowsDisplayed - 1) {
							setListData(prev => arrayMove(prev, idx, idx + 1))
						}
					}}
					data-cy={`orderedlist-downarrow`}
				/>
			</TableCell>
		</TableRow>
	)
}

interface OrderedListProps<TableKeyUnion extends string> extends Omit<TableProps, "ref"> {
	headerData: OrderedHeaderCell<TableKeyUnion>[]
	listData: OrderedRowData<TableKeyUnion>[]
	setListData: React.Dispatch<React.SetStateAction<OrderedRowData<TableKeyUnion>[]>>
	tableRef?: Ref<HTMLTableElement> | null
	afterSort?: () => void
	excludeId?: boolean
}

export const OrderedList = <TableKeyUnion extends string>({
	headerData,
	tableRef,
	listData,
	setListData,
	afterSort,
	excludeId = true,
	...rest
}: OrderedListProps<TableKeyUnion>) => {
	const sensors = [useSensor(PointerSensor)]

	return (
		<TableContainer>
			<Table ref={tableRef} {...rest}>
				<TableHead>
					<TableRow>
						<TableCell width="32px"></TableCell>
						{headerData.map(cell => (
							<TableCell
								align="left"
								{...(cell.cellProps || {})}
								sx={{
									textTransform: "capitalize",
								}}
								key={cell.label}
							>
								{cell.label}
							</TableCell>
						))}
						<TableCell width="48px"></TableCell>
					</TableRow>
				</TableHead>
				<TableBody>
					<DndContext
						sensors={sensors}
						collisionDetection={closestCenter}
						onDragEnd={({ active, over }) => {
							if (active && over && active.id !== over?.id) {
								setListData(itms => {
									const newActive = itms.findIndex(item => item.id === active.id)
									const overIndex = itms.findIndex(item => item.id === over.id)

									return arrayMove(itms, newActive, overIndex)
								})
								if (afterSort) {
									afterSort()
								}
							}
						}}
						modifiers={[restrictToVerticalAxis, restrictToParentElement]}
					>
						<SortableContext items={listData.map(item => item.id)} strategy={rectSortingStrategy}>
							{listData.map((rowData, idx) => (
								<OrderedListRow
									excludeId={excludeId}
									key={rowData.id}
									idx={idx}
									rowData={rowData}
									setListData={setListData}
									totalRowsDisplayed={listData.length}
								/>
							))}
						</SortableContext>
					</DndContext>
				</TableBody>
			</Table>
		</TableContainer>
	)
}
