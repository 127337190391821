import { ensureVarIsArray } from "./dataValidation"
import { formatLatLong } from "./locationHandling"
import { getObjectValues } from "./typedObjectMapping"

/** Determines if an object contains only empty string, null, or undefined values for every key */
export const recordIsNullish = (
	recordObject: Record<string, string | null | undefined> | null | undefined,
): recordObject is Record<string, null | "" | undefined> | null | undefined => {
	return (
		recordObject === null ||
		recordObject === undefined ||
		getObjectValues(recordObject).every(val => val === null || val === undefined || val === "")
	)
}

/** Compare values while with the assumption an empty string is nullish */
export const compareNullishValues = (
	defaultVal: string | null | undefined,
	updatedVal: string | null | undefined,
) => {
	if (defaultVal === "" || defaultVal == null) {
		if (updatedVal === "" || updatedVal == null) {
			return true
		}
		return false
	}

	if (updatedVal === "" || defaultVal == null) {
		return false
	}

	return defaultVal === updatedVal
}

/** Basic JSON conversion comparison */
export const compare = (defaultValues: any, currentValues: any): boolean => {
	const a = JSON.stringify(defaultValues)
	const b = JSON.stringify(currentValues)
	return a === b
}

/** Compares dimensions or proscenium fieldset (issues with ordering when using normal json compare) */
export const compareDimensionFieldset = (
	defaultValues:
		| Partial<Record<"width" | "depth" | "height", string | null | undefined>>
		| null
		| undefined,
	updatedValues:
		| Partial<Record<"width" | "depth" | "height", string | null | undefined>>
		| null
		| undefined,
) => {
	if (recordIsNullish(defaultValues) && recordIsNullish(updatedValues)) {
		return true
	}

	if (recordIsNullish(defaultValues) || recordIsNullish(updatedValues)) {
		return false
	}

	const d = {
		width: defaultValues["width"] ?? "",
		depth: defaultValues["depth"] ?? "",
		height: defaultValues["height"] ?? "",
	}

	const u = {
		width: updatedValues["width"] ?? "",
		depth: updatedValues["depth"] ?? "",
		height: updatedValues["height"] ?? "",
	}

	return compare(d, u)
}

export const compareGeneralVenueInfo = (defaultValues: Venue, currentValues: Venue): boolean => {
	const formatVen = (venue: Venue) => {
		return {
			name: venue.name ?? "",
			website: ensureVarIsArray(venue?.urls).find(val => val.label === "primary")?.url ?? "",
			addressLine1: venue.addressLine1 ?? "",
			addressLine2: venue.addressLine2 ?? "",
			city: venue.city ?? "",
			state: venue.state ?? "",
			zip: venue.zip ?? "",
			country: venue.country ?? "",
			latitude: formatLatLong(venue.latitude) ?? "",
			longitude: formatLatLong(venue.longitude) ?? "",
			ageRequirement: venue.ageRequirement ?? "",
			publicNotes:
				ensureVarIsArray(venue?.notes ?? []).find(val => val.label === "public")?.notes ?? "",
			previousNames: venue.previousNames,
			contacts: ensureVarIsArray(venue?.contacts).filter(val => val.label && val.label !== ""),
		}
	}

	const orig = formatVen(defaultValues)
	const newVal = formatVen(currentValues)

	return compare(orig, newVal)
}

/*
	logoImage (virtual)

	name
	website
	previousNames
	addressLine1
	addressLine2
	city
	state
	zip
	country
	latitude
	longitude
	ageRequirement
	publicNotes (virtual, convert to notes as [{label: "public", notes: newValue}])
	contacts
*/

/** Used to compare the productionData Stage field */
export const compareProductionData = (
	defaultValues: Stage["productionData"],
	updatedValues: Stage["productionData"],
) => {
	const d = {
		...defaultValues,
	}

	const u = {
		...updatedValues,
	}

	delete d.dimensions
	delete d.proscenium
	delete u.dimensions
	delete u.proscenium

	const comp = compare(d, u)
	const dim = compareDimensionFieldset(defaultValues.dimensions, updatedValues.dimensions)
	const prosc = compareDimensionFieldset(defaultValues.proscenium, updatedValues.proscenium)

	return comp && dim && prosc
}
