import NewButton from "@/components/atoms/NewButton"
import { GeneralVenueContactDetail } from "@/components/molecules/production/GeneralVenueContactDetail"
import { Add } from "@mui/icons-material"
import { styled, useTheme } from "@mui/material"
import AddVenueContactModal from "./AddVenueContactModal"
import { useEffect, useState } from "react"
import { DeleteVenueContactModal } from "./DeleteVenueContactModal"
import { PlaceholderContent } from "@/components/atoms/PlaceholderContent"
import { useTranslation } from "react-i18next"
import { useVenuesContext } from "@/context/venues"
import { useProductionContext } from "@/context/production"
import Grid from "@mui/material/Unstable_Grid2"
import { mediaQuery } from "@/util/stylingUtils"
import { Divider } from "@/components/atoms/Divider"
import { ensureVarIsArray } from "@/util/dataValidation"

export const GeneralVenueContacts: React.FC<{}> = () => {
	const { t } = useTranslation()
	const { colorPalette } = useTheme()
	const [newContactData, setNewContactData] = useState<VenueContact[]>([])
	const { setState: setProductionState } = useProductionContext()
	const { selectedVenue } = useVenuesContext()

	useEffect(() => {
		if (selectedVenue?.contacts) {
			setProductionState(prev => ({
				...prev,
				venue: {
					...prev.venue,
					contacts: newContactData,
				},
			}))
		}
		return
	}, [newContactData, selectedVenue, setProductionState])

	useEffect(() => {
		setNewContactData(
			ensureVarIsArray(selectedVenue?.contacts).filter(val => val.label && val.label !== ""),
		)
	}, [selectedVenue, setNewContactData])

	return (
		<>
			<AddVenueContactModal />
			<DeleteVenueContactModal />
			<GroupContainer container>
				<GroupTitleContainer container xs={12} sm={12} lg={5} xl={4}>
					<Grid xs={10} sm={7} lg={12}>
						<h3>{t("phoneNumberAndEmailAddress_plural")}</h3>
					</Grid>

					<Grid xs={12}>
						<NewButton
							text={t("addPhoneOrEmail")}
							iconElement={<Add htmlColor={colorPalette.primary.value} />}
							buttonType="Outline"
							borderColor="secondary"
							onClick={() =>
								setProductionState(prev => ({
									...prev,
									addContactModalOpen: true,
								}))
							}
						/>
					</Grid>
					<Grid xs={12} sm={7} lg={12}>
						<p>{t("addGeneralVenueContactBlurb")}</p>
					</Grid>
				</GroupTitleContainer>

				<Grid container xs={12} sm={12} lg gap={3}>
					{newContactData.length === 0 ? (
						<StyledPlaceholderGrid xs={12} display={"flex"} flexGrow={1}>
							<PlaceholderContent text={t("noNumberOrEmailAdded_plural")} />
						</StyledPlaceholderGrid>
					) : (
						newContactData.map((contact, idx) => {
							return (
								<Grid
									xs={12}
									key={idx}
									data-cy={`production-VenueContact-Card-${idx}`}
									display={"flex"}
									flexDirection={"column"}
									gap={2}
								>
									<GeneralVenueContactDetail
										contact={contact}
										idx={idx}
										setContact={setNewContactData}
									/>
									{idx + 1 !== newContactData.length && <StyledDivider flexItem />}
								</Grid>
							)
						})
					)}
				</Grid>
			</GroupContainer>
		</>
	)
}

const StyledDivider = styled(Divider)`
	width: 100%;
	margin-top: 8px;
	margin-bottom: 8px;
`

const StyledPlaceholderGrid = styled(Grid)`
	& > div {
		${mediaQuery("lg")`
			height: 192px;
		`}
	}
`

const GroupContainer = styled(Grid)`
	width: 100%;
	display: flex;
	padding: 0;
	margin-bottom: 48px;

	${mediaQuery("lg")`
		gap: 24px;
	`}
`

const GroupTitleContainer = styled(Grid)`
	padding: 0px 48px;
	margin-left: -40px;

	gap: 16px;
	display: flex;
	flex-direction: column;
	align-items: start;

	${mediaQuery("lg")`
		gap: 24px;
	`}

	& h3 {
		color: ${({ theme }) => theme.colorPalette.surface.on};
		font: 400 24px/32px Roboto-Regular;
		padding-bottom: 8px;
	}

	& p {
		color: ${({ theme }) => theme.colorPalette.secondary.value};
		font: 500 11px/16px Signika-Regular;
	}
`
