import { useMemo } from "react"
import { useTranslation } from "react-i18next"
import { Chip, IconButton, Stack, styled } from "@mui/material"
import {
	AddOutlined,
	EditOutlined,
	VisibilityOffOutlined,
	VisibilityOutlined,
} from "@mui/icons-material"
import { useStaffCrew } from "@/context/staffCrew"
import { StaffCrewContextProvider } from "@/context/staffCrew"
import NewButton from "@atoms/NewButton"
import { PlaceholderContent } from "@atoms/PlaceholderContent"
import { RowData, SortableList } from "@molecules/SortableList"
import { PageHeader } from "@organisms/PageHeader"
import { AddEditUserModal } from "@organisms/staffCrew/AddEditUserModal"
import LoaderOverlay from "@organisms/LoaderOverlay"
import { StaffFilterChips } from "@organisms/staffCrew/StaffFilterChips"
import { mediaQuery } from "@/util/stylingUtils"
import { PageRootContainer } from "../atoms/PageRootContainer"
import { ScrollableStack } from "../atoms/ScrollableStack"
import { getDisplayName } from "@/util/userUtils"
import { Footer } from "../organisms/Footer"

const HEADER_DATA = (
	[
		"name",
		"title",
		"phone",
		"email",
		"permission",
		"status",
		"techPack",
		"editButton",
	] as StaffCrewTableKey[]
).map(val => ({
	cellKey: val,
	label: val === "editButton" ? "" : val === "techPack" ? "Tech Pack" : val,
	canSort: !["phone", "email", "editButton"].includes(val),
}))

const StaffCrewBase = () => {
	const { t } = useTranslation()
	const { filteredData, setState, isPending } = useStaffCrew()

	const tableData = useMemo(
		() =>
			filteredData.map((row: StaffCrewRow): RowData<StaffCrewTableKey> => {
				const name = getDisplayName(row.name, true)

				const ellipsisOverflow = {
					textOverflow: "ellipsis",
					whiteSpace: "nowrap",
					overflow: "hidden",
				}
				return {
					name: {
						value: name,
						displayContent: name,
						cellProps: {
							sx: {
								maxWidth: "124px",
								...ellipsisOverflow,
							},
						},
					},
					title: {
						value: row.title || "N/A",
						displayContent: row.title || "N/A",
						cellProps: {
							sx: {
								maxWidth: "144px",
								...ellipsisOverflow,
							},
						},
					},
					phone: {
						value: row.phone || "N/A",
						displayContent: row.phone || "N/A",
						cellProps: {
							sx: {
								maxWidth: "104px",
							},
						},
					},
					email: {
						value: row.email || "N/A",
						displayContent: row.email || "N/A",
						cellProps: {
							sx: {
								maxWidth: "238px",
								...ellipsisOverflow,
							},
						},
					},
					permission: {
						value: row.roleName || "N/A",
						displayContent: row.roleName || "N/A",
						cellProps: {
							sx: {
								maxWidth: "118px",
							},
						},
					},
					status: {
						value: row.hasLoggedIn === 1 ? t("active") : t("inactive"),
						displayContent: (
							<StatusChip
								label={row.hasLoggedIn === 1 ? t("active") : t("inactive")}
								isActive={row.hasLoggedIn === 1}
							/>
						),
						cellProps: {
							width: "94px",
						},
					},
					techPack: {
						value: row.includedInTechpack,
						displayContent: row.includedInTechpack ? (
							<VisibilityOutlined />
						) : (
							<VisibilityOffOutlined />
						),
						cellProps: {
							variant: "iconOnly" as const,
						},
					},
					editButton: {
						value: "",
						displayContent: (
							<StyledIconButton
								color="primary"
								onClick={() =>
									setState(prev => ({
										...prev,
										editStaffModalOpen: true,
										selectedUserId: row.userId,
									}))
								}
							>
								<EditOutlined />
							</StyledIconButton>
						),
					},
				}
			}),
		[filteredData, setState, t],
	)

	return (
		<>
			<LoaderOverlay isLoading={isPending} />
			<AddEditUserModal />
			<PageRootContainer>
				<PageHeader pageTitle={t("staffCrew")}>
					<NewButton
						text={t("addNewUser")}
						buttonType="Outline"
						borderColor="primary"
						iconElement={<AddOutlined />}
						onClick={() => setState(prev => ({ ...prev, addStaffModalOpen: true }))}
						data-cy="staffCrew-addUserButton"
					/>
				</PageHeader>
				<PermissionLevelsContainer>
					<div>
						<p>{t("permissionLevel_plural")}</p>
						<ul>
							<li>{t("venueAdminPermissionLevel")}</li>
							<li>{t("venueManagerPermissionLevel")}</li>
							<li>{t("venueStaffPermissionLevel")}</li>
						</ul>
					</div>
					<ActivationHintText>
						<i>{t("venuePermissionsActivationHint")}</i>
					</ActivationHintText>
				</PermissionLevelsContainer>
				<ScrollableStack>
					<StaffFilterChips />
					{tableData.length > 0 ? (
						<SortableList headerData={HEADER_DATA} tableContent={tableData} />
					) : (
						<PlaceholderContent text={t("noStaffFound")} height="272px" />
					)}
					<Footer />
				</ScrollableStack>
			</PageRootContainer>
		</>
	)
}

export const StaffCrew = () => {
	return (
		<StaffCrewContextProvider>
			<StaffCrewBase />
		</StaffCrewContextProvider>
	)
}

const StyledIconButton = styled(IconButton)`
	&.MuiIconButton-colorPrimary {
		color: ${({ theme }) => theme.colorPalette.primary.value};
	}
`

const StatusChip = styled(Chip, {
	shouldForwardProp: prop => prop !== "isActive",
})<{
	isActive: boolean
}>`
	&.MuiChip-root {
		padding: 0px 8px;
		border-radius: 0px;
		background-color: ${({ theme, isActive }) =>
			isActive
				? theme.colorPalette.tertiary.container.value
				: theme.colorPalette.secondary.fixed.dim};
		height: 16px;
		width: 100%;
	}

	& .MuiChip-label {
		font: 500 11px/16px Signika-Medium;
		text-align: center;
		padding: 0px;
	}
`

const ActivationHintText = styled("p")`
	&::before {
		content: "*";
	}
`

const PermissionLevelsContainer = styled(Stack)`
	align-items: start;
	gap: 10px;
	color: ${({ theme }) => theme.colorPalette.surface.on};
	padding: 0px 48px;
	padding-bottom: 0px;

	${mediaQuery("md")`
		padding: 0px 16px;
	`}

	& p {
		font: 500 14px/20px FiraSans-Medium;
	}

	& ul {
		padding: 0px 24px;
		li {
			letter-spacing: 0.25px;
			font: 400 14px/20px Roboto-Regular;
		}
	}

	& i {
		font: 400 14px/20px FiraSans-Regular;
		font-style: italic;
	}
`
