import styled from "styled-components"
import { useTranslation } from "react-i18next"
import { LogoImage } from "../molecules/LogoImage"
import { PageHeader } from "../organisms/PageHeader"
import NewButton from "../atoms/NewButton"
import { Check } from "@mui/icons-material"
import FormField from "../atoms/FormField"
import { VenueLevelPermissions } from "../organisms/profile/VenueLevelPermissions"
import { ProfileContextProvider, useProfileContext } from "@/context/profile"
import Grid from "@mui/material/Unstable_Grid2"
import { mediaQuery } from "@/util/stylingUtils"
import { Divider } from "../atoms/Divider"
import { ScrollableStack } from "../atoms/ScrollableStack"
import { PageRootContainer } from "../atoms/PageRootContainer"
import { Footer } from "../organisms/Footer"

const ProfileBase: React.FC<{}> = () => {
	const { t } = useTranslation()
	const {
		userProfile,
		state: profileState,
		setState: setProfileState,
		canSubmit,
		updateUserProfile,
		updateUserAvatar,
	} = useProfileContext()

	return (
		<PageRootContainer>
			<PageHeader pageTitle={t("profile")}>
				<NewButton
					text={t("saveChange_plural")}
					buttonType="Outline"
					borderColor="primary"
					iconElement={<Check />}
					disabled={!canSubmit}
					onClick={updateUserProfile}
					data-cy={"profile-savechanges"}
				/>
			</PageHeader>
			<ScrollableStack>
				<ProfileSectionContainer container xs={12}>
					<Grid xs={12} lg="auto">
						<LogoImage
							aspectRatio={[1, 1]}
							idealSize={[1000, 1000]}
							captionText={t("profileImage")}
							imageWidth={300}
							handleInputChange={updateUserAvatar}
							previewUrl={userProfile?.avatarUrl ?? undefined}
							variant="circle"
							data-cy={"profile-profileimage"}
						/>
					</Grid>

					<Grid container xs={12} lg gap={2}>
						<Grid xs={12}>
							<ProfileEmail>{userProfile?.email ?? ""}</ProfileEmail>
						</Grid>
						<Grid container xs={12} gap={3}>
							<Grid xs>
								<FormField
									label={t("legalFirstName")}
									value={profileState.firstName}
									onChange={e =>
										setProfileState(prev => ({
											...prev,
											firstName: e.target.value,
										}))
									}
									fullWidth
									required
									data-cy={"profile-firstname"}
								/>
							</Grid>
							<Grid xs>
								<FormField
									label={t("legalLastName")}
									value={profileState.lastName}
									onChange={e =>
										setProfileState(prev => ({
											...prev,
											lastName: e.target.value,
										}))
									}
									fullWidth
									required
									data-cy={"profile-lastname"}
								/>
							</Grid>
						</Grid>
						<Grid container xs={12} gap={3}>
							<Grid xs>
								<FormField
									label={t("preferredName")}
									value={profileState.preferredName}
									onChange={e =>
										setProfileState(prev => ({
											...prev,
											preferredName: e.target.value,
										}))
									}
									fullWidth
									data-cy={"profile-preferredname"}
								/>
							</Grid>
							<Grid xs>
								<FormField
									label={t("phoneNumber")}
									value={profileState.phone}
									onChange={e =>
										setProfileState(prev => ({
											...prev,
											phone: e.target.value,
										}))
									}
									fullWidth
									required
									data-cy={"profile-phonenumber"}
								/>
							</Grid>
						</Grid>
					</Grid>
				</ProfileSectionContainer>

				<StyledDivider flexItem />

				<VenueLevelPermissions />
				<Footer />
			</ScrollableStack>
		</PageRootContainer>
	)
}

export const Profile: React.FC<{}> = () => {
	return (
		<ProfileContextProvider>
			<ProfileBase />
		</ProfileContextProvider>
	)
}

const StyledDivider = styled(Divider)`
	margin: 24px 16px;
	${mediaQuery("lg")`
		margin-left: 24px;
		margin-right: 24px;
	`}
`

const ProfileEmail = styled("h4")`
	font-family: Signika-Regular;
	font-weight: 400;
	font-size: 22px;
	line-height: 28px;
	color: ${({ theme }) => theme.colorPalette.surface.onVariant};
	user-select: none;
	padding-bottom: 8px;
`

const ProfileSectionContainer = styled(Grid)`
	& {
		width: 100%;
		min-width: 100%;
		gap: 48px;
		justify-content: center;
		align-items: center;
	}
`
