import { styled, Tab as MuiTab } from "@mui/material"

export const Tab = styled(MuiTab)`
	& {
		display: flex;
		justify-content: center;
		align-items: center;
		align-self: stretch;

		color: ${({ theme }) => theme.colorPalette.surface.on};
		font: 500 14px/20px Signika-Regular;
		text-align: center;
		letter-spacing: 0.1px;
		text-transform: none;

		border: 1px solid ${({ theme }) => theme.colorPalette.outline.value};
		padding: 6px 24px;
		white-space: nowrap;

		height: 40px;
		min-height: 40px;
		transition: all 150ms ease-out;
	}

	& .MuiTab-iconWrapper {
		width: 0;
		visibility: hidden;
		transition: all 150ms ease-out;
		margin-right: 0px;
		opacity: 0;
	}

	&.Mui-selected {
		background-color: ${({ theme }) => theme.colorPalette.secondary.container.value};
		color: ${({ theme }) => theme.colorPalette.secondary.container.on};
		text-decoration: none;
		padding-left: 16px;

		&:hover {
			background-color: ${({ theme }) => theme.colorPalette.secondary.container.value};
		}
		.MuiTab-iconWrapper {
			visibility: visible;
			width: 18px;
			margin-right: 8px;
			opacity: 1;
		}
	}

	&:hover {
		background-color: ${({ theme }) => theme.colorPalette.stateLayers.primary.value}14;
	}

	&:active {
		background-color: ${({ theme }) => theme.colorPalette.stateLayers.primary.value}1F;
	}
`
