import { PageHeader } from "@organisms/PageHeader"
import { useTranslation } from "react-i18next"
import { Box, Chip, styled, SvgIcon } from "@mui/material"
import Autocomplete from "@molecules/Autocomplete"
import { SortableList } from "@molecules/SortableList"
import { TablePagination } from "@molecules/TablePagination"
import { getHistoryTimestamp, getActionType, getFilterFieldSupportText } from "@/util/historyUtils"
import HistoryContextProvider, { useHistoryContext } from "@/context/history"
import LoaderOverlay from "../organisms/LoaderOverlay"
import { useCallback, useEffect, useMemo, useState } from "react"
import { compare } from "@/util/compare"
import { HistoryDetailModal } from "../organisms/history/HistoryDetailModal"
import { useVenueStageTypes } from "@/hooks/useVenueStageTypes"
import { mediaQuery } from "@/util/stylingUtils"
import { PageRootContainer } from "../atoms/PageRootContainer"
import { ScrollableStack } from "../atoms/ScrollableStack"
import Grid from "@mui/material/Unstable_Grid2"
import useWindowDimensions from "@/hooks/useWindowDimensions"
import { BREAKPOINTS } from "@/util/constants/BREAKPOINTS"
import { getDisplayName } from "@/util/userUtils"
import { normalizeStringBoolean } from "@/util/dataValidation"
import { Footer } from "../organisms/Footer"

const HistoryBase: React.FC<{}> = () => {
	const { t } = useTranslation()
	const { width: windowWidth } = useWindowDimensions()
	const {
		state: { filterFieldName, filterUserId },
		setState,
		objectChangelog,
		dataPending,
	} = useHistoryContext()

	const [currField, setCurrField] = useState<{ label: string; support?: string } | null>(null)
	const [currUser, setCurrUser] = useState<{ label: string; support?: string } | null>(null)

	const { venueStageTypes } = useVenueStageTypes()

	const fieldOptions = useMemo(() => {
		if (objectChangelog === undefined) return []

		return objectChangelog.fields.reduce<
			{
				columnName: string
				option: { label: string; support: string }
			}[]
		>((prev, curr) => {
			let labelTranslationKey = curr.labelTranslationKey

			labelTranslationKey =
				labelTranslationKey === "filename"
					? "fileName"
					: labelTranslationKey === "filesize"
					? "fileSize"
					: labelTranslationKey
			return [
				...prev,
				{
					columnName: curr.columnName,
					option: {
						label: t(labelTranslationKey),
						support: getFilterFieldSupportText(curr)
							.map(val => t(val))
							.join(" > "),
					},
				},
			]
		}, [])
	}, [objectChangelog, t])

	const userOptions = useMemo(() => {
		if (objectChangelog === undefined) return []

		return objectChangelog.users.reduce<
			{
				userId: string
				option: { label: string; support?: string }
			}[]
		>((prev, curr) => {
			return [
				...prev,
				{
					userId: curr.id,
					option: { label: getDisplayName(curr.name, true) },
				},
			]
		}, [])
	}, [objectChangelog])

	const formatEntries = useCallback(
		(val: HistoryDataChangeLog) => {
			const formatEntry = (name: string, entry: string) => {
				switch (name) {
					case "venueStageTypeId":
						return t(
							venueStageTypes.find(stageType => stageType.id === entry)?.translationKey || "null",
						)
					case "venueTypeId":
						return t(entry)
					case "isMainStage":
					case "isActive":
						return t(normalizeStringBoolean(entry))
					default:
						return entry
				}
			}
			return {
				previousEntry: formatEntry(val.fieldNameMetadata.name, val.originalValue || "null"),
				updatedEntry: formatEntry(val.fieldNameMetadata.name, val.updatedValue || "null"),
			}
		},
		[venueStageTypes, t],
	)

	const tableData = useMemo(() => {
		if (objectChangelog === undefined) return []

		return objectChangelog.dataChangeLogs.map(val => {
			const { previousEntry, updatedEntry } = formatEntries(val)

			let labelTranslationKey = val.fieldNameMetadata?.labelTranslationKey ?? ""

			labelTranslationKey =
				labelTranslationKey === "filename"
					? "fileName"
					: labelTranslationKey === "filesize"
					? "fileSize"
					: labelTranslationKey

			return {
				timestamp: getHistoryTimestamp(val.actionDate),
				name: getDisplayName(val.user.name),
				action: (() => {
					const actionType = getActionType(val)
					return {
						value: actionType,
						displayContent: <ActionChip action={actionType} label={actionType} />,
					}
				})(),
				fieldName: {
					value: val.fieldName,
					displayContent: (
						<>
							<FieldName>{t(labelTranslationKey)}</FieldName>
							<FieldCategory>
								{getFilterFieldSupportText(val.fieldNameMetadata)
									.map(val => t(val))
									.join(" > ")}
							</FieldCategory>
						</>
					),
				},
				previousEntry: {
					value: previousEntry === "null" ? `< ${t("empty")} >` : previousEntry,
					displayContent: (
						<TextEntryContainer>
							{previousEntry === "null" ? `< ${t("empty")} >` : previousEntry}
						</TextEntryContainer>
					),
				},
				updatedEntry: {
					value: updatedEntry === "null" ? `< ${t("empty")} >` : updatedEntry,
					displayContent: (
						<TextEntryContainer>
							{updatedEntry === "null" ? `< ${t("empty")} >` : updatedEntry}
						</TextEntryContainer>
					),
				},
				openModal: {
					value: "",
					displayContent:
						getActionType(val) === "changed" ? (
							<StyledSvgIcon
								onClick={() =>
									setState(prev => ({
										...prev,
										modalOpen: true,
										selectedDatalogId: val.id,
									}))
								}
							>
								<svg
									viewBox="0 0 24 24"
									fill="currentColor"
									xmlns="http://www.w3.org/2000/svg"
									stroke="currentColor"
									strokeWidth={0.05}
								>
									<path d="M5 22H2C1.45 22 0.979167 21.8042 0.5875 21.4125C0.195833 21.0208 0 20.55 0 20V17H2V20H5V22ZM17 22V20H20V17H22V20C22 20.55 21.8042 21.0208 21.4125 21.4125C21.0208 21.8042 20.55 22 20 22H17ZM11 17.5C9 17.5 7.1875 16.9083 5.5625 15.725C3.9375 14.5417 2.75 12.9667 2 11C2.75 9.03333 3.9375 7.45833 5.5625 6.275C7.1875 5.09167 9 4.5 11 4.5C13 4.5 14.8125 5.09167 16.4375 6.275C18.0625 7.45833 19.25 9.03333 20 11C19.25 12.9667 18.0625 14.5417 16.4375 15.725C14.8125 16.9083 13 17.5 11 17.5ZM11 15.5C12.4667 15.5 13.8083 15.1 15.025 14.3C16.2417 13.5 17.175 12.4 17.825 11C17.175 9.6 16.2417 8.5 15.025 7.7C13.8083 6.9 12.4667 6.5 11 6.5C9.53333 6.5 8.19167 6.9 6.975 7.7C5.75833 8.5 4.825 9.6 4.175 11C4.825 12.4 5.75833 13.5 6.975 14.3C8.19167 15.1 9.53333 15.5 11 15.5ZM11 14.5C11.9667 14.5 12.7917 14.1583 13.475 13.475C14.1583 12.7917 14.5 11.9667 14.5 11C14.5 10.0333 14.1583 9.20833 13.475 8.525C12.7917 7.84167 11.9667 7.5 11 7.5C10.0333 7.5 9.20833 7.84167 8.525 8.525C7.84167 9.20833 7.5 10.0333 7.5 11C7.5 11.9667 7.84167 12.7917 8.525 13.475C9.20833 14.1583 10.0333 14.5 11 14.5ZM11 12.5C10.5833 12.5 10.2292 12.3542 9.9375 12.0625C9.64583 11.7708 9.5 11.4167 9.5 11C9.5 10.5833 9.64583 10.2292 9.9375 9.9375C10.2292 9.64583 10.5833 9.5 11 9.5C11.4167 9.5 11.7708 9.64583 12.0625 9.9375C12.3542 10.2292 12.5 10.5833 12.5 11C12.5 11.4167 12.3542 11.7708 12.0625 12.0625C11.7708 12.3542 11.4167 12.5 11 12.5ZM0 5V2C0 1.45 0.195833 0.979167 0.5875 0.5875C0.979167 0.195833 1.45 0 2 0H5V2H2V5H0ZM20 5V2H17V0H20C20.55 0 21.0208 0.195833 21.4125 0.5875C21.8042 0.979167 22 1.45 22 2V5H20Z" />
								</svg>
							</StyledSvgIcon>
						) : (
							""
						),
				},
			}
		})
	}, [objectChangelog, t, formatEntries, setState])

	useEffect(() => {
		const newVal =
			currField === null ? null : fieldOptions.find(val => compare(val.option, currField))

		if (newVal !== undefined) {
			if (newVal === null) {
				if (filterFieldName !== null) {
					setState(prev => ({
						...prev,
						filterFieldName: null,
						page: 1,
					}))
				}
			} else if (newVal.columnName !== filterFieldName) {
				setState(prev => ({
					...prev,
					filterFieldName: newVal.columnName,
					page: 1,
				}))
			}
		}
	}, [currField, setState, fieldOptions, filterFieldName])

	useEffect(() => {
		const newVal = currUser === null ? null : userOptions.find(val => compare(val.option, currUser))

		if (newVal !== undefined) {
			if (newVal === null) {
				if (filterUserId !== null) {
					setState(prev => ({
						...prev,
						filterUserId: null,
						page: 1,
					}))
				}
			} else if (newVal.userId !== filterUserId) {
				setState(prev => ({
					...prev,
					filterUserId: newVal.userId,
					page: 1,
				}))
			}
		}
	}, [currUser, setState, userOptions, filterUserId])

	const siblingCount = useMemo(() => {
		if (windowWidth > BREAKPOINTS.xl) {
			return 3
		} else if (windowWidth > BREAKPOINTS.sm) {
			return 2
		} else {
			return 0
		}
	}, [windowWidth])

	return (
		<>
			<LoaderOverlay isLoading={dataPending} />
			<HistoryDetailModal />
			<PageRootContainer>
				<PageHeader pageTitle={t("history")} />
				<ScrollableStack>
					<FilterContainer container>
						<Grid xs={12} md="auto">
							<Autocomplete
								multiple={false}
								options={fieldOptions.map(val => val.option)}
								selectLabel={t("filterBy")}
								placeholder={t("fieldName")}
								value={currField}
								onChange={(_, newVal) =>
									typeof newVal === "string" ? setCurrField(null) : setCurrField(newVal)
								}
								isOptionEqualToValue={compare}
								fullWidth={windowWidth <= BREAKPOINTS.md}
							/>
						</Grid>
						<Grid xs={12} md="auto">
							<Autocomplete
								multiple={false}
								options={userOptions.map(val => val.option)}
								selectLabel={t("filterBy")}
								placeholder={t("username")}
								value={currUser}
								onChange={(_, newVal) =>
									typeof newVal === "string" ? setCurrUser(null) : setCurrUser(newVal)
								}
								isOptionEqualToValue={compare}
								fullWidth={windowWidth <= BREAKPOINTS.md}
							/>
						</Grid>
					</FilterContainer>
					<Box>
						<SortableList
							headerData={[
								{
									cellKey: "timestamp",
									label: t("timeStamp"),
									cellProps: {
										width: "11%",
									},
								},
								{
									cellKey: "name",
									label: t("editedBy"),
									cellProps: {
										width: "14%",
									},
								},
								{
									cellKey: "action",
									label: t("action"),
									cellProps: {
										width: "9%",
									},
								},
								{
									cellKey: "fieldName",
									label: t("fieldName"),
									cellProps: {
										width: "17%",
									},
								},
								{
									cellKey: "previousEntry",
									label: t("previousEntry"),
									cellProps: {
										width: "20%",
									},
								},
								{
									cellKey: "updatedEntry",
									label: t("updatedEntry"),
									cellProps: {
										width: "20%",
									},
								},
								{
									cellKey: "openModal",
									label: "",
									cellProps: {
										width: "5%",
									},
								},
							]}
							tableContent={tableData}
							isSortable={false}
						/>
					</Box>
					<Box>
						<TablePagination
							count={objectChangelog?.pagination.lastPage || 1}
							siblingCount={siblingCount}
							boundaryCount={1}
							page={objectChangelog?.pagination.currentPage || 1}
							onChange={(_, newPage) =>
								setState(prev => ({
									...prev,
									page: newPage,
								}))
							}
						/>
					</Box>
					<Footer />
				</ScrollableStack>
			</PageRootContainer>
		</>
	)
}

export const History: React.FC<{}> = () => {
	return (
		<HistoryContextProvider>
			<HistoryBase />
		</HistoryContextProvider>
	)
}

const TextEntryContainer = styled("p")`
	font: inherit;
	max-width: 208px;
	display: -webkit-box;
	-webkit-line-clamp: 2;
	-webkit-box-orient: vertical;
	overflow: hidden;
	text-overflow: ellipsis;
`

const StyledSvgIcon = styled(SvgIcon)`
	color: ${({ theme }) => theme.colorPalette.primary.value};
	cursor: pointer;
`

const FieldName = styled("p")`
	font: 400 14px/20px Signika-Regular;
	color: ${({ theme }) => theme.colorPalette.surface.on};
	text-transform: capitalize;
`
const FieldCategory = styled("p")`
	font: 500 11px/16px Signika-Medium;
	color: ${({ theme }) => theme.colorPalette.surface.onVariant};
`

const ActionChip = styled(Chip, {
	shouldForwardProp: prop => prop !== "action",
})<{ action: string }>`
	border-radius: 0px;
	gap: 4px;
	height: 16px;
	width: 56px;

	& .MuiChip-label {
		font: 500 11px/16px Signika-Medium;
		padding: 0px;
		text-align: center;
		text-transform: capitalize;
	}

	${({ theme, action }) => {
		switch (action) {
			case "added":
				return `
					background-color: ${theme.colorPalette.primary.value};
					color: ${theme.colorPalette.primary.on}
				`
			case "changed":
				return `
					background-color: ${theme.colorPalette.primary.container.value};
					color: ${theme.colorPalette.primary.container.on};
				`
			case "deleted":
				return `
					background-color: ${theme.colorPalette.error.container.value};
					color: ${theme.colorPalette.error.container.on};
				`
			default:
				return `
					background-color: ${theme.colorPalette.primary.value};
				`
		}
	}};
`

const FilterContainer = styled(Grid)`
	gap: 16px;
	padding-top: 24px;

	${mediaQuery("md")`
		padding-top: 0px;
		gap: 24px;
	`}
`
