import FormField from "@/components/atoms/FormField"
import { LogoImage } from "@/components/molecules/LogoImage"
import { PreviousNames } from "@/components/molecules/production/PreviousNames"
import countries from "@/util/constants/COUNTRIES"
import { Select } from "@/components/molecules/Select"
import { Divider, Stack, styled } from "@mui/material"
import { useTranslation } from "react-i18next"
import { GeneralVenueContacts } from "./GeneralVenueContacts"
import { useCallback, useEffect, useMemo, useState } from "react"
import { useVenuesContext } from "@/context/venues"
import DEFAULT_VENUE from "@/util/constants/defaults/DEFAULT_VENUE"
import { useProductionContext } from "@/context/production"
import useProductionAttachments from "@/hooks/useProductionAttachments"
import { ensureVarIsArray } from "@/util/dataValidation"
import Grid from "@mui/material/Unstable_Grid2"
import useWindowDimensions from "@/hooks/useWindowDimensions"

export const GeneralVenueInformation: React.FC<{}> = () => {
	const { t } = useTranslation()
	const { selectedVenue } = useVenuesContext()
	const { setState: setProductionState, state: productionState } = useProductionContext()
	const { venueLogo, handleLogoUpload } = useProductionAttachments()

	const [venueState, setVenueState] = useState(selectedVenue ?? DEFAULT_VENUE)
	const [venueWebsite, setVenueWebsite] = useState(
		ensureVarIsArray(selectedVenue?.urls).find(val => val.label === "primary")?.url ?? "",
	)

	const [country, setCountry] = useState<string>(
		selectedVenue == null || selectedVenue.country === ""
			? ""
			: t(`country${selectedVenue.country}`, { ns: "countries" }),
	)
	const [publicNotes, setPublicNotes] = useState(
		ensureVarIsArray(selectedVenue?.notes).find(val => val.label === "public")?.notes ?? "",
	)

	const countryCodes = useMemo(
		() => countries.map(country => t(`country${country}`, { ns: "countries" })),
		[t],
	)

	const updateVenueState = useCallback(
		(fieldKey: keyof Venue | "publicNotes", newValue: string) => {
			switch (fieldKey) {
				case "publicNotes":
					setPublicNotes(newValue)
					break
				case "website":
					setVenueWebsite(newValue)
					break
				default:
					setVenueState(prev => ({
						...prev,
						[fieldKey]: newValue,
					}))
			}
		},
		[setVenueState, setPublicNotes, setVenueWebsite],
	)

	useEffect(() => {
		const delayDebounceFn = setTimeout(() => {
			setProductionState(prev => {
				const newNotes = (prev.venue.notes ?? []).filter(val => val.label !== "public")
				const newUrls = (prev.venue.urls ?? []).filter(val => val.label !== "primary")

				return {
					...prev,
					venue: {
						...prev.venue,
						name: venueState.name,
						addressLine1: venueState.addressLine1,
						addressLine2: venueState.addressLine2,
						city: venueState.city,
						zip: venueState.zip,
						latitude: venueState.latitude,
						longitude: venueState.longitude,
						ageRequirement: venueState.ageRequirement,
						notes: [
							...newNotes,
							{
								label: "public",
								notes: publicNotes,
							},
						],
						urls: [
							...newUrls,
							{
								label: "primary",
								url: venueWebsite,
							},
						],
						country:
							countries.find(val => t(`country${val}`, { ns: "countries" }) === country) ?? "",
						state: venueState.state,
					},
				}
			})
		}, 500)

		return () => clearTimeout(delayDebounceFn)
	}, [venueState, venueWebsite, country, publicNotes, setProductionState, t])

	useEffect(() => {
		if (selectedVenue !== null && selectedVenue.id !== venueState.id) {
			setVenueState(selectedVenue)
			setPublicNotes(
				ensureVarIsArray(selectedVenue?.notes).find(val => val.label === "public")?.notes ?? "",
			)
			setVenueWebsite(
				ensureVarIsArray(selectedVenue?.urls).find(val => val.label === "primary")?.url ?? "",
			)
			setCountry(
				selectedVenue.country === ""
					? ""
					: t(`country${selectedVenue.country}`, { ns: "countries" }),
			)
		}
	}, [setVenueState, selectedVenue, venueState, setPublicNotes, t, setVenueWebsite, setCountry])

	useEffect(() => {
		setVenueState(prev => ({
			...prev,
			latitude: productionState.venue.latitude,
			longitude: productionState.venue.longitude,
		}))
	}, [setVenueState, productionState.venue.latitude, productionState.venue.longitude])

	const windowDimensions = useWindowDimensions()

	return (
		<RootContainer container xs={12} spacing={6} padding={0}>
			<Grid xs={12} lg={"auto"} paddingTop={0} display="flex" justifyContent={"center"}>
				<LogoImage
					aspectRatio={[4, 3]}
					idealSize={[1024, 768]}
					captionText={t("defaultVenueImageLogo")}
					imageWidth={
						windowDimensions.width < 1023
							? windowDimensions.width < 787
								? windowDimensions.width < 550
									? windowDimensions.width - 32
									: 518
								: 404
							: 336
					}
					handleInputChange={handleLogoUpload}
					previewUrl={venueLogo?.file.publicUrls.urlPreview}
					alt={venueLogo?.file.filename}
					data-cy="generalvenueinfo-venuelogo"
				/>
			</Grid>
			<Grid xs={12} lg container paddingTop={3} spacing={3}>
				<Grid xs={12}>
					<FormField
						label={t("venueName")}
						value={venueState.name ?? ""}
						onChange={e => updateVenueState("name", e.target.value)}
						required
						fullWidth
						data-cy="generalvenueinfo-venuename"
					/>
				</Grid>
				<Grid xs={12}>
					<FormField
						label={t("venueWebsite")}
						value={venueWebsite}
						onChange={e => updateVenueState("website", e.target.value)}
						fullWidth
						data-cy="generalvenueinfo-venuewebsite"
					/>
				</Grid>
				<Grid xs={12}>
					<PreviousNames data-cy="generalvenueinfo-previousnames" />
				</Grid>
			</Grid>
			<Grid xs={12} paddingTop={3} container spacing={6}>
				<Grid container xs={12} lg spacing={3}>
					<Grid xs={12}>
						<FormField
							label={t("addressLine1")}
							value={venueState.addressLine1 ?? ""}
							onChange={e => updateVenueState("addressLine1", e.target.value)}
							fullWidth
							data-cy="generalvenueinfo-addressline1"
						/>
					</Grid>
					<Grid xs={12}>
						<FormField
							label={t("addressLine2")}
							value={venueState.addressLine2 ?? ""}
							onChange={e => updateVenueState("addressLine2", e.target.value)}
							fullWidth
							data-cy="generalvenueinfo-addressline2"
						/>
					</Grid>
					<Grid xs={12}>
						<FormField
							label={t("city")}
							value={venueState.city ?? ""}
							onChange={e => updateVenueState("city", e.target.value)}
							fullWidth
							data-cy="generalvenueinfo-city"
						/>
					</Grid>
					<Grid xs={12} container>
						<Grid xs={6}>
							<FormField
								label={t("stateProvince")}
								value={venueState.state ?? ""}
								onChange={e => updateVenueState("state", e.target.value)}
								fullWidth
								data-cy="generalvenueinfo-state"
							/>
						</Grid>
						<Grid xs={6}>
							<FormField
								label={t("zipPostalCode")}
								value={venueState.zip ?? ""}
								onChange={e => updateVenueState("zip", e.target.value)}
								fullWidth
								data-cy="generalvenueinfo-postalcode"
							/>
						</Grid>
					</Grid>
					<Grid xs={12}>
						<Select
							data-cy="generalvenueinfo-country"
							label={t("country")}
							value={country}
							onChange={e => setCountry(e.target.value)}
							optionValues={countryCodes}
							fullWidth
							sx={{
								"& .MuiOutlinedInput-notchedOutline": {
									borderRadius: "4px",
								},
							}}
							MenuProps={{
								sx: {
									"& .MuiPaper-root": {
										maxHeight: "200px",
									},
								},
							}}
						/>
					</Grid>
					<Grid xs={12} container>
						<Grid xs={6}>
							<FormField
								label={t("latitude")}
								onChange={e => updateVenueState("latitude", e.target.value)}
								value={venueState.latitude ?? ""}
								fullWidth
								data-cy="generalvenueinfo-latitude"
							/>
						</Grid>
						<Grid xs={6}>
							<FormField
								label={t("longitude")}
								onChange={e => updateVenueState("longitude", e.target.value)}
								value={venueState.longitude ?? ""}
								fullWidth
								data-cy="generalvenueinfo-logitude"
							/>
						</Grid>
					</Grid>
				</Grid>
				<Grid xs={12} lg="auto">
					<StyledDivider orientation={windowDimensions.width < 1023 ? "horizontal" : "vertical"} />
				</Grid>
				<Grid xs>
					<Stack
						spacing={3}
						sx={{
							height: "100%",
						}}
					>
						<FormField
							label={t("ageRestriction_plural")}
							value={venueState.ageRequirement ?? ""}
							onChange={e => updateVenueState("ageRequirement", e.target.value)}
							fullWidth
							data-cy="generalvenueinfo-agerestriction"
						/>
						<StyledTextArea
							label={t("publicNotes")}
							value={publicNotes}
							onChange={e => updateVenueState("publicNotes", e.target.value)}
							fullWidth
							multiline
							maxRows={14}
							sx={{
								height: windowDimensions.width < 1023 ? "376px" : "100%",
								flexGrow: 1,
							}}
							data-cy="generalvenueinfo-publicnotes"
						/>
					</Stack>
				</Grid>
			</Grid>
			<Grid xs={12} paddingTop={"48px"} paddingBottom={"48px"}>
				<StyledDivider />
			</Grid>
			<Grid xs={12}>
				<GeneralVenueContacts />
			</Grid>
		</RootContainer>
	)
}

const StyledTextArea = styled(FormField)`
	& .MuiInputBase-root.MuiOutlinedInput-root.MuiInputBase-formControl {
		overflow-y: scroll;
		scrollbar-width: thin;
		position: static;
		align-items: start;
		gap: 10px;
		scrollbar-width: thin;
	}
`

const RootContainer = styled(Grid)`
	& {
		margin-top: 0;
		padding-top: 24px;
		width: auto;
		height: auto;
	}
`

const StyledDivider = styled(Divider)`
	border-color: ${({ theme }) => theme.colorPalette.outline.variant};
`
