import { QueryFilters } from "@tanstack/react-query"
import { ensureVarIsArray } from "./dataValidation"
import { getObjectEntries, hasOwn } from "./typedObjectMapping"

export const getQueryFilters = (
	primaryKeyList: string[],
	secondaryKeyDictionary?: Record<string, any>,
) => {
	const queryFilters: QueryFilters = {
		predicate: query => {
			const formattedQueryKey = ensureVarIsArray(query.queryKey)
			if (formattedQueryKey.length === 0) {
				return true
			}

			if (
				typeof formattedQueryKey[0] === "string" &&
				primaryKeyList.includes(formattedQueryKey[0])
			) {
				return true
			}

			if (formattedQueryKey.length > 1 && secondaryKeyDictionary !== undefined) {
				const queryObj = formattedQueryKey[1]

				if (typeof queryObj === "object" && queryObj !== null) {
					return (
						getObjectEntries(secondaryKeyDictionary).find(([key, val]) => {
							return hasOwn(queryObj, key) && queryObj[key] === val
						}) !== undefined
					)
				}
			}

			return false
		},
	}

	return queryFilters
}
