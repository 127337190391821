export const BREAKPOINTS = {
	xs: 360,
	sm: 550,
	md: 787,
	lg: 1023,
	xl: 1300,
} as const

export const HEIGHT_BREAKPOINTS = {
	sm: 590,
	md: 720,
	lg: 1023,
} as const
