import { useRef, useEffect, useState } from "react"
import videojs from "video.js"
import "video.js/dist/video-js.css"
import { styled } from "@mui/material"
import { PlayArrow } from "@mui/icons-material"
import useDarkMode from "../../hooks/useDarkMode"
import { mediaQuery } from "../../util/stylingUtils"

interface VideoTypes {
	file: FileObject
}

const VideoPlayer = ({ file }: VideoTypes) => {
	const videoRef = useRef<HTMLVideoElement>(null)
	const playerRef = useRef<typeof videojs.players>(null)
	const [showPlayButton, setShowPlayButton] = useState(true)
	const { darkMode } = useDarkMode()

	useEffect(() => {
		// Make sure Video.js player is only initialized once
		if (!playerRef.current) {
			const videoElement = document.createElement("video-js")
			const preview = file?.publicUrls?.streaming?.thumbNailsUrls?.length
				? file?.publicUrls?.streaming?.thumbNailsUrls[0]
				: null
			videoElement.classList.add("vjs-big-play-centered")
			if (preview) {
				videoElement.setAttribute("poster", preview)
			}

			if (videoRef.current) {
				videoRef.current.appendChild(videoElement)
				videoRef.current.preload = "auto"
			}

			const player = (playerRef.current = videojs(
				videoElement,
				{
					autoplay: false,
					controls: true,
					responsive: true,
					fill: true,
					disablePictureInPicture: true,
					bigPlayButton: false,
					controlBar: {
						pictureInPictureToggle: false,
						remainingTimeDisplay: false,
						volumePanel: {
							inline: false,
						},
					},
					breakpoints: {
						small: 550,
						medium: 787,
						large: 1023,
						xlarge: 1300,
					},
				},
				() => {
					player.src({
						src: file?.publicUrls?.streaming?.url,
						type: "application/x-mpegURL",
					})
				},
			))
		}
		return () => {
			if (playerRef.current && !playerRef.current.isDisposed()) {
				playerRef.current.dispose()
				playerRef.current = null
			}
		}
	}, [file?.publicUrls?.streaming])

	const handlePlay = () => {
		const player = playerRef.current
		if (player) {
			player.play()
		}
	}
	useEffect(() => {
		// add listener to play/pause video
		const player = playerRef.current
		if (player) {
			player.on("pause", () => {
				setShowPlayButton(true)
			})
			player.on("play", () => {
				setShowPlayButton(false)
			})
		}
	}, [playerRef])

	return (
		<StyledVideo darkMode={darkMode} data-vjs-player data-cy-component="Component-VideoPlayer">
			{/* @ts-ignore */}
			<div ref={videoRef} className="container" />
			{showPlayButton ? (
				<StyledOverlay data-cy="Video_Overlay">
					<StyledPlayIcon data-cy="Video_PlayButton" onClick={handlePlay} />
				</StyledOverlay>
			) : null}
		</StyledVideo>
	)
}

const StyledOverlay = styled("div")`
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;

	display: flex;
	justify-content: center;
	align-items: center;

	background-color: rgba(2, 6, 7, 0.5);
	z-index: 5;
`

const StyledPlayIcon = styled(PlayArrow)`
	& {
		color: ${({ theme }) => theme.colorPalette.primary.inverse};
		width: 96px;
		height: 96px;
		cursor: pointer;

		${mediaQuery("xl")`
			width: 72px;
			height: 72px;
		`}

		${mediaQuery("lg")`
			width: 48px;
			height: 48px;
		`}

		${mediaQuery("md")`
			width: 32px;
			height: 32px;
		`}

		${mediaQuery("sm")`
			width: 24px;
			height: 24px;
		`}
	}
`

const StyledVideo = styled("div", {
	shouldForwardProp: propName => propName !== "darkMode",
})<{ darkMode: boolean }>`
	& {
		position: relative;
		width: 100%;
		height: 100%;
		overflow-y: hidden;
		border-radius: 10px;
	}

	& > .container {
		position: relative;
		width: 100%;
		height: 100%;
	}

	.container > video-js {
		position: relative;
	}

	.container > video-js > video {
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
	}

	video-js,
	video,
	.vjs-error-display {
		border-radius: 10px;
	}

	& video-js > .vjs-control-bar {
		z-index: 6;
		background-color: ${({ theme }) => theme.colorPalette.primary.inverse};

		.vjs-icon-placeholder:before {
			color: ${({ darkMode, theme }) => (darkMode ? "#FFFFFF" : theme.colorPalette.primary.value)};
		}

		.vjs-progress-control > .vjs-progress-holder {
			height: 5px;
			.vjs-load-progress > div {
				background-color: ${({ darkMode, theme }) =>
					darkMode ? "#FFFFFF" : theme.colorPalette.primary.value};
			}

			.vjs-play-progress {
				background-color: ${({ darkMode, theme }) =>
					darkMode ? "#8691A4" : theme.colorPalette.secondary.value};

				&::before {
					color: ${({ darkMode, theme }) =>
						darkMode ? "#FFFFFF" : theme.colorPalette.primary.value};
					font-size: 15px;
				}
			}
			border-radius: 10px;
		}
	}
`

export default VideoPlayer
