import { createContext, useCallback, useContext, useEffect, useMemo, useState } from "react"
import { useTranslation } from "react-i18next"
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query"
import { toast } from "react-toastify"
import useAxios from "../hooks/useAxios"
import { useConfirmCloseContext } from "./confirmClose"
import { compare, compareGeneralVenueInfo, compareProductionData } from "../util/compare"
import DEFAULT_STAGE from "../util/constants/defaults/DEFAULT_STAGE"
import DEFAULT_VENUE from "../util/constants/defaults/DEFAULT_VENUE"
import { formatLatLong, latLongMatch } from "../util/locationHandling"
import { useVenuesContext } from "./venues"
import { ensureVarIsArray } from "@/util/dataValidation"
import { getDisplayName } from "@/util/userUtils"

enum ProductionTab {
	generalVenueInfo = 0,
	contacts,
	production,
	facilities,
	equipment,
	logistics,
	localCrew,
}

const INITIAL_STATE = {
	venue: DEFAULT_VENUE,
	stage: DEFAULT_STAGE,
	formattedProductionContacts: [],

	productionTabIndex: 0,

	addContactModalOpen: false,
	deleteContactModalOpen: false,
	deleteVenueContactIndex: -1,
}

export const ProductionContext = createContext<ProductionContextTypes>({
	state: INITIAL_STATE,
	setState: () => null,

	saveProductionTab: () => null,
	tabHasChanges: false,
	updateProductionTabIndex: () => null,

	productionContacts: [],

	updateVenueContacts: () => null,
	isUpdateVenueContactsLoading: false,
	updateVenuePublicNotes: () => null,
	updateVenueUrl: () => null,
})

export const ProductionContextProvider = ({
	children,
}: React.PropsWithChildren<unknown>): JSX.Element => {
	const { token } = localStorage

	const axios = useAxios()
	const queryClient = useQueryClient()
	const { t } = useTranslation()
	const {
		selectedVenue,
		setSelectedVenue,
		selectedStage,
		patchStage,
		patchVenue,
		patchVenuePending,
		patchStagePending,
	} = useVenuesContext()
	const { shouldPreventClose, setShouldPreventClose } = useConfirmCloseContext()

	const [state, setState] = useState<ProductionStateInterface>(INITIAL_STATE)

	const { mutateAsync: fetchGeocodeAPI } = useMutation({
		mutationKey: ["signed-google-geocode"],
		mutationFn: async (searchAddress: string) => {
			return axios
				.post<{
					data: {
						results: {
							geometry: {
								location: {
									lat: string
									lng: string
								}
							}
						}[]
						status: string
					}
				}>(`/venues/v1/geocode-request`, {
					path: `https://maps.googleapis.com/maps/api/geocode/json?address=${encodeURI(
						searchAddress,
					)}`,
				})
				.then(res => res.data.data)
		},
	})

	const { data: productionContacts = [] } = useQuery({
		queryKey: [
			"production-contacts",
			{
				venueId: selectedVenue?.id ?? "",
			},
		],
		queryFn: () =>
			axios
				.get<{ data: StaffCrewRow[] }>(
					`/venues/v1/venues/${selectedVenue?.id ?? ""}/production-contacts/${
						selectedStage?.id ?? ""
					}`,
				)
				.then(res => res.data.data),
		enabled: selectedVenue !== null && selectedStage !== null,
	})

	const { mutate: updateProductionContacts } = useMutation({
		mutationKey: [
			"patch-production-contacts",
			{
				venueId: selectedVenue?.id ?? "",
				stageId: selectedStage?.id ?? "",
			},
		],
		mutationFn: async (productionContacts: { id: string }[]) => {
			return axios.post(
				`/venues/v1/venues/${selectedVenue?.id ?? ""}/production-contacts/${
					selectedStage?.id ?? ""
				}`,
				{
					order: productionContacts.map((val, idx) => ({
						id: val.id,
						order: idx,
					})),
				},
			)
		},
		onSuccess: () => {
			queryClient.invalidateQueries({
				queryKey: ["production-contacts"],
			})
			toast.success(t("updated"))
		},
	})

	/** Handles venue.contacts
	 * - Should be updated in general venue info implementation
	 */
	const { mutate: updateVenueContacts, isPending: isUpdateVenueContactsLoading } = useMutation({
		mutationKey: ["patchVenueContacts"],
		mutationFn: async ({ contacts }: updateVenueContactsProps) => {
			return await axios.patch<{ data: Venue }>(`/venues/v1/venues/${selectedVenue?.id ?? ""}`, {
				contacts,
			})
		},
		onError: () => {
			toast.error(t("errorTryAgain"))
		},
		onSuccess: (res, { translationKey }) => {
			toast.success(t(translationKey))

			setSelectedVenue((venue: Venue) => (venue ? res.data.data : null))
			setState(s => ({ ...s, venue: res.data.data, originalVenue: res.data.data }))
			queryClient.invalidateQueries({
				queryKey: ["venues", token],
			})
		},
	})

	//edit or create venue.url[label=primary]
	const updateVenueUrl = (value: string) =>
		setState(s => ({
			...s,
			venue: {
				...s.venue,
				urls:
					s.venue?.urls &&
					Object.keys(ensureVarIsArray(s.venue?.urls).find(url => url.label === "primary") ?? {})
						.length > 0
						? s.venue.urls.map(url => (url.label === "primary" ? { ...url, url: value } : url))
						: [...ensureVarIsArray(s.venue?.urls), { label: "primary", url: value }],
			},
		}))

	//edit or create venue.notes[label=public]
	const updateVenuePublicNotes = (value: string) =>
		setState(s => ({
			...s,
			venue: {
				...s.venue,
				notes:
					s.venue?.notes &&
					Object.keys(ensureVarIsArray(s.venue?.notes).find(n => n.label === "public") ?? {})
						.length > 0
						? s.venue.notes.map(note =>
								note.label === "public" ? { ...note, notes: value } : note,
						  )
						: [...ensureVarIsArray(s.venue?.notes), { label: "public", notes: value }],
			},
		}))

	const updateProductionTabIndex = useCallback(
		(idx: number) => {
			if (selectedStage !== null && selectedVenue !== null) {
				setState(prev => {
					switch (idx) {
						case 0:
							return {
								...prev,
								venue: selectedVenue,
								productionTabIndex: idx,
							}
						case 1:
							return {
								...prev,
								venue: selectedVenue,
								stage: selectedStage,
								productionTabIndex: idx,
							}
						case 2:
							return {
								...prev,
								stage: {
									...prev.stage,
									productionData: selectedStage.productionData,
								},
								productionTabIndex: idx,
							}
						case 3:
							return {
								...prev,
								stage: {
									...prev.stage,
									facilitiesData: selectedStage.facilitiesData,
								},
								productionTabIndex: idx,
							}
						case 4:
							return {
								...prev,
								stage: {
									...prev.stage,
									equipmentData: selectedStage.equipmentData,
								},
								productionTabIndex: idx,
							}
						case 5:
							return {
								...prev,
								stage: {
									...prev.stage,
									logisticsData: selectedStage.logisticsData,
								},
								productionTabIndex: idx,
							}
						case 6:
							return {
								...prev,
								stage: {
									...prev.stage,
									localCrewData: selectedStage.localCrewData,
								},
								productionTabIndex: idx,
							}
						default:
							return {
								...prev,
								stage: selectedStage,
								productionTabIndex: idx,
							}
					}
				})
			} else {
				setState(prev => ({
					...prev,
					productionTabIndex: idx,
				}))
			}
		},
		[setState, selectedStage, selectedVenue],
	)

	const saveProductionTab = useCallback(() => {
		if (!selectedStage || !selectedVenue) {
			return
		}

		switch (state.productionTabIndex) {
			case ProductionTab.generalVenueInfo:
				const updateData: Record<string, any> = {
					name: state.venue.name,
					urls: state.venue.urls,
					addressLine1: state.venue.addressLine1,
					addressLine2: state.venue.addressLine2,
					city: state.venue.city,
					state: state.venue.state,
					zip: state.venue.zip,
					country: state.venue.country,
					ageRequirement: state.venue.ageRequirement,
					notes: state.venue.notes,
					previousNames: state.venue.previousNames,
					contacts: state.venue.contacts,
				}

				if (!latLongMatch(selectedVenue.latitude, state.venue.latitude)) {
					updateData["latitude"] = formatLatLong(state.venue.latitude)
				}

				if (!latLongMatch(selectedVenue.longitude, state.venue.longitude)) {
					updateData["longitude"] = formatLatLong(state.venue.longitude)
				}

				patchVenue({
					venueId: selectedVenue.id,
					updateData: updateData,
				})
				break
			case ProductionTab.contacts:
				updateProductionContacts(state.formattedProductionContacts)
				break
			case ProductionTab.production:
				patchStage({
					stageId: selectedStage.id,
					updateData: {
						productionData: state.stage.productionData,
					},
				})
				break
			case ProductionTab.facilities:
				patchStage({
					stageId: selectedStage.id,
					updateData: {
						facilitiesData: state.stage.facilitiesData,
					},
				})
				break
			case ProductionTab.equipment:
				patchStage({
					stageId: selectedStage.id,
					updateData: {
						equipmentData: state.stage.equipmentData,
					},
				})
				break
			case ProductionTab.logistics:
				patchStage({
					stageId: selectedStage.id,
					updateData: {
						logisticsData: state.stage.logisticsData,
					},
				})
				break
			case ProductionTab.localCrew:
				patchStage({
					stageId: selectedStage.id,
					updateData: {
						localCrewData: state.stage.localCrewData,
					},
				})
				break
			default:
				return
		}
	}, [
		updateProductionContacts,
		state.formattedProductionContacts,
		state.productionTabIndex,
		selectedStage,
		patchStage,
		state.stage,
		patchVenue,
		selectedVenue,
		state.venue,
	])

	const tabHasChanges = useMemo(() => {
		if (
			selectedStage === null ||
			selectedVenue === null ||
			patchStagePending ||
			patchVenuePending
		) {
			return false
		}

		switch (state.productionTabIndex) {
			case 0:
				return !compareGeneralVenueInfo(selectedVenue, state.venue)
			case 1:
				return state.formattedProductionContacts.length !== productionContacts.length
					? false
					: !state.formattedProductionContacts.every(
							(val, idx) => val.id === productionContacts[idx]?.userId,
					  )
			case 2:
				return !compareProductionData(selectedStage.productionData, state.stage.productionData)
			case 3:
				return !compare(selectedStage.facilitiesData, state.stage.facilitiesData)
			case 4:
				return !compare(selectedStage.equipmentData, state.stage.equipmentData)
			case 5:
				return !compare(selectedStage.logisticsData, state.stage.logisticsData)
			case 6:
				return !compare(selectedStage.localCrewData, state.stage.localCrewData)
			default:
				return false
		}
	}, [
		state.productionTabIndex,
		state.stage,
		selectedStage,
		selectedVenue,
		patchStagePending,
		patchVenuePending,
		state.venue,
		productionContacts,
		state.formattedProductionContacts,
	])

	useEffect(() => {
		if (selectedStage !== null && selectedVenue !== null) {
			setState(prev => {
				if (prev.stage.id !== selectedStage.id || prev.venue.id !== selectedVenue.id) {
					return {
						...prev,
						stage: {
							...selectedStage,
						},
						venue: selectedVenue,
					}
				} else {
					switch (prev.productionTabIndex) {
						case 0:
							return {
								...prev,
								venue: selectedVenue,
							}
						case 1:
							return {
								...prev,
								venue: selectedVenue,
								stage: selectedStage,
							}
						case 2:
							return {
								...prev,
								stage: {
									...prev.stage,
									productionData: selectedStage.productionData,
								},
							}
						case 3:
							return {
								...prev,
								stage: {
									...prev.stage,
									facilitiesData: selectedStage.facilitiesData,
								},
							}
						case 4:
							return {
								...prev,
								stage: {
									...prev.stage,
									equipmentData: selectedStage.equipmentData,
								},
							}
						case 5:
							return {
								...prev,
								stage: {
									...prev.stage,
									logisticsData: selectedStage.logisticsData,
								},
							}
						case 6:
							return {
								...prev,
								stage: {
									...prev.stage,
									localCrewData: selectedStage.localCrewData,
								},
							}
						default:
							return {
								...prev,
								stage: selectedStage,
							}
					}
				}
			})
		}
	}, [selectedStage, selectedVenue, setState])

	useEffect(() => {
		if (tabHasChanges && !shouldPreventClose) {
			setShouldPreventClose(true)
		}

		if (!tabHasChanges && shouldPreventClose) {
			setShouldPreventClose(false)
		}
	}, [tabHasChanges, shouldPreventClose, setShouldPreventClose])

	useEffect(() => {
		let ignore = false

		const updateLatLong = () => {
			fetchGeocodeAPI(`${state.venue.addressLine1},${state.venue.city},${state.venue.state}`).then(
				data => {
					if (data.results.length === 0 || data.status !== "OK") {
						return
					}

					if (!ignore) {
						const { lat, lng } = data.results[0] && data.results[0]?.geometry.location

						setState(prev => ({
							...prev,
							venue: {
								...prev.venue,
								latitude: Number.parseFloat(formatLatLong(lat)),
								longitude: Number.parseFloat(formatLatLong(lng)),
							},
						}))
					}
				},
			)
		}

		if (selectedVenue !== null) {
			if (
				state.venue.addressLine1 !== selectedVenue?.addressLine1 ||
				state.venue.city !== selectedVenue?.city ||
				state.venue.state !== selectedVenue?.state
			) {
				updateLatLong()
			} else {
				setState(prev => ({
					...prev,
					venue: {
						...prev.venue,
						latitude: selectedVenue.latitude,
						longitude: selectedVenue.longitude,
					},
				}))
			}
		}

		return () => {
			ignore = true
		}
	}, [
		state.venue.addressLine1,
		state.venue.state,
		state.venue.city,
		fetchGeocodeAPI,
		setState,
		selectedVenue,
	])

	useEffect(() => {
		if (productionContacts.length !== state.formattedProductionContacts.length) {
			setState(prev => ({
				...prev,
				formattedProductionContacts: productionContacts.map(val => ({
					id: val.userId,
					name: getDisplayName(val.name),
					title: t(val.title ?? ""),
					phone: val.phone ?? "",
					email: val.email ?? "",
				})),
			}))
		}
	}, [productionContacts, state.formattedProductionContacts.length, t])

	return (
		<ProductionContext.Provider
			value={{
				state,
				setState,

				saveProductionTab,
				tabHasChanges,
				updateProductionTabIndex,

				productionContacts,

				updateVenueContacts,
				isUpdateVenueContactsLoading,
				updateVenuePublicNotes,
				updateVenueUrl,
			}}
		>
			{children}
		</ProductionContext.Provider>
	)
}

export const useProductionContext = () => {
	return useContext(ProductionContext)
}
