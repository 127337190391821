import NewButton from "@/components/atoms/NewButton"
import { ProductionPageTranslationKey } from "@/components/views/Production"
import { mediaQuery } from "@/util/stylingUtils"
import { useProductionContext } from "@/context/production"
import { Check } from "@mui/icons-material"
import { styled } from "@mui/material"
import { useTranslation } from "react-i18next"
import { useMemo } from "react"

export const SectionHeader: React.FC<{ pageTranslationKey: ProductionPageTranslationKey }> = ({
	pageTranslationKey,
}) => {
	const { t } = useTranslation()
	const {
		saveProductionTab,
		tabHasChanges,
		state: {
			productionTabIndex,
			venue: { name, contacts },
		},
	} = useProductionContext()

	const disableSave = useMemo(
		() =>
			!tabHasChanges ||
			(productionTabIndex === 0 &&
				(name.replaceAll(" ", "") === "" ||
					contacts.find(contact => contact?.label.replaceAll(" ", "") === "") !== undefined)),
		[productionTabIndex, name, tabHasChanges, contacts],
	)

	return (
		<SectionContainer>
			<TitleContainer>
				<Title>{t(pageTranslationKey)}</Title>
				<NewButton
					buttonType="Outline"
					text={t("saveChange_plural")}
					iconElement={<Check />}
					onClick={saveProductionTab}
					disabled={disableSave}
					data-cy={"productionheader-savechangesbutton"}
				/>
			</TitleContainer>
			<TitleHr />
		</SectionContainer>
	)
}

const SectionContainer = styled("div")`
	position: static;
	gap: 12px;
	display: flex;
	flex-direction: column;
	width: 100%;
	padding: 0px 48px;
	padding-top: 40px;
	background-color: ${({ theme }) => theme.colorPalette.surface.bright};

	${mediaQuery("md")`
		z-index: 5;
		position: sticky;
		top: 0;
		padding: 24px 16px 0 16px;
	`}
`

const TitleContainer = styled("div")`
	display: flex;
	justify-content: space-between;
	align-items: center;
`

const Title = styled("h3")`
	font-family: Roboto-Regular;
	font-weight: 400;
	font-size: 32px;
	line-height: 40px;
	color: ${({ theme }) => theme.colorPalette.surface.on};
`

const TitleHr = styled("hr")`
	color: ${({ theme }) => theme.colorPalette.outline.inverseVariant};
	width: 100%;
`
