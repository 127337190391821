import Bugsnag from "@bugsnag/js"
import { getAnalytics, setUserId } from "firebase/analytics"
import { createContext, useContext, useEffect, useState } from "react"
import { useTranslation } from "react-i18next"
import { useMutation } from "@tanstack/react-query"
import { toast } from "react-toastify"
import useAxios from "../hooks/useAxios"
import { getDisplayName, normalizeNameObject } from "@/util/userUtils"

/* Honestly not super sure how necessary this context is, might be better to merge with profile when we have more time */

const DEFAULT_USER: UserType = {
	firstName: "",
	lastName: "",
	preferredName: "",
	metadata: {
		email: "",
		phone: "",
	},
	userId: "",
	displayName: "",
}

export type UserType = {
	firstName: string
	lastName: string
	preferredName: string
	metadata: {
		email?: string
		phone?: string
	}
	displayName: string
	userId: string
}

export type UserContextType = {
	user: UserType
	getUserLoading: boolean
	myVenues: any[]
	getUser: () => void
}

export const UserContext = createContext<UserContextType>({
	user: DEFAULT_USER,
	getUserLoading: true,
	myVenues: [],
	getUser: () => null,
})

export default function UserContextProvider({
	children,
}: React.PropsWithChildren<unknown>): JSX.Element {
	const [user, setUser] = useState(DEFAULT_USER)
	const [myVenues, setMyVenues] = useState([])
	const axios = useAxios()
	const { t } = useTranslation()
	const analytics = getAnalytics()

	const editUser = (edits: object) => setUser(u => ({ ...u, ...edits }))
	const editUserMetadata = (edits: object) =>
		setUser(u => ({ ...u, metadata: { ...u.metadata, ...edits } }))

	const { mutate: mutateGetProfile, isPending: getUserLoading } = useMutation({
		mutationFn: async () => {
			return await axios.post("/venues/v1/profile/showByAttachedCriteria", {
				attachedToType: "_service",
				attachedToId: "masterTourVenue",
				includeAvatar: true,
			})
		},
		onError: () => {
			toast.error(t("errorTryAgain"))
		},
		onSuccess: res => {
			if (res && res.data.success) {
				const {
					data: {
						metadata: { email, phone },
						name,
						userId,
						id,
					},
				} = res.data

				setUserId(analytics, id)

				//provide default "" if user.name object has undefined fields
				editUser({
					firstName: name.firstName ?? "",
					lastName: name.lastName ?? "",
					preferredName: normalizeNameObject(name).preferredName,
					displayName: name.displayName ?? getDisplayName(name),
					userId,
				})

				editUserMetadata({ email, phone })
				Bugsnag.setUser(userId, email, `${name.firstName} ${name.lastName}`)
			}
		},
	})

	const { mutate: getMyVenues } = useMutation({
		mutationFn: async () => {
			return await axios.get("/venues/v1/venues")
		},
		onError: () => {
			toast.error(t("errorTryAgain"))
		},
		onSuccess: res => {
			if (res && res.data.success) {
				setMyVenues(res.data.data)
			}
		},
	})

	useEffect(() => {
		if (localStorage.token) {
			mutateGetProfile()
			getMyVenues()
		}
	}, [getMyVenues, mutateGetProfile])

	return (
		<UserContext.Provider
			value={{
				user,
				getUser: mutateGetProfile,
				myVenues,
				getUserLoading,
			}}
		>
			{children}
		</UserContext.Provider>
	)
}

export const useUserContext = () => {
	return useContext(UserContext)
}
