import { mediaQuery } from "@/util/stylingUtils"
import { styled } from "@mui/material"

export const PageRootContainer = styled("div", {
	shouldForwardProp: prop => prop !== "mobileSticky",
})<{ mobileSticky?: boolean }>`
	height: 100%;
	overflow-y: hidden;
	overflow-x: hidden;
	display: flex;
	flex-direction: column;

	${({ mobileSticky }) =>
		mobileSticky
			? ""
			: mediaQuery("md")`
		display: block;
		overflow-y: scroll;
		overflow-x: hidden;
	`}
`
