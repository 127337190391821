import { PlaceholderContent } from "@/components/atoms/PlaceholderContent"
import { useProfileContext } from "@/context/profile"
import { mediaQuery } from "@/util/stylingUtils"
import { styled, Table, TableBody, TableCell, TableHead, TableRow } from "@mui/material"
import Grid from "@mui/material/Unstable_Grid2"
import { useTranslation } from "react-i18next"

export const VenueLevelPermissions: React.FC<{}> = () => {
	const { t } = useTranslation()
	const { userProfile } = useProfileContext()
	return (
		<RootContainer container data-cy-component={"venuelevelpermissions-container"} xs={12}>
			<HeaderContainer container xs={12} lg={"auto"} gap={2}>
				<Grid xs={12}>
					<h3>{t("venueLevelPermission_plural")}</h3>
				</Grid>
				<Grid xs={12}>
					<p>{t("venueLevelPermissionBlurb")}</p>
				</Grid>
			</HeaderContainer>
			<TableGrid xs={12} lg>
				{(userProfile?.rolesAndLocations?.Venue ?? []).length > 0 ? (
					<StyledTable>
						<TableHead>
							<TableRow>
								<TableCell>{t("venueName")}</TableCell>
								<TableCell>{t("venueLocation")}</TableCell>
								<TableCell>{t("permissionLevel")}</TableCell>
							</TableRow>
						</TableHead>
						<TableBody>
							{(userProfile?.rolesAndLocations.Venue ?? []).map(val => (
								<TableRow key={val.name ?? ""}>
									<TableCell>{val.name ?? ""}</TableCell>
									<TableCell>
										{val.state === null && val.city === null
											? "N/A"
											: [val.city ?? "", val.state ?? ""].join(", ")}
									</TableCell>
									<TableCell>{val.role ?? ""}</TableCell>
								</TableRow>
							))}
						</TableBody>
					</StyledTable>
				) : (
					<PlaceholderContent text={t("noVenuePermissionFound_plural")} />
				)}
			</TableGrid>
		</RootContainer>
	)
}

const TableGrid = styled(Grid)`
	overflow-y: scroll;
	scrollbar-width: thin;
	box-sizing: border-box;
`

const StyledTable = styled(Table)`
	box-sizing: border-box;
	& .MuiTableCell-root {
		font-size: 14px;
		line-height: 20px;
		padding: 10px 12px;
		color: ${({ theme }) => theme.colorPalette.surface.on};
		width: 33.33%;
		border-bottom: 1px solid ${({ theme }) => theme.colorPalette.surface.container.value};
	}

	& .MuiTableHead-root .MuiTableCell-root {
		background-color: ${({ theme }) => theme.colorPalette.surface.container.value};
		font-family: Signika-Medium;
		font-weight: 500;
		white-space: nowrap;
	}

	& .MuiTableBody-root .MuiTableCell-root {
		font-weight: 400;
		font-family: Signika-Regular;
		white-space: nowrap;
		overflow: hidden;
		text-overflow: ellipsis;
	}
`

const HeaderContainer = styled(Grid)`
	& {
		align-content: start;
		width: 192px;

		${mediaQuery("lg")`
			width: 100%;
		`}
	}

	& h3 {
		font-family: Roboto-Regular;
		font-size: 24px;
		line-height: 32px;
		font-weight: 400;
		color: ${({ theme }) => theme.colorPalette.surface.on};
	}

	& p {
		font-family: Signika-Medium;
		font-weight: 500;
		font-size: 11px;
		line-height: 16px;
		color: ${({ theme }) => theme.colorPalette.secondary.value};

		${mediaQuery("lg")`
			width: 50%;
		`}
	}
`

const RootContainer = styled(Grid)`
	& {
		gap: 48px;
		color: ${({ theme }) => theme.colorPalette.surface.on};

		${mediaQuery("lg")`
			gap: 24px;
		`}
	}
`
