import styled, { css, useTheme } from "styled-components"
import { useSwitch, UseSwitchParameters } from "@mui/base/useSwitch"
import { Check } from "@mui/icons-material"

function Toggle(props: UseSwitchParameters) {
	const { getInputProps, checked } = useSwitch(props)
	const theme = useTheme()

	return (
		<Root checked={checked} className="toggleRoot" >
			<Track className="toggleTrack" checked={checked} >
				<Thumb className="toggleThumb" checked={checked}>
					<IconContainer checked={checked}>
						<Check
							sx={{
								width: "16px",
								height: "16px",
							}}
							htmlColor={theme.colorPalette.primary.container.on}
						/>
					</IconContainer>
				</Thumb>
			</Track>
			<Input {...getInputProps()} {...props}/>
		</Root>
	)
}

const IconContainer = styled.span<{ checked: boolean }>`
	opacity: ${({ checked }) => (checked ? "1" : "0")};
	display: flex;
	padding: 0;
	margin: 0;
	justify-content: center;
	align-items: center;
	-moz-transition: opacity 150ms ease-out;
	-o-transition: opacity 150ms ease-out;
	-webkit-transition: opacity 150ms ease-out;
	transition: opacity 150ms ease-out;
`

const Root = styled.span<{ checked: boolean }>`
	display: inline-block;
	position: relative;
	width: 52px;
	height: 32px;
	gap: 0px;
	opacity: 0px;
	padding: 0px;

	&:hover {
		.toggleTrack .toggleThumb {
			box-shadow: 0px 0px 0px
				${({ checked, theme }) =>
					checked
						? " 8px " + theme.colorPalette.primary.value
						: " 12px " + theme.colorPalette.surface.on}14;
			background-color: ${({ checked, theme }) =>
				checked
					? theme.colorPalette.primary.container.value
					: theme.colorPalette.surface.onVariant};
		}
	}

	&:active {
		.toggleTrack .toggleThumb {
			box-shadow: 0px 0px 0px 6px
				${({ checked, theme }) =>
					checked ? theme.colorPalette.primary.value : theme.colorPalette.surface.on}14;
			width: 28px;
			height: 28px;
			top: 2px;
			left: ${({ checked }) => (checked ? "22px" : "2px")};
		}
	}
`

const Input = styled.input`
	position: absolute;
	width: 100%;
	height: 100%;
	top: 0;
	left: 0;
	opacity: 0;
	z-index: 1;
	margin: 0;
	cursor: pointer;
`

//checked ? theme.colorPalette.primary.value : theme.colorPalette.surface.on
//1F
const Thumb = styled.span<{ checked: boolean }>`
	position: absolute;
	border-radius: 100px;
	display: flex;
	justify-content: center;
	align-items: center;

	-moz-transition: all 200ms ease-out;
	-o-transition: all 200ms ease-out;
	-webkit-transition: all 200ms ease-out;
	transition: all 200ms ease-out;

	${({ theme, checked }) => {
		if (checked) {
			return css`
				top: 4px;
				left: 24px;
				width: 24px;
				height: 24px;

				background-color: ${theme.colorPalette.surface.container.lowest};
			`
		} else {
			return css`
				top: 8px;
				left: 8px;
				width: 16px;
				height: 16px;
				background-color: ${theme.colorPalette.outline.value};
			`
		}
	}}
`

const Track = styled.span<{ checked: boolean }>`
	display: block;
	width: 100%;
	height: 100%;
	border-radius: 100px;
	gap: 0;
	width: 52px;
	border: 2px solid
		${({ checked, theme }) =>
			checked ? theme.colorPalette.primary.value : theme.colorPalette.outline.value};

	background-color: ${({ checked, theme }) =>
		checked ? theme.colorPalette.primary.value : theme.colorPalette.surface.container.highest};
`

export default Toggle
