import Modal from "@mui/material/Modal"
import { styled, useTheme } from "@mui/material"
import React, { FC, useCallback, useMemo } from "react"
import { mediaQuery } from "@/util/stylingUtils"
import useTechPack from "@hooks/useTechPack"
import {
	ChevronLeft,
	ChevronRight,
	CloseOutlined,
	FileDownloadDoneOutlined,
} from "@mui/icons-material"
import { useTranslation } from "react-i18next"
import { getSection } from "@/util/fieldUtils"
import { attachmentDisplayName, getPreviewUrl } from "@/util/attachmentUtils"
import { FileImg } from "@/components/atoms/FileImg"
import ShowPreview from "./ShowPreview"
import { DEFAULT_FILE_ATTACHMENT } from "@/util/constants/defaults/DEFAULT_STORAGE"
import formatBytes from "@/util/formatBytes"
import useDownloadAttachment from "@/hooks/useDownloadAttachment"

const ImageModal: FC = () => {
	const { t } = useTranslation()
	const theme = useTheme()
	const { downloadAttachment, isDownloadLoading } = useDownloadAttachment()

	const {
		state: {
			selectedAttachment: { category, field, selectedIndex },
		},
		setState,
		attachments,
		closeSelectedAttachmentModal,
	} = useTechPack()

	const open = !!category && !!FileDownloadDoneOutlined
	const theseAttachments: FileAttachment[] = open ? attachments[category][field] : []
	const thisAttachment = open ? theseAttachments[selectedIndex] : DEFAULT_FILE_ATTACHMENT

	const handleDownloadClick = () => {
		if (thisAttachment?.id === "") return

		if (thisAttachment?.file?.filename?.includes(".pdf")) {
			window.open(thisAttachment?.file?.publicUrls?.url ?? "", "_blank")
		} else if (!isDownloadLoading) {
			downloadAttachment({
				url: thisAttachment.file?.publicUrls?.urlDownload ?? "",
				filename: thisAttachment?.file?.filename ?? "",
			})
		}
	}

	const handleArrowClick = useCallback(
		(action: "next" | "prev") => {
			setState(prev => {
				const prevIndex = prev.selectedAttachment.selectedIndex

				const newIndex =
					action === "next"
						? prevIndex === theseAttachments.length - 1
							? 0
							: prevIndex + 1
						: prevIndex === 0
						? theseAttachments.length - 1
						: prevIndex - 1

				return {
					...prev,
					selectedAttachment: {
						...prev.selectedAttachment,
						selectedIndex: newIndex,
					},
				}
			})
		},
		[setState, theseAttachments.length],
	)

	const subCategory = getSection(field)

	return (
		<StyledModal
			open={open}
			onClose={closeSelectedAttachmentModal}
			onSubmit={closeSelectedAttachmentModal}
		>
			<ModalBox>
				<ModalHeader>
					<h2>{`${t(category.replace("Data", ""))} ${subCategory && `> ${t(subCategory)}`} > ${t(
						field,
					)}`}</h2>
					<CloseOutlined
						htmlColor={theme.colorPalette.surface.on}
						sx={{
							width: "40px",
							height: "40px",
						}}
						onClick={closeSelectedAttachmentModal}
					/>
				</ModalHeader>
				<CarouselPreview>
					<ChevronLeft onClick={() => handleArrowClick("prev")} />
					<ShowPreview attachment={thisAttachment} />
					<ChevronRight onClick={() => handleArrowClick("next")} />
				</CarouselPreview>
				<DescriptionDownloadContainer>
					<CaptionText>{thisAttachment?.file?.metadata?.caption ?? ""}</CaptionText>
					<DownloadContainer onClick={handleDownloadClick}>
						<svg
							xmlns="http://www.w3.org/2000/svg"
							width="24"
							height="24"
							viewBox="0 0 24 24"
							fill="none"
						>
							<path
								d="M14 24V22H22V24H14ZM18 21L14 17L15.4 15.6L17 17.2V13.025H19V17.2L20.6 15.6L22 17L18 21ZM6 20C5.45 20 4.97917 19.8042 4.5875 19.4125C4.19583 19.0208 4 18.55 4 18V4C4 3.45 4.19583 2.97917 4.5875 2.5875C4.97917 2.19583 5.45 2 6 2H13L19 8V11.025H12V20H6ZM12 9H17L12 4V9Z"
								fill="currentColor"
							/>
						</svg>

						<p>
							{t(thisAttachment?.file?.filename?.includes(".pdf") ? "view" : "download")}{" "}
							<b>{thisAttachment?.file?.filename ?? ""}</b> (
							{formatBytes(thisAttachment?.file?.filesize ?? 0)})
						</p>
					</DownloadContainer>
				</DescriptionDownloadContainer>
				<ThumbnailCarousel>
					<ChevronLeft onClick={() => handleArrowClick("prev")} />
					<ThumbnailList>
						{theseAttachments.map((val, idx) => (
							<Thumbnail key={val.id} fileAttachment={val} idx={idx} />
						))}
					</ThumbnailList>
					<ChevronRight onClick={() => handleArrowClick("next")} />
				</ThumbnailCarousel>
			</ModalBox>
		</StyledModal>
	)
}

const Thumbnail: React.FC<{
	fileAttachment: FileAttachment
	idx: number
}> = ({ fileAttachment, idx }) => {
	const { colorPalette } = useTheme()

	const file = fileAttachment.file
	const previewUrl = getPreviewUrl(file)
	const fileName = attachmentDisplayName(file)
	const {
		state: {
			selectedAttachment: { selectedIndex },
		},
		setState,
	} = useTechPack()

	const handleSelectAttachment = useCallback(() => {
		setState(prev => ({
			...prev,
			selectedAttachment: {
				...prev.selectedAttachment,
				selectedIndex: idx,
			},
		}))
	}, [setState, idx])

	const ImgBorderStyle: React.CSSProperties = useMemo(() => {
		if (idx === selectedIndex) {
			return {
				border: `2px solid ${colorPalette.primary.value}`,
				boxShadow: "0px 1px 2px 0px rgba(0, 0, 0, 0.30), 0px 2px 6px 2px rgba(0, 0, 0, 0.15)",
				cursor: "default",
			}
		} else {
			return {
				border: `2px solid ${colorPalette.secondary.value}`,
				cursor: "pointer",
			}
		}
	}, [idx, selectedIndex, colorPalette])

	return (
		<StyledThumbnail>
			<FileImg
				filename={fileName}
				previewUrl={previewUrl}
				height="75px"
				width="75px"
				style={{
					borderRadius: "4px",
					objectFit: "cover",
					color: colorPalette.primary.value,
					backgroundColor: colorPalette.primary.container.value,
					...ImgBorderStyle,
				}}
				onClick={handleSelectAttachment}
			/>
			<p>{file.filename}</p>
		</StyledThumbnail>
	)
}

const StyledModal = styled(Modal)`
	& {
		align-items: center;
		display: flex;
		justify-content: center;
		z-index: 9999;

		${mediaQuery("sm")`
			font-size: 12px;
		`}
	}
`

const ModalBox = styled("div")`
	display: flex;
	flex-direction: column;
	align-items: center;
	background-color: ${({ theme }) => theme.colorPalette.surface.bright};
	border: 2px solid ${({ theme }) => theme.colorPalette.secondary.value};
	border-radius: 10px;
	padding: 28px 12px;
	position: relative;
	outline: none;
	min-width: 750px;
	max-height: 95vh;
	/* height: 95vh; */
	max-width: 858px;

	overflow-y: auto;
	scrollbar-width: thin;

	${mediaQuery("md")`
		min-width: 95vw;
		width: 95vw;
		max-height: 95vh;
		padding: 20px 5px;
	`}
`
const ModalHeader = styled("div")`
	display: flex;
	justify-content: space-between;
	align-items: center;
	width: 100%;
	padding: 0px 12px 0px 90px;
	color: ${({ theme }) => theme.colorPalette.surface.on};

	& > h2 {
		font-family: "FiraSansCondensed-Bold";
		font-size: 24px;
		font-weight: 700;
		line-height: normal;
		color: inherit;
	}

	& > svg.MuiSvgIcon-root.MuiSvgIcon-fontSizeMedium {
		cursor: pointer;
		justify-self: end;
		width: 40px;
		height: 40px;
		color: inherit;
	}

	${mediaQuery("md")`
		padding: 0px 10px;
		& > h2 {
			font-family: "FiraSansCondensed-Bold";
			font-size: 16px;
			font-weight: 700;
			line-height: normal;
		}
		& > svg.MuiSvgIcon-root.MuiSvgIcon-fontSizeMedium {
			width: 24px;
			height: 24px;
		}
	`}
`

const CarouselPreview = styled("div")`
	padding: 20px 0px;
	width: 100%;
	height: 45vh;
	display: flex;
	justify-content: center;
	gap: 12px;

	& > svg {
		text-align: center;
		align-self: center;
		cursor: pointer;
		width: 80px;
		height: 80px;
		color: ${({ theme }) => theme.colorPalette.primary.value};
	}

	${mediaQuery("md")`
		height: 45vh;
		gap: 5px;
		padding: 12px 0px;

		& > svg {
			width: 40px;
			height: 40px;
		}
	`}

	${mediaQuery("sm")`
		height: 40vh;
	`}
`

const DescriptionDownloadContainer = styled("div")`
	display: flex;
	flex-direction: column;
	align-items: start;
	gap: 32px;
	padding: 0px 90px;
	width: 100%;

	font-family: Signika-Regular;
	color: ${({ theme }) => theme.colorPalette.surface.on};

	${mediaQuery("md")`
		font-size: 14px;
		line-height: normal;
		padding: 0px 43px;
		gap: 12px;
	`}
`

const CaptionText = styled("p")`
	color: inherit;
	font-family: inherit;
	font-weight: 400;
	font-size: 16px;
	line-height: 20px;

	${mediaQuery("md")`
		font-size: 14px;
		line-height: normal;
	`}
`

const DownloadContainer = styled("div")`
	display: flex;
	justify-content: start;
	align-items: center;
	gap: 10px;
	cursor: pointer;
	user-select: none;
	opacity: 1;

	&:hover {
		opacity: 0.75;
	}

	&:active {
		opacity: 0.9;
	}

	svg {
		color: ${({ theme }) => theme.colorPalette.primary.value};
	}

	p {
		font-family: Signika-Regular;
		color: ${({ theme }) => theme.colorPalette.surface.on};
		font-size: 14px;
		line-height: normal;
		b {
			color: inherit;
			font-family: Signika-Bold;
			font-weight: 700;
		}
	}

	${mediaQuery("md")`
		p {
			font-size: 12px;
			b {
				font-size: 12px;
			}
		}
	`}
`

const ThumbnailCarousel = styled("div")`
	padding: 32px 12px 0px 12px;
	display: flex;
	gap: 25px;
	justify-content: center;
	align-items: center;
	width: 100%;

	& > svg {
		color: ${({ theme }) => theme.colorPalette.primary.value};
		width: 40px;
		height: 40px;

		${mediaQuery("sm")`
			width: 28px;
			height: 28px;
		`}
	}
`

const ThumbnailList = styled("div")`
	display: flex;
	justify-content: start;
	align-items: center;
	max-width: 80%;
	gap: 20px;
	height: 100px;
	overflow-x: auto;
	overflow-y: hidden;
	scrollbar-width: thin;
`

const StyledThumbnail = styled("div")`
	height: 98px;
	width: 75px;

	& > p {
		width: 100%;
		color: ${({ theme }) => theme.colorPalette.surface.on};
		font-family: Signika-Regular;
		font-weight: 400;
		font-size: 12px;
		text-align: center;
		line-height: normal;
		text-overflow: ellipsis;
		overflow: hidden;
		white-space: nowrap;
		margin-top: 8px;
	}

	${mediaQuery("md")`
		& > div {
				width: 60px;
				height: 60px;
			div {
				width: 60px;
				height: 60px
			}
		}

		& > p{
		margin-top: 9px;
			font-size: 11px;
		}
	`}
`

export default ImageModal
