import { styled } from "@mui/material"
import Grid from "@mui/material/Unstable_Grid2"
import SingleLineLight from "../../logos/MTV_Long_Logo_Light.svg"
import SingleLineDark from "../../logos/MTV_Long_Logo_Dark.svg"
import useDarkMode from "@/hooks/useDarkMode"
import { Divider } from "../atoms/Divider"
import { useTranslation } from "react-i18next"

export const Footer = () => {
	const { darkMode } = useDarkMode()
	const { t } = useTranslation()
	return (
		<FooterRoot>
			<StyledDivider />
			<Grid container gap={3} justifyContent={"center"}>
				<Grid container xs="auto" rowGap={1} columnGap={2} flexDirection={"column"}>
					<Grid xs="auto">
						<CopyrightText>Copyright © 2024 Eventric, LLC. All Rights Reserved.</CopyrightText>
					</Grid>
					<Grid container xs="auto" gap={5}>
						<Grid xs="auto">
							<FooterLink href="https://www.eventric.com/privacy-policy">
								{t("privacy&Terms")}
							</FooterLink>
						</Grid>
						<Grid xs="auto">
							<FooterLink href="mailto:venues@eventric.com">{t("support")}</FooterLink>
						</Grid>
					</Grid>
				</Grid>
				<Grid xs="auto">
					<StyledImg src={darkMode ? SingleLineDark : SingleLineLight} alt="Eventric Logo" />
				</Grid>
			</Grid>
		</FooterRoot>
	)
}

const FooterRoot = styled("div")`
	width: 100%;
	height: auto;
	padding: 24px 0px;
	margin-top: auto;

	display: flex;
	flex-direction: column;
	justify-content: start;
	align-items: center;
`

const StyledDivider = styled(Divider)`
	width: 100%;
	margin-bottom: 24px;
`

const CopyrightText = styled("p")`
	font: 400 14px/20px Signika-Regular;
	white-space: nowrap;
	color: ${({ theme }) => theme.colorPalette.mono};
`

const FooterLink = styled("a")`
	text-decoration: underline;
	color: ${({ theme }) => theme.colorPalette.mono};
	font: 400 14px/20px Signika-Regular;

	&,
	&:link,
	&:active,
	&:visited {
		color: ${({ theme }) => theme.colorPalette.mono};
	}
`

const StyledImg = styled("img")`
	width: 245px;
`
