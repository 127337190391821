import { getCustomBorder, mediaQuery } from "@/util/stylingUtils"
import {
	Box,
	ImageList as MuiImageList,
	ImageListItem as MuiImageListItem,
	styled,
} from "@mui/material"
import {
	ChangeEventHandler,
	MouseEvent,
	MouseEventHandler,
	useCallback,
	useRef,
	useState,
} from "react"
import { FileImg } from "../atoms/FileImg"
import {
	closestCenter,
	DndContext,
	DragEndEvent,
	PointerSensor,
	useSensor,
	useSensors,
} from "@dnd-kit/core"
import { restrictToParentElement } from "@dnd-kit/modifiers"
import { arrayMove, rectSortingStrategy, SortableContext, useSortable } from "@dnd-kit/sortable"
import { CSS } from "@dnd-kit/utilities"
import useProductionAttachments from "@/hooks/useProductionAttachments"
import { Trans } from "react-i18next"
import useWindowDimensions from "@/hooks/useWindowDimensions"
import { BREAKPOINTS } from "@/util/constants/BREAKPOINTS"

interface AttachmentListProps {
	listType?: "add" | "edit"
}

const AttachmentListItem = ({
	itemData,
	handleOpenAttachment,
	deactivateSelect,
	parentIsDragging,
	selectedAttachmentId,
}: {
	itemData: {
		id: string
		previewUrl?: string
		filename: string
	}
	handleOpenAttachment: (attachmentId: string) => void
	deactivateSelect: boolean
	parentIsDragging: boolean
	selectedAttachmentId: string
}) => {
	const { setNodeRef, attributes, listeners, transition, transform, isDragging } = useSortable({
		id: itemData.id,
	})

	const handleClick: MouseEventHandler<HTMLLIElement> = e => {
		e.stopPropagation()
		if (parentIsDragging) {
			return
		}

		if (isDragging) {
			return
		}

		handleOpenAttachment(itemData.id)
	}
	return (
		<ImageListItem
			ref={setNodeRef}
			{...attributes}
			{...listeners}
			onClick={handleClick}
			deactivateSelect={deactivateSelect}
			attachmentSelected={selectedAttachmentId === itemData.id}
			style={{
				transition,
				transform: CSS.Transform.toString(transform),
			}}
		>
			<FileImg
				height="100%"
				width="100%"
				filename={itemData.filename}
				previewUrl={itemData.previewUrl}
			/>
		</ImageListItem>
	)
}

export const AttachmentList = ({ listType = "add" }: AttachmentListProps) => {
	const {
		state: {
			addEditModalState: { selectedAttachmentId, previewData, selectedField },
		},
		setState,
		handleFileInput,
	} = useProductionAttachments()

	const { width: windowWidth } = useWindowDimensions()
	const fileInputRef = useRef<HTMLInputElement>(null)
	const [isDragging, setIsDragging] = useState(false)

	const handleOpenAttachment = useCallback(
		(newAttachmentId: string) => {
			if (selectedField === "") {
				setState(prevState => ({
					...prevState,
					addEditModalState: {
						...prevState.addEditModalState,
						fieldError: true,
					},
				}))
			} else {
				setState(prevState => ({
					...prevState,
					addEditModalState: {
						...prevState.addEditModalState,
						selectedAttachmentId: newAttachmentId,
					},
				}))
			}
		},
		[setState, selectedField],
	)

	const setPreviewData = useCallback(
		(
			newPreviewData: {
				id: string
				previewUrl?: string
				filename: string
			}[],
		) => {
			setState(prevState => ({
				...prevState,
				addEditModalState: {
					...prevState.addEditModalState,
					previewData: newPreviewData,
				},
			}))
		},
		[setState],
	)

	const sensors = useSensors(
		useSensor(PointerSensor, {
			activationConstraint: {
				distance: 10,
			},
		}),
	)

	const handleAddFileClick = (e: MouseEvent) => {
		e.stopPropagation()
		fileInputRef?.current?.click()
	}

	const onAddFile: ChangeEventHandler<HTMLInputElement> = e => {
		e.stopPropagation()
		handleFileInput(e.target.files)
	}

	const handleDragStart = () => {
		setIsDragging(true)
	}

	const handleDragEnd = ({ active, over }: DragEndEvent) => {
		setIsDragging(false)

		if (active && over && active.id !== over?.id) {
			const newActive = previewData.findIndex(item => item.id === active.id)
			const overIndex = previewData.findIndex(item => item.id === over.id)
			setPreviewData(arrayMove(previewData, newActive, overIndex))
		}
	}

	const test = windowWidth > BREAKPOINTS.md ? 4 : (windowWidth * 0.3725 - 32) / 120

	console.log(test)

	return (
		<AttatchmentContainer
			flexGrow={2}
			onClick={listType === "add" ? handleAddFileClick : undefined}
			listType={listType}
			height={selectedAttachmentId ? "100%" : previewData.length <= 4 ? "192px" : "224px"}
		>
			{previewData.length === 0 ? (
				<div>
					<p>
						<Trans i18nKey="addFileDragAndDrop">
							<span onClick={handleAddFileClick}>Add file</span> or drag & drop file here
						</Trans>
					</p>
				</div>
			) : (
				<ImageList
					sx={{
						overflowX: "hidden",
					}}
					rowHeight={104}
					gap={8}
					detailsOpen={selectedAttachmentId !== undefined}
				>
					<DndContext
						sensors={sensors}
						collisionDetection={closestCenter}
						onDragStart={handleDragStart}
						onDragEnd={handleDragEnd}
						modifiers={[restrictToParentElement]}
					>
						<SortableContext
							items={previewData.map(item => item.id)}
							strategy={rectSortingStrategy}
						>
							{previewData.map(val => (
								<AttachmentListItem
									key={val.id}
									itemData={val}
									handleOpenAttachment={handleOpenAttachment}
									deactivateSelect={selectedField === ""}
									selectedAttachmentId={selectedAttachmentId || ""}
									parentIsDragging={isDragging}
								/>
							))}
						</SortableContext>
					</DndContext>
				</ImageList>
			)}
			<input
				ref={fileInputRef}
				type="file"
				style={{ display: "none" }}
				onChange={onAddFile}
				multiple
			/>
		</AttatchmentContainer>
	)
}

const AttatchmentContainer = styled(Box, {
	shouldForwardProp: prop => prop !== "listType" && prop !== "height",
})<{ listType: "add" | "edit"; height?: number | string }>`
	background-image: ${({ theme }) =>
		getCustomBorder({
			strokeWidth: 1,
			color: theme.colorPalette.surface.onVariant,
			dashArray: [9, 12],
			dashOffset: 5,
		})};
	padding: 16px;
	user-select: none;
	min-height: 148px;
	width: 100%;
	overflow-y: auto;
	scrollbar-width: thin;

	cursor: ${({ listType }) => (listType === "add" ? "pointer" : "default")};
	height: 100%;

	${mediaQuery("md")`
		height: 183px;
		min-height: 183px;
	`}

	& > div {
		width: 100%;
		min-height: ${({ height }) => height};
		height: ${({ height }) => height};
		display: flex;
		align-items: center;
		justify-content: center;

		${mediaQuery("md")`
			height: 151px;
			min-height: 151px;
		`}

		& > p {
			width: 100%;
			font: 400 14px/20px Signika-Regular;
			color: ${({ theme }) => theme.colorPalette.surface.onVariant};
			text-align: center;
			letter-spacing: 0.25px;
			& > span {
				color: ${({ theme }) => theme.colorPalette.primary.value};
				letter-spacing: inherit;
				font: inherit;
				cursor: pointer;
			}
		}
	}
`

const ImageList = styled(MuiImageList, {
	shouldForwardProp: prop => prop !== "detailsOpen",
})<{ detailsOpen: boolean }>`
	width: 100%;

	&.MuiImageList-root {
		grid-template-columns: repeat(4, 1fr) !important;
	}

	@media (max-width: ${BREAKPOINTS.lg}px) {
		&.MuiImageList-root {
			grid-template-columns: ${({ detailsOpen }) =>
				detailsOpen ? "repeat(3, 1fr)" : "repeat(4, 1fr)"} !important;
		}
	}

	@media (max-width: 869px) {
		&.MuiImageList-root {
			grid-template-columns: ${({ detailsOpen }) =>
				detailsOpen ? "repeat(2, 1fr)" : "repeat(4, 1fr)"} !important;
		}
	}

	@media (max-width: ${BREAKPOINTS.md}px) {
		&.MuiImageList-root {
			grid-template-columns: repeat(4, 1fr) !important;
		}
	}

	@media (max-width: ${BREAKPOINTS.sm}px) {
		&.MuiImageList-root {
			grid-template-columns: repeat(3, 1fr) !important;
		}
	}

	@media (max-width: ${BREAKPOINTS.xs}px) {
		&.MuiImageList-root {
			grid-template-columns: repeat(2, 1fr) !important;
		}
	}
`

const ImageListItem = styled(MuiImageListItem, {
	shouldForwardProp: prop => prop !== "deactivateSelect" && prop !== "attachmentSelected",
})<{ deactivateSelect: boolean; attachmentSelected: boolean }>`
	display: inline-block;
	cursor: ${({ deactivateSelect }) => (deactivateSelect ? "default" : "pointer")};

	& {
		height: 104px;
		width: 104px;
	}

	& > div > div > img {
		${({ theme, attachmentSelected }) => {
			if (attachmentSelected) {
				return `
					border: 2px solid ${theme.colorPalette.primary.value} !important;
				`
			}
		}}
	}
`
