import { mediaQuery } from "@/util/stylingUtils"
import { Stack, styled } from "@mui/material"

export const ScrollableStack = styled(Stack, {
	shouldForwardProp: prop => prop !== "mobileSticky",
})<{ mobileSticky?: boolean }>`
	overflow-y: scroll;
	overflow-x: hidden;
	padding: 0 48px;
	padding-bottom: 24px;
	height: auto;

	gap: 24px;
	flex-grow: 2;

	${({ mobileSticky }) =>
		mobileSticky
			? ""
			: mediaQuery("md")`
        overflow-y: hidden;
    `}

	${mediaQuery("md")`
        padding: 24px 16px;
    `}
`
