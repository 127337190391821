import styled, { useTheme } from "styled-components"
import Toggle from "../../../components/atoms/Toggle"
import { DarkMode } from "../../../icons"
import { useTranslation } from "react-i18next"
import useDarkMode from "../../../hooks/useDarkMode"
import { mediaQuery } from "@/util/stylingUtils"

function DarkModeToggle({ ...rest }) {
	const { t } = useTranslation()
	const { darkMode, setDarkMode } = useDarkMode()
	const theme = useTheme()

	return (
		<DarkModeContainer>
			<DarkMode fill={darkMode} color={theme.colorPalette.surface.on} />
			<DarkModeText>{t("darkMode")}</DarkModeText>
			<Toggle checked={darkMode} onChange={() => setDarkMode(val => !val)} {...rest} />
		</DarkModeContainer>
	)
}

const DarkModeText = styled.span`
	margin: auto auto auto 0;

	font: 600 14px Signika-Semibold;
	letter-spacing: 0.10000000149011612px;
	text-align: start;

	color: ${({ theme }) => theme.colorPalette.surface.on};
`

const DarkModeContainer = styled.div`
	display: flex;
	align-items: center;
	width: 276px;
	height: 64px;

	padding: 16px 24px;
	margin: auto 0 22px auto;
	gap: 12px;

	${mediaQuery("md")`
		margin: 0;
	`}
`

export default DarkModeToggle
