import { ProductionContextProvider, useProductionContext } from "../../context/production"
import ProductionAttatchmentsContextProvider from "@/context/productionAttachments"
import { useTranslation } from "react-i18next"
import { Fab, styled } from "@mui/material"
import { PageHeader } from "../organisms/PageHeader"
import { SectionHeader } from "../organisms/production/SectionHeader"
import { AddEditAttachmentModal } from "../organisms/production/AddEditAttachmentModal"
import { ProductionSection } from "../organisms/production/ProductionSection"
import { mediaQuery } from "@/util/stylingUtils"
import { GeneralVenueInformation } from "../organisms/production/GeneralVenueInformation"
import { Contacts } from "../organisms/production/Contacts"
import LoaderOverlay from "../organisms/LoaderOverlay"
import useProductionAttachments from "@/hooks/useProductionAttachments"
import { useConfirmCloseContext } from "@/context/confirmClose"
import { useCallback, useEffect, useRef, useState } from "react"
import { ArrowUpward, Check } from "@mui/icons-material"
import Grid from "@mui/material/Unstable_Grid2"
import {
	FACILITIES_SUBCATEGORIES,
	PRODUCTION_SUBCATEGORIES,
} from "@/util/constants/STAGE_SUBCATEGORY_KEYS"
import { ScrollableStack } from "../atoms/ScrollableStack"
import { PageRootContainer } from "../atoms/PageRootContainer"
import { Tabs } from "../molecules/Tabs"
import { Tab } from "../atoms/Tab"
import { Footer } from "../organisms/Footer"

export const PRODUCTION_PAGE_TRANSLATION_KEYS = [
	"generalVenueInfo",
	"contact_plural",
	"production",
	"facilities",
	"equipment",
	"logistic_plural",
	"localCrew",
] as const

export type ProductionPageTranslationKey = (typeof PRODUCTION_PAGE_TRANSLATION_KEYS)[number]

function ProductionPage() {
	const { t } = useTranslation()
	const scrollRef = useRef<HTMLDivElement>(null)
	const [displayScrollButton, setDisplayScrollButton] = useState(false)

	const {
		state: { productionTabIndex },
		updateProductionTabIndex,
	} = useProductionContext()

	const { handleConfirmNavgate } = useConfirmCloseContext()
	const { venueLogoUploadIsPending } = useProductionAttachments()

	const updateTab = useCallback(
		(idx: number) => {
			handleConfirmNavgate(() => updateProductionTabIndex(idx))
		},
		[updateProductionTabIndex, handleConfirmNavgate],
	)

	const resetScroll = () => {
		if (scrollRef.current) {
			scrollRef.current.scrollTo({
				top: 0,
				left: 0,
				behavior: "smooth",
			})
		} else {
		}
	}

	useEffect(() => {
		if (scrollRef.current) {
			scrollRef.current.addEventListener("scroll", () => {
				if (scrollRef.current && scrollRef.current.scrollTop > 140 && productionTabIndex !== 1) {
					setDisplayScrollButton(true)
				} else {
					setDisplayScrollButton(false)
				}
			})
		} else {
			setDisplayScrollButton(false)
		}
	}, [scrollRef, setDisplayScrollButton, productionTabIndex])

	return (
		<>
			<LoaderOverlay isLoading={venueLogoUploadIsPending} />
			<AddEditAttachmentModal modalType="add" />
			<AddEditAttachmentModal modalType="edit" />
			<StyledFab
				aria-label="back-to-top"
				onClick={resetScroll}
				showScrollButton={displayScrollButton}
			>
				<ArrowUpward />
			</StyledFab>
			<PageRootContainer ref={scrollRef}>
				<PageHeader pageTitle={t("production")} />
				<StyledTabs
					value={productionTabIndex}
					onChange={(_, val) => updateTab(val)}
					aria-label="production tabs"
					variant="scrollable"
					scrollButtons={false}
				>
					{PRODUCTION_PAGE_TRANSLATION_KEYS.map((val, idx) => (
						<Tab
							value={idx}
							key={`${val}-tab`}
							label={t(val)}
							data-cy={`productionheader-${t(val).toLowerCase().replace(/\s+/g, "")}`}
							disableRipple
							icon={<Check />}
							iconPosition="start"
						/>
					))}
				</StyledTabs>

				<SectionHeader pageTranslationKey={PRODUCTION_PAGE_TRANSLATION_KEYS[productionTabIndex]} />

				<ScrollableStack>
					{productionTabIndex === 0 ? (
						<GeneralVenueInformation />
					) : productionTabIndex === 1 ? (
						<Contacts />
					) : (
						<SectionGrid container gap={3}>
							{productionTabIndex === 2 ? (
								PRODUCTION_SUBCATEGORIES.map((val, idx) => (
									<ProductionSection
										key={val}
										stageSubcategory={val}
										hideDivider={idx === PRODUCTION_SUBCATEGORIES.length - 1}
									/>
								))
							) : productionTabIndex === 3 ? (
								FACILITIES_SUBCATEGORIES.map((val, idx) => (
									<ProductionSection
										key={val}
										stageSubcategory={val}
										hideDivider={idx === FACILITIES_SUBCATEGORIES.length - 1}
									/>
								))
							) : productionTabIndex === 4 ? (
								<ProductionSection stageSubcategory="equipmentData" hideDivider />
							) : productionTabIndex === 5 ? (
								<ProductionSection stageSubcategory="logisticsData" hideDivider />
							) : productionTabIndex === 6 ? (
								<ProductionSection stageSubcategory="localCrewData" hideDivider />
							) : (
								<></>
							)}
						</SectionGrid>
					)}
					<Footer />
				</ScrollableStack>
			</PageRootContainer>
		</>
	)
}

export default function Production() {
	return (
		<ProductionContextProvider>
			<ProductionAttatchmentsContextProvider>
				<ProductionPage />
			</ProductionAttatchmentsContextProvider>
		</ProductionContextProvider>
	)
}

const StyledTabs = styled(Tabs)`
	padding: 0px 48px;

	${mediaQuery("md")`
		padding: 0px 16px;
	`}
`

const SectionGrid = styled(Grid)`
	margin-top: 24px;

	${mediaQuery("lg")`
		margin-top: 0px;
	`}
`

const StyledFab = styled(Fab, {
	shouldForwardProp: prop => prop !== "showScrollButton",
})<{ showScrollButton: boolean }>`
	position: absolute;
	bottom: 24px;
	right: 24px;

	&.MuiFab-root {
		background-color: ${({ theme }) => theme.colorPalette.primary.container.value};

		svg.MuiSvgIcon-root {
			color: ${({ theme }) => theme.colorPalette.primary.container.on};
		}
	}

	&.MuiFab-root:hover {
		opacity: 1;
	}

	${({ showScrollButton }) =>
		showScrollButton
			? `
		opacity: 30%;
		visibility: visible;
	`
			: `
		opacity: 0;
		visibility: hidden;
	`}

	transition: opacity 150ms ease-out;
`
