import { useTranslation } from "react-i18next"
import Link from "../../icons/Link"
import { toast } from "react-toastify"
import { styled, useTheme } from "@mui/material"

type LinkButtonProps = {
	color?: string
	textToCopy: string
	backgroundColor?: string
}

const LinkButton = ({ color: colorProp, textToCopy, ...rest }: LinkButtonProps) => {
	const { t } = useTranslation()
	const theme = useTheme()
	const color = colorProp || theme.techPackIconColor

	const handleCopy = async () => {
		try {
			await navigator.clipboard.writeText(textToCopy)
			toast.success(t("linkCopied"))
		} catch (err) {
			toast.error(t("errorMessage"))
			console.error("Failed to copy: ", err)
		}
	}

	return (
		<ButtonContainer onClick={handleCopy} {...rest} data-cy-component="LinkButton_Component">
			<Link size={20} color={color} />
		</ButtonContainer>
	)
}

const ButtonContainer = styled("div", {
	shouldForwardProp: prop => prop !== "backgroundColor",
})<{ backgroundColor?: string }>`
	margin: 10px;
	display: flex;
	align-items: center;
	justify-content: center;
	width: 30px;
	height: 30px;
	background: ${({ theme, backgroundColor }) =>
		backgroundColor ? backgroundColor : theme.techPackIconBackground};
	border: ${({ theme, backgroundColor }) =>
		backgroundColor ? backgroundColor : theme.techPackIconBackground};
	border-radius: 50%;
`

export default LinkButton
