import FormField from "@/components/atoms/FormField"
import NewButton from "@/components/atoms/NewButton"
import { useProductionContext } from "@/context/production"
import { Cancel } from "@mui/icons-material"
import { styled, useTheme } from "@mui/material"
import { Dispatch, SetStateAction, useCallback } from "react"
import { useTranslation } from "react-i18next"
import { css } from "styled-components"

interface GeneralVenueContactDetailProps {
	setContact: Dispatch<SetStateAction<VenueContact[]>>
	contact: VenueContact
	idx: number
}

export const GeneralVenueContactDetail: React.FC<GeneralVenueContactDetailProps> = ({
	idx,
	contact,
	setContact,
}) => {
	const { t } = useTranslation()
	const { colorPalette } = useTheme()
	const { setState } = useProductionContext()

	const openDeleteVenueContactModal = useCallback(
		() =>
			setState(prev => ({
				...prev,
				deleteContactModalOpen: true,
				deleteVenueContactIndex: idx,
			})),
		[setState, idx],
	)

	return (
		<ContactContainer
			data-cy={`venuecontacts-container-${contact.label.replace(/\s+/g, "").toLowerCase()}`}
		>
			<FormField
				required
				value={contact.label ?? ""}
				label={t("contactLabel")}
				onChange={e =>
					setContact(prev =>
						prev.map((val, newIdx) => (idx === newIdx ? { ...val, label: e.target.value } : val)),
					)
				}
				fullWidth
				data-cy="venuecontacts-contactlabel"
			/>
			<div className="row">
				<FormField
					value={contact.phone ?? ""}
					label={t("phoneNumber")}
					fullWidth
					onChange={e =>
						setContact(prev =>
							prev.map((val, newIdx) =>
								idx === newIdx
									? { ...val, phone: e.target.value === "" ? null : e.target.value }
									: val,
							),
						)
					}
					data-cy="venuecontacts-phonenumber"
				/>
				<FormField
					value={contact.email ?? ""}
					label={t("emailAddress")}
					fullWidth
					onChange={e =>
						setContact(prev =>
							prev.map((val, newIdx) =>
								idx === newIdx
									? { ...val, email: e.target.value === "" ? null : e.target.value }
									: val,
							),
						)
					}
					data-cy="venuecontacts-emailaddress"
				/>
			</div>
			<NewButton
				buttonType="Text"
				text={t("deletePermanently")}
				buttonStyles={css`
					color: ${colorPalette.error.container.on};
				`}
				iconElement={<Cancel htmlColor={colorPalette.error.container.on} />}
				onClick={openDeleteVenueContactModal}
				data-cy="venuecontacts-deletepermanently"
			/>
		</ContactContainer>
	)
}

const ContactContainer = styled("div")`
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: start;
	width: 100%;
	gap: 12px;

	& > div.row {
		padding-top: 12px;
		gap: 24px;
		width: 100%;
		display: flex;
	}
`
