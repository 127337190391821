import { ArrowBack, ArrowForward } from "@mui/icons-material"
import { Pagination, PaginationItem, PaginationProps, styled } from "@mui/material"
import { Ref } from "react"

export const TablePagination = (props: PaginationProps & { ref?: Ref<unknown> }) => {
	return (
		<StyledPagination
			renderItem={item => (
				<StyledPaginationItem slots={{ previous: ArrowBack, next: ArrowForward }} {...item} />
			)}
			{...props}
		/>
	)
}

const StyledPagination = styled(Pagination)`
	color: ${({ theme }) => theme.colorPalette.primary.value};

	& .MuiPagination-ul {
		justify-content: center;
		flex-wrap: nowrap;
	}
`

const StyledPaginationItem = styled(PaginationItem)`
	margin: 0px;

	&.MuiPaginationItem-text.MuiButtonBase-root {
		width: 48px;
		height: 48px;
		gap: 10px;
		border-radius: 100px;
	}

	&.MuiPaginationItem-page {
		font: 400 16px/24px Signika-Regular;
		letter-spacing: 0.5px;
		text-align: center;

		color: ${({ theme }) => theme.colorPalette.surface.on};
		background-color: none;

		&.Mui-selected {
			color: ${({ theme }) => theme.colorPalette.primary.on};
			background-color: ${({ theme }) => theme.colorPalette.primary.value};
		}
	}

	&.MuiPaginationItem-ellipsis {
		color: ${({ theme }) => theme.colorPalette.surface.on};
		background-color: none;
		user-select: none;
	}

	&.MuiPaginationItem-previousNext {
		color: ${({ theme }) => theme.colorPalette.primary.value};
	}
`
//  MuiPaginationItem-root MuiPaginationItem-sizeMedium MuiPaginationItem-text MuiPaginationItem-circular MuiPaginationItem-page .MuiPaginationItem-text.MuiButtonBase-root
// MuiPaginationItem-root MuiPaginationItem-sizeMedium MuiPaginationItem-text MuiPaginationItem-circular MuiPaginationItem-ellipsis
