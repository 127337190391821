import Modal from "@/components/molecules/Modal"
import useProductionAttachments from "@/hooks/useProductionAttachments"
import { Add, AttachFile, Check } from "@mui/icons-material"
import { SelectChangeEvent, styled, useTheme } from "@mui/material"
import { useCallback, useEffect, useMemo, useRef, useState } from "react"
import { Trans, useTranslation } from "react-i18next"
import { css } from "styled-components"
import { Select } from "@/components/molecules/Select"
import { AttachmentList } from "@/components/molecules/AttachmentList"
import { AttachmentDetails } from "./AttachmentDetails"
import { DeleteAttachmentModal } from "./DeleteAttachmentModal"
import LoaderOverlay from "../LoaderOverlay"
import { mediaQuery } from "@/util/stylingUtils"
import Grid from "@mui/material/Unstable_Grid2"
import { BREAKPOINTS } from "@/util/constants/BREAKPOINTS"

interface AddEditAttachmentModalProps {
	modalType: "add" | "edit"
}

export const AddEditAttachmentModal = ({ modalType }: AddEditAttachmentModalProps) => {
	const { t } = useTranslation()
	const { colorPalette } = useTheme()

	const leftGridRef = useRef<HTMLDivElement>(null)
	const [offset, setOffset] = useState(0)

	const {
		state: {
			addAttachmentModalOpen,
			editAttachmentModalOpen,
			category,
			translatedSectionFields,
			addEditModalState: { selectedAttachmentId, previewData, selectedField, fieldError },
			categorySection,
		},
		setState,
		canSubmitAttachments,
		submitAddAttachments,
		submitEditAttachments,
		fileAttachments,
		requestPending,
	} = useProductionAttachments()

	const handleCloseModal = useCallback(() => {
		if (modalType === "add") {
			setState(prev => ({
				...prev,
				addAttachmentModalOpen: false,
			}))
		} else {
			setState(prev => ({
				...prev,
				editAttachmentModalOpen: false,
			}))
		}
	}, [modalType, setState])

	const handleSelectField = useCallback(
		(e: SelectChangeEvent<string>) => {
			const foundKey = translatedSectionFields.find(val => val.translatedValue === e.target.value)

			if (foundKey) {
				setState(prev => ({
					...prev,
					addEditModalState: {
						...prev.addEditModalState,
						selectedField: foundKey.translationKey,
						fieldError: false,
					},
				}))
			}
		},
		[translatedSectionFields, setState],
	)

	const selectOptions = useMemo(() => {
		if (modalType === "add") {
			if (category === "productionData") {
				return translatedSectionFields.filter(
					val => val.translationKey !== "dimensions" && val.translationKey !== "proscenium",
				)
			}
			return translatedSectionFields
		}

		if (fileAttachments === undefined) {
			return []
		}

		const sectionFields = fileAttachments.reduce<string[]>((prev, curr) => {
			const splitField = curr.category.split(".")

			if (splitField[1] !== category) {
				return prev
			}

			const newField = splitField.pop()

			if (
				newField === undefined ||
				prev.includes(newField) ||
				newField === "proscenium" ||
				newField === "dimensions"
			) {
				return prev
			}

			return [...prev, newField]
		}, [])

		return translatedSectionFields.filter(val => sectionFields.includes(val.translationKey))
	}, [fileAttachments, translatedSectionFields, category, modalType])

	const parsedSectionName = useMemo(() => categorySection.split("Data")[0], [categorySection])

	useEffect(() => {
		const setClientHeight = () => {
			const newContainerHeight = (leftGridRef.current?.clientHeight ?? 0) + 16
			if (offset !== newContainerHeight) {
				setOffset(newContainerHeight)
			}
		}

		if (modalType === "add" ? addAttachmentModalOpen : editAttachmentModalOpen) {
			setClientHeight()
		}

		window.addEventListener("resize", setClientHeight)
		return () => {
			window.removeEventListener("resize", setClientHeight)
		}
	}, [modalType, addAttachmentModalOpen, editAttachmentModalOpen, offset, setOffset])

	return (
		<>
			<Modal
				open={modalType === "add" ? addAttachmentModalOpen : editAttachmentModalOpen}
				closeModal={handleCloseModal}
				size={selectedAttachmentId ? "2xl" : "md"}
				title={modalType === "add" ? t("addAttachment") : t("editAttachment")}
				titleIcon={<AttachFile />}
				subHeader={
					selectedAttachmentId ? undefined : modalType === "add" ? (
						<Trans
							i18nKey="uploadFilesToSection"
							values={{
								section_name: t(parsedSectionName),
							}}
							components={{
								b: <strong />,
							}}
						/>
					) : (
						<Trans
							i18nKey="editFilesInSection"
							values={{
								section_name: t(parsedSectionName),
							}}
							components={{
								b: <strong />,
							}}
						/>
					)
				}
				hint={
					selectedAttachmentId
						? undefined
						: modalType === "add"
						? previewData.length === 0
							? undefined
							: t("addAttachmentModalBlurb")
						: t("editAttachmentModalBlurb")
				}
				footerActions={{
					end: [
						"Cancel",
						{
							buttonType: "Outline",
							text: modalType === "add" ? t("upload") : t("update"),
							iconElement:
								modalType === "add" ? (
									<Add
										htmlColor={colorPalette.primary.value}
										sx={{
											height: "18px",
											width: "18px",
										}}
									/>
								) : (
									<Check
										htmlColor={colorPalette.primary.value}
										sx={{
											height: "18px",
											width: "18px",
										}}
									/>
								),
							stateLayerStyles: css`
								width: 116px;
								padding: 10px 16px 10px 12px;
							`,
							disabled: !canSubmitAttachments || requestPending,
							onClick: () =>
								modalType === "add" ? submitAddAttachments() : submitEditAttachments(),
						},
					],
				}}
			>
				<RootGrid container gap={2}>
					<LeftGrid
						container
						sm
						flexDirection="column"
						justifyContent="start"
						alignContent="start"
						flexGrow={2}
						alignSelf="stretch"
						detailsOpen={selectedAttachmentId !== undefined}
					>
						{!selectedAttachmentId ? (
							<></>
						) : modalType === "add" ? (
							<ExpandedHeaderContainer xs={12}>
								<p>
									<Trans
										i18nKey="uploadFilesToSection"
										values={{
											section_name: t(parsedSectionName),
										}}
										components={{
											b: <strong />,
										}}
									/>
								</p>
								<small>{t("addAttachmentModalBlurb")}</small>
							</ExpandedHeaderContainer>
						) : (
							<ExpandedHeaderContainer xs={12}>
								<p>
									<Trans
										i18nKey="editFilesInSection"
										values={{
											section_name: t(parsedSectionName),
										}}
										components={{
											b: <strong />,
										}}
									/>
								</p>
								<small>{t("editAttachmentModalBlurb")}</small>
							</ExpandedHeaderContainer>
						)}

						{!selectedAttachmentId &&
							((previewData.length > 0 && modalType === "add") || modalType === "edit") && (
								<Grid sm={12}>
									<Select
										onChange={handleSelectField}
										value={t(selectedField)}
										simple
										optionValues={selectOptions.map(val => val.translatedValue)}
										label={t("selectField")}
										placeholder={t("selectField")}
										fullWidth
										MenuProps={{
											sx: {
												"& .MuiPaper-root": {
													maxHeight: "267px",
												},
											},
										}}
										error={modalType === "add" && fieldError}
										disabled={modalType === "edit" && canSubmitAttachments}
									/>
								</Grid>
							)}
						<ListGrid
							ref={leftGridRef}
							xs={12}
							flexGrow={2}
							alignSelf="stretch"
							detailsOpen={false}
						>
							<AttachmentList listType={modalType} />
						</ListGrid>
					</LeftGrid>
					<DetailsGrid
						xs={12}
						offset={offset}
						detailsOpen={selectedAttachmentId !== undefined}
						className={selectedAttachmentId ? "detailsOpen" : ""}
					>
						<AttachmentDetails detailType={modalType} />{" "}
					</DetailsGrid>
				</RootGrid>
			</Modal>
			{modalType === "edit" && <DeleteAttachmentModal />}
			<LoaderOverlay isLoading={requestPending} />
		</>
	)
}

const RootGrid = styled(Grid)`
	padding: 8px 11px 0px 11px;
	justify-content: center;
	align-items: start;
	position: relative;

	${mediaQuery("md")`
		padding: 8px 0px 0px;
	`}
`

const ListGrid = styled(Grid, {
	shouldForwardProp: prop => prop !== "detailsOpen",
})<{ detailsOpen: boolean }>`
	min-height: 192px;

	${({ detailsOpen }) =>
		mediaQuery("md")(`
		visibility: ${detailsOpen ? "hidden" : "visible"};
		display: ${detailsOpen ? "none" : "block"};
	`)}
`

const DetailsGrid = styled(Grid, {
	shouldForwardProp: prop => prop !== "detailsOpen" && prop !== "offset",
})<{ detailsOpen: boolean; offset: number }>`
	position: relative;
	width: auto;
	height: auto;
	display: ${({ detailsOpen }) => (detailsOpen ? "block" : "none")};
	visibility: ${({ detailsOpen }) => (detailsOpen ? "visible" : "hidden")};
	opacity: ${({ detailsOpen }) => (detailsOpen ? "1" : "0")};

	@media (max-width: ${BREAKPOINTS.md}px) {
		top: -${({ offset }) => offset}px;
		margin-bottom: -${({ offset }) => offset}px;
		width: 100%;
	}
`

const LeftGrid = styled(Grid, {
	shouldForwardProp: prop => prop !== "detailsOpen",
})<{ detailsOpen: boolean }>`
	width: 496px;
	gap: 16px;

	${({ detailsOpen: _a }) => mediaQuery("md")`
		width: 100%;
	`}
`

const ExpandedHeaderContainer = styled(Grid)`
	padding: 0px 16px;
	gap: 16px;
	display: flex;
	flex-direction: column;
	justify-content: start;
	align-items: center;

	p {
		font: 400 14px/20px Signika-Regular;
		color: ${({ theme }) => theme.colorPalette.surface.onVariant};
		text-align: center;
	}

	small {
		font: 400 12px/16px Signika-Regular;
		color: ${({ theme }) => theme.colorPalette.surface.onVariant};
		text-align: left;

		&::before {
			content: "*";
		}
	}
`
