export const DEFAULT_STAFF_CREW_ROW: StaffCrewRow = {
	userId: "",
	email: "",
	isActive: false,
	name: {},
	phone: "",
	roleName: "",
	title: "",
	includedInTechpack: false,
	order: null,
	hasLoggedIn: null,
	lastNotifiedSignup: null,
}
