import { FC, useCallback } from "react"
import { useTranslation } from "react-i18next"
import { KeyboardEvent } from "react"
import NewButton from "../../atoms/NewButton"
import { styled } from "@mui/material"
import { css } from "styled-components"
import { HintFormField } from "@/components/molecules/HintFormField"
import useLogin from "@/hooks/useLogin"

export const ForgotPasswordForm: FC<{}> = () => {
	const { t } = useTranslation()
	const {
		state: { email, resetNotification },
		setState,
		resetPassword,
		resetPasswordPending,
	} = useLogin()

	const handleResetSend = useCallback(() => {
		if (email === "" || resetPasswordPending) return

		resetPassword()
	}, [email, resetPassword, resetPasswordPending])

	return (
		<FormContainer
			onKeyDown={(e: KeyboardEvent) => {
				if (e.key === "Enter") {
					e.preventDefault()
					handleResetSend()
				}
			}}
		>
			<HintFormField
				formFieldProps={{
					"data-cy": "passwordreset-emailinput",
					variant: "standard",
					type: "email",
					label: t("email"),
					required: true,
					id: "emailTextField",
					placeholder: "example@gmail.com",
					fullWidth: true,
					value: email,
					onChange: e => setState(prev => ({ ...prev, email: e.target.value })),
					autoComplete: "username",
				}}
				displayStatus={
					resetNotification === "checkEmail"
						? "hint"
						: resetNotification === "unregisteredAccount"
						? "error"
						: "hidden"
				}
				translatedHintText={t(resetNotification)}
			/>
			<NewButton
				buttonType="Filled"
				text={t("sendResetEmail")}
				buttonStyles={css`
					border-radius: 6px;
				`}
				textStyles={css`
					font-size: 16px;
					line-height: 24px;
				`}
				onClick={() => handleResetSend()}
				disabled={email === ""}
			/>
		</FormContainer>
	)
}

const FormContainer = styled("form")`
	width: 100%;
	display: inherit;
	justify-content: inherit;
	gap: 40px;
	flex-direction: inherit;
	align-items: center;
`
