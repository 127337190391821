import { PlaceholderContent } from "@/components/atoms/PlaceholderContent"
import { OrderedList, OrderedRowData } from "@/components/molecules/OrderedList"
import { useProductionContext } from "@/context/production"
import useDebounce from "@/hooks/useDebounce"
import { Stack, styled } from "@mui/material"
import { useCallback, useEffect, useState } from "react"
import { useTranslation } from "react-i18next"

type ContactsTableKey = "name" | "title" | "phone" | "email"

export const Contacts: React.FC<{}> = () => {
	const { t } = useTranslation()
	const {
		state: { formattedProductionContacts },
		setState,
	} = useProductionContext()

	const [contactsShallowCopy, setContactsShallowCopy] = useState<
		OrderedRowData<ContactsTableKey>[]
	>([])

	const updateProductionContacts = useCallback(
		(newData: OrderedRowData<ContactsTableKey>[]) => {
			setState(prev => ({
				...prev,
				formattedProductionContacts: newData as Record<ContactsTableKey | "id", string>[],
			}))
		},
		[setState],
	)

	useDebounce(contactsShallowCopy, updateProductionContacts, 100)

	useEffect(() => {
		if (formattedProductionContacts.length !== contactsShallowCopy.length) {
			setContactsShallowCopy(formattedProductionContacts)
		}
	}, [formattedProductionContacts, setContactsShallowCopy, contactsShallowCopy.length])

	return (
		<RootContainer>
			<ContactDescription>
				<p>{t("productionContactsBlurb")}</p>
				<p>
					<i>{t("productionContactsMissingBlurb")}</i>
				</p>
			</ContactDescription>
			{contactsShallowCopy.length === 0 ? (
				<PlaceholderContainer>
					<PlaceholderContent text={t("noStaffFound")} />
				</PlaceholderContainer>
			) : (
				<OrderedList
					headerData={[
						{
							cellKey: "name",
							label: t("name"),
						},
						{
							cellKey: "title",
							label: t("title"),
						},
						{
							cellKey: "phone",
							label: t("phone"),
						},
						{
							cellKey: "email",
							label: t("email"),
						},
					]}
					listData={contactsShallowCopy}
					setListData={setContactsShallowCopy}
					data-cy="production-contacts-table"
				/>
			)}
		</RootContainer>
	)
}

const PlaceholderContainer = styled("div")`
	& {
		height: 216px;
		width: 100%;
		padding: 0px;
	}
`

const RootContainer = styled(Stack)`
	padding-top: 24px;
	gap: 24px;
	width: 100%;
`

const ContactDescription = styled(Stack)`
	width: 100%;
	gap: 10px;

	& > p {
		font-family: Roboto-Regular;
		font-weight: 400;
		font-size: 14px;
		line-height: 20px;
		letter-spacing: 0.25px;
		color: ${({ theme }) => theme.colorPalette.surface.on};

		i::before {
			content: "*";
		}
	}
`
