import NewModal from "@/components/molecules/Modal"
import { useHistoryContext } from "@/context/history"
import { useVenueStageTypes } from "@/hooks/useVenueStageTypes"
import useWindowDimensions from "@/hooks/useWindowDimensions"
import { BREAKPOINTS } from "@/util/constants/BREAKPOINTS"
import { normalizeStringBoolean } from "@/util/dataValidation"
import { getFilterFieldSupportText, getHistoryTimestamp } from "@/util/historyUtils"
import { getDisplayName } from "@/util/userUtils"
import { mediaQuery } from "@/util/stylingUtils"
import { ArrowDownward, ArrowForward, CloseOutlined } from "@mui/icons-material"
import { Unstable_Grid2 as Grid, styled, SvgIcon } from "@mui/material"
import { useCallback, useMemo } from "react"
import { useTranslation } from "react-i18next"
import { useTheme } from "styled-components"

export const HistoryDetailModal = () => {
	const {
		state: { modalOpen, selectedDatalogId },
		setState,
		selectedDatalog,
	} = useHistoryContext()
	const { width: windowWidth } = useWindowDimensions()
	const { colorPalette } = useTheme()
	const { t } = useTranslation()
	const { venueStageTypes } = useVenueStageTypes()

	const closeModal = useCallback(() => {
		setState(prev => ({
			...prev,
			modalOpen: false,
		}))
	}, [setState])

	const formatEntries = useCallback(
		(val: HistoryDataChangeLog) => {
			const formatEntry = (name: string, entry: string) => {
				switch (name) {
					case "venueStageTypeId":
						return t(
							venueStageTypes.find(stageType => stageType.id === entry)?.translationKey || "null",
						)
					case "venueTypeId":
						return t(entry)
					case "isMainStage":
					case "isActive":
						return t(normalizeStringBoolean(entry))
					default:
						return entry
				}
			}
			return {
				previousEntry: formatEntry(val.fieldNameMetadata.name, val.originalValue || "null"),
				updatedEntry: formatEntry(val.fieldNameMetadata.name, val.updatedValue || "null"),
			}
		},
		[venueStageTypes, t],
	)

	const { previousEntry, updatedEntry } = selectedDatalog
		? formatEntries(selectedDatalog)
		: { previousEntry: "", updatedEntry: "" }

	const modalTitle = useMemo(() => {
		if (!selectedDatalog) {
			return ""
		}
		const labelKey = selectedDatalog.fieldNameMetadata.labelTranslationKey

		switch (labelKey) {
			case "contacts":
				return t("contact_plural")
			default:
				return t(labelKey)
		}
	}, [selectedDatalog, t])

	return (
		<NewModal
			open={modalOpen && selectedDatalogId === selectedDatalog?.id}
			closeModal={closeModal}
			size="xl"
			title={modalTitle}
			subHeader={
				selectedDatalog
					? getFilterFieldSupportText(selectedDatalog.fieldNameMetadata)
							.map(val => t(val))
							.join(" > ")
					: ""
			}
			titleIcon={
				<SvgIcon>
					<svg
						viewBox="0 0 24 24"
						fill="currentColor"
						xmlns="http://www.w3.org/2000/svg"
						stroke="currentColor"
						strokeWidth={0.05}
					>
						<path d="M5 22H2C1.45 22 0.979167 21.8042 0.5875 21.4125C0.195833 21.0208 0 20.55 0 20V17H2V20H5V22ZM17 22V20H20V17H22V20C22 20.55 21.8042 21.0208 21.4125 21.4125C21.0208 21.8042 20.55 22 20 22H17ZM11 17.5C9 17.5 7.1875 16.9083 5.5625 15.725C3.9375 14.5417 2.75 12.9667 2 11C2.75 9.03333 3.9375 7.45833 5.5625 6.275C7.1875 5.09167 9 4.5 11 4.5C13 4.5 14.8125 5.09167 16.4375 6.275C18.0625 7.45833 19.25 9.03333 20 11C19.25 12.9667 18.0625 14.5417 16.4375 15.725C14.8125 16.9083 13 17.5 11 17.5ZM11 15.5C12.4667 15.5 13.8083 15.1 15.025 14.3C16.2417 13.5 17.175 12.4 17.825 11C17.175 9.6 16.2417 8.5 15.025 7.7C13.8083 6.9 12.4667 6.5 11 6.5C9.53333 6.5 8.19167 6.9 6.975 7.7C5.75833 8.5 4.825 9.6 4.175 11C4.825 12.4 5.75833 13.5 6.975 14.3C8.19167 15.1 9.53333 15.5 11 15.5ZM11 14.5C11.9667 14.5 12.7917 14.1583 13.475 13.475C14.1583 12.7917 14.5 11.9667 14.5 11C14.5 10.0333 14.1583 9.20833 13.475 8.525C12.7917 7.84167 11.9667 7.5 11 7.5C10.0333 7.5 9.20833 7.84167 8.525 8.525C7.84167 9.20833 7.5 10.0333 7.5 11C7.5 11.9667 7.84167 12.7917 8.525 13.475C9.20833 14.1583 10.0333 14.5 11 14.5ZM11 12.5C10.5833 12.5 10.2292 12.3542 9.9375 12.0625C9.64583 11.7708 9.5 11.4167 9.5 11C9.5 10.5833 9.64583 10.2292 9.9375 9.9375C10.2292 9.64583 10.5833 9.5 11 9.5C11.4167 9.5 11.7708 9.64583 12.0625 9.9375C12.3542 10.2292 12.5 10.5833 12.5 11C12.5 11.4167 12.3542 11.7708 12.0625 12.0625C11.7708 12.3542 11.4167 12.5 11 12.5ZM0 5V2C0 1.45 0.195833 0.979167 0.5875 0.5875C0.979167 0.195833 1.45 0 2 0H5V2H2V5H0ZM20 5V2H17V0H20C20.55 0 21.0208 0.195833 21.4125 0.5875C21.8042 0.979167 22 1.45 22 2V5H20Z" />
					</svg>
				</SvgIcon>
			}
			footerActions={{
				end: [
					{
						buttonType: "Outline",
						text: t("close"),
						onClick: () => closeModal(),
						iconElement: <CloseOutlined />,
					},
				],
			}}
		>
			<ModalContentStack container gap={2}>
				<StyledEntryDiv container xs={12} md>
					<Grid xs={12}>
						<EntryHeader>{t("previousEntry")}</EntryHeader>
					</Grid>
					<Grid xs={12}>
						<ScrollableContent>
							{previousEntry === "null" ? `< ${t("empty")} >` : previousEntry}
						</ScrollableContent>
					</Grid>
				</StyledEntryDiv>
				<ArrowStack xs={12} md="auto" container>
					<Grid xs="auto" md={12}>
						{windowWidth > BREAKPOINTS.md ? (
							<ArrowForward htmlColor={colorPalette.outline.value} />
						) : (
							<ArrowDownward htmlColor={colorPalette.outline.value} />
						)}
					</Grid>
					<Grid xs="auto" md={12}>
						{windowWidth > BREAKPOINTS.md ? (
							<ArrowForward htmlColor={colorPalette.outline.value} />
						) : (
							<ArrowDownward htmlColor={colorPalette.outline.value} />
						)}
					</Grid>
					<Grid xs="auto" md={12}>
						{windowWidth > BREAKPOINTS.md ? (
							<ArrowForward htmlColor={colorPalette.outline.value} />
						) : (
							<ArrowDownward htmlColor={colorPalette.outline.value} />
						)}
					</Grid>
				</ArrowStack>
				<StyledEntryDiv container xs={12} md>
					<Grid xs={12}>
						<EntryHeader>{t("updatedEntry")}</EntryHeader>
					</Grid>
					<Grid xs={12}>
						<ScrollableContent>
							{updatedEntry === "null" ? `< ${t("empty")} >` : updatedEntry}
						</ScrollableContent>
					</Grid>
				</StyledEntryDiv>
			</ModalContentStack>
			<UpdatedByText>
				{t("updatedByOn", {
					name: selectedDatalog ? getDisplayName(selectedDatalog.user.name, true) : "",
					date: selectedDatalog ? getHistoryTimestamp(selectedDatalog.actionDate, " at ") : "",
					interpolation: { escapeValue: false },
				})}
			</UpdatedByText>
		</NewModal>
	)
}

const ArrowStack = styled(Grid)`
	gap: 80px;
	width: 24px;
	justify-content: center;
	align-items: center;
	margin-top: 32px;

	${mediaQuery("md")`
		width: auto;
		justify-content: center;
		margin-top: 0px;
	`}
`

const ModalContentStack = styled(Grid)``

const UpdatedByText = styled("p")`
	padding: 0px 8px;
	margin-top: 20px;
	width: 100%;
	color: ${({ theme }) => theme.colorPalette.surface.on};
	font: 400 12px/16px FiraSans-Regular;
`

const ScrollableContent = styled("div")`
	width: 100%;
	height: 392px;
	padding: 16px 8px 16px 16px;
	gap: 8px;
	border: 1px solid ${({ theme }) => theme.colorPalette.outline.value};
	border-radius: 4px;

	font: 400 14px/20px Signika-Regular;
	color: ${({ theme }) => theme.colorPalette.surface.on};
	overflow-y: scroll;
	white-space: pre-line;
`

const EntryHeader = styled("h5")`
	font: 500 16px/24px Signika-Medium;
	color: ${({ theme }) => theme.colorPalette.surface.onVariant};
	padding: 0px 8px;
	user-select: none;
`

const StyledEntryDiv = styled(Grid)`
	width: 352px;
	gap: 8px;

	${mediaQuery("md")`
		width: 100%;
	`}
`
