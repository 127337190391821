export const getDisplayName = (profileName: ProfileName, nickNameFallback: boolean = false) => {
	if (profileName.displayName !== undefined && profileName.displayName.trim() !== "") {
		return profileName.displayName
	}

	if (profileName.preferredName != null && profileName.preferredName.trim() !== "") {
		return profileName.preferredName
	}

	if (nickNameFallback && profileName.nickName != null && profileName.nickName.trim() !== "") {
		return profileName.nickName
	}

	return [profileName.firstName ?? "", profileName.lastName ?? ""].join(" ").trim()
}

/** Helps backfill the transition from nickName value to preferredName, adds preferredName field if it is null but nickName is not */
export const normalizeNameObject = (profileName: ProfileName) => {
	if (profileName.preferredName != null || profileName.nickName == null) {
		return profileName
	}

	return {
		...profileName,
		preferredName: profileName.nickName,
	}
}
