import React from "react"
import { styled } from "@mui/material"
import SingleLineLight from "@logos/MTV_Logo_Light.svg"
import SingleLineDark from "@logos/MTV_Logo_Dark.svg"
import useDarkMode from "@/hooks/useDarkMode"
import { mediaQuery } from "@/util/stylingUtils"

export const LoginWrapper: React.FC<{ children: React.ReactNode }> = ({ children }) => {
	const { darkMode } = useDarkMode()

	return (
		<PageWrapper>
			<FormContainer>
				<StyledImg src={darkMode ? SingleLineDark : SingleLineLight} alt="Eventric Logo" />

				{children}
			</FormContainer>
		</PageWrapper>
	)
}

const StyledImg = styled("img")`
	width: 90%;
	margin: 0px 16px;
	margin-bottom: 40px;
`

const FormContainer = styled("div")`
	width: 448px;
	margin: 0px 16px;
	padding: 32px 0px;
	margin-top: auto;
	margin-bottom: auto;
	display: flex;
	justify-content: start;
	flex-direction: column;

	align-items: center;
	gap: 40px;

	${mediaQuery("md")`
		width: 100%;
		max-width: 448px;
	`}
`

const PageWrapper = styled("div")`
	width: 100vw;
	height: 100vh;
	display: flex;
	justify-content: center;
	align-items: center;

	background: ${({ theme }) => theme.colorPalette.surface.bright};
	overflow-y: scroll;
`
