import React from "react"
import { ToggleButton, ToggleButtonGroup } from "@mui/material"
import styled, { useTheme } from "styled-components"
import { Trans, useTranslation } from "react-i18next"
import useDarkMode from "../../../../hooks/useDarkMode"
import { mediaQuery } from "@/util/stylingUtils"
import useTechPack from "../../../../hooks/useTechPack"
import { dateFormatToLocale } from "../../../../util/dateFunctions"
import LightModeToggle from "../../../../icons/LightModeToggle"
import DarkModeToggle from "../../../../icons/DarkModeToggle"

export const LightDarkModeToggle: React.FC = () => {
	const { t } = useTranslation()
	const { darkMode, setDarkMode } = useDarkMode()
	const { modifiedDate, stage } = useTechPack()
	const theme = useTheme()

	return (
		<Container>
			<UpdatedByText>
				<Trans
					i18nKey="lastUpdatedOnBy"
					values={{
						updateAuthor: stage.metadata?.venueModifiedByName
							? stage.metadata.venueModifiedByName
							: t("masterTourStaff"),
						date: dateFormatToLocale(modifiedDate || ""),
						role: stage.metadata?.venueModifiedByTitle
							? stage.metadata.venueModifiedByTitle
							: t("administrator"),
					}}
					components={{ b: <span style={{ fontFamily: "Signika-Regular" }} /> }}
					shouldUnescape
				/>
			</UpdatedByText>
			<ToggleButtonGroup exclusive aria-label="text alignment">
				<LightToggleButton
					data-cy="darkLightModeToggle_Light"
					value="left"
					aria-label="left aligned"
					onClick={() => setDarkMode(false)}
				>
					<LightModeToggle size={20} color={darkMode ? theme.techPackIconColor : theme.white} />
				</LightToggleButton>
				<DarkToggleButton
					data-cy="darkLightModeToggle_Dark"
					value="center"
					aria-label="centered"
					onClick={() => setDarkMode(true)}
				>
					<DarkModeToggle size={20} color={darkMode ? theme.white : theme.techPackIconBackground} />
				</DarkToggleButton>
			</ToggleButtonGroup>
		</Container>
	)
}

const LightToggleButton = styled(ToggleButton)(({ theme }) => ({
	"&.MuiToggleButton-root, &.MuiToggleButton-root:hover": {
		backgroundColor: theme.techPackIconBackground,
		border: `solid 1px ${theme.techPackBorder}`,
		padding: `5px 20px`,
		flexWrap: "wrap",
		height: "42px",
		width: "100px",
		borderRadius: `10px 0 0 10px`,
		"@media (max-width: 1023px)": {
			padding: `5px 10px`,
		},
	},
	"&.MuiToggleButton-root:hover": {
		opacity: 0.75,
	},
}))

const DarkToggleButton = styled(ToggleButton)(({ theme }) => ({
	"&.MuiToggleButton-root, &.MuiToggleButton-root:hover": {
		backgroundColor: theme.techPackHeaderButtonBg,
		border: `solid 1px ${theme.techPackBorder}`,
		padding: `5px 20px`,
		flexWrap: "wrap",
		height: "42px",
		width: "100px",
		borderRadius: "0 10px 10px 0",
		"@media (max-width: 1023px)": {
			padding: `5px 10px`,
		},
	},
	"&.MuiToggleButton-root:hover": {
		opacity: 0.75,
	},
}))

const Container = styled.div`
	padding-top: 40px;
	padding-bottom: 10px;
	border-top: 3px solid ${({ theme }) => theme.techPackTextHeadings};
	display: flex;
	justify-content: center;
	align-items: center;
	flex-direction: column;
	margin-top: 20px;
`
const UpdatedByText = styled.p`
	font-family: "Signika-Regular" !important;
	font-size: 16px;
	text-align: center;
	margin: 0;
	padding-bottom: 20px;
	color: ${({ theme }) => theme.techPackText};
	margin-right: 60px;
	margin-left: 60px;

	${mediaQuery("md")`
  		font-size: 16px;
	`};
`

export default LightDarkModeToggle
