import useAxios from "@/hooks/useAxios"
import { UseMutateFunction, useMutation } from "@tanstack/react-query"
import { createContext, useState } from "react"
import { toast } from "react-toastify"
import { LOCAL_STORAGE_REDIRECT_AFTER_LOGIN } from "./axios"
import { useTranslation } from "react-i18next"
import { useNavigate } from "react-router-dom"
import baseAxios, { AxiosResponse } from "axios"
import { useUserContext } from "./user"
const { REACT_APP_API_URL } = process.env

export interface LoginContextStateType {
	email: string
	password: string
	resetNotification: "" | "unregisteredAccount" | "checkEmail"
}

const DEFAULT_LOGIN_STATE: LoginContextStateType = {
	email: "",
	password: "",
	resetNotification: "",
}

export interface LoginContextType {
	state: LoginContextStateType
	setState: React.Dispatch<React.SetStateAction<LoginContextStateType>>

	login: UseMutateFunction<void, Error, void, unknown>
	loginPending: boolean

	resetPassword: UseMutateFunction<AxiosResponse<any, any>, Error, void, unknown>
	resetPasswordPending: boolean
}

export const LoginContext = createContext<LoginContextType>({
	state: DEFAULT_LOGIN_STATE,
	setState: () => null,

	login: async () => undefined,
	loginPending: false,

	resetPassword: async () => undefined,
	resetPasswordPending: false,
})

export const LoginContextProvider = ({
	children,
}: React.PropsWithChildren<unknown>): JSX.Element => {
	const { t } = useTranslation()
	const navigate = useNavigate()
	const axios = useAxios()
	const [state, setState] = useState(DEFAULT_LOGIN_STATE)
	const { getUser } = useUserContext()

	const { mutate: login, isPending: loginPending } = useMutation({
		mutationFn: async () => {
			const data = {
				username: state.email,
				password: state.password,
			}
			return axios
				.post<{ success: boolean; data: { token: string } }>("/venues/v1/login/masterVenue", data)
				.then(res => {
					if (res?.data?.success) {
						// save token to local storage
						localStorage.token = res.data.data.token
						getUser()
					} else {
						throw Error("Login Failed")
					}
				})
		},
		onError: () => {
			toast.error(t("invalidEmailPasswordCombination"))
		},
		onSuccess: () => {
			// if token was invalid, hooks/axios interceptor sent user here and potentially had a redirect back to their previous page
			const redirectFromToken = localStorage.getItem(LOCAL_STORAGE_REDIRECT_AFTER_LOGIN)

			if (redirectFromToken) {
				localStorage.removeItem(LOCAL_STORAGE_REDIRECT_AFTER_LOGIN)
				navigate(redirectFromToken)
			} else {
				navigate("/production")
			}
		},
	})

	const { mutate: resetPassword, isPending: resetPasswordPending } = useMutation({
		mutationFn: async () =>
			await baseAxios.post(`${REACT_APP_API_URL}/venues/v1/password/reset`, {
				username: state.email,
			}),

		onError: () => {
			toast.error(t("unregisteredAccount"))
			setState(prev => ({ ...prev, resetNotification: "unregisteredAccount" }))
		},

		onSuccess: () => {
			toast.success(t("checkEmail"))
			setState(prev => ({ ...prev, resetNotification: "checkEmail" }))
		},
	})

	return (
		<LoginContext.Provider
			value={{
				state,
				setState,
				login,
				loginPending,
				resetPassword,
				resetPasswordPending,
			}}
		>
			{children}
		</LoginContext.Provider>
	)
}
