import FormField from "@/components/atoms/FormField"
import NewButton from "@/components/atoms/NewButton"
import { STAGE_SUBCATEGORY_KEYS } from "@/util/constants/STAGE_SUBCATEGORY_KEYS"
import { AttachFile } from "@mui/icons-material"
import { styled } from "@mui/material"
import { useTranslation } from "react-i18next"
import { css } from "styled-components"
import { AttachmentsPreview } from "./AttachmentsPreview"
import { getCategory } from "@/util/fieldUtils"
import React, { useCallback, useEffect, useState } from "react"
import useDebounce from "@/hooks/useDebounce"
import useProductionAttachments from "@/hooks/useProductionAttachments"
import { getObjectValues } from "@/util/typedObjectMapping"
import { useVenuesContext } from "@/context/venues"
import { useProductionContext } from "@/context/production"
import Grid from "@mui/material/Unstable_Grid2"
import { mediaQuery } from "@/util/stylingUtils"
import { Divider } from "@/components/atoms/Divider"

type SectionState = Partial<
	Omit<Record<StageField, string>, "dimensions" | "proscenium"> &
		Record<"dimensions" | "proscenium", DimensionProscenium>
>

interface ProductionSectionProps {
	stageSubcategory: StageSubcategory
	hideDivider?: boolean
}

export const ProductionSection = ({
	stageSubcategory,
	hideDivider = false,
}: ProductionSectionProps) => {
	const { t } = useTranslation()
	const { selectedStage } = useVenuesContext()
	const { setState: setAttachmentState } = useProductionAttachments()
	const {
		state: { stage },
		setState,
	} = useProductionContext()

	const [currentStageId, setCurrentStageId] = useState(stage.id)

	const stageCategory = getCategory(stageSubcategory)

	const [sectionState, setSectionState] = useState<SectionState>(stage[stageCategory] ?? {})
	const [updatedValues, setUpdatedValues] = useState<StageField[]>([])

	const updateState = useCallback(
		(
			categoryField: Exclude<StageField, "dimensions" | "proscenium">,
			e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>,
		) => {
			setSectionState(prev => ({
				...prev,
				[categoryField]: e.target.value,
			}))
			setUpdatedValues(prev => [...prev, categoryField])
		},
		[setSectionState, setUpdatedValues],
	)

	const updateDimensionsState = useCallback(
		(
			categoryField: "dimensions" | "proscenium",
			dimension: "width" | "depth" | "height",
			e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>,
		) => {
			setSectionState(prev => {
				return {
					...prev,
					[categoryField]: {
						...(prev[categoryField] || {}),
						[dimension]: e.target.value,
					},
				}
			})
			setUpdatedValues(prev => [...prev, categoryField])
		},
		[setSectionState, setUpdatedValues],
	)

	const updateProductionState = useCallback(
		(newSectionState: SectionState) => {
			if (updatedValues.length > 0) {
				setState(prev => {
					const previousStageValues = { ...(prev.stage[stageCategory] || {}) }

					updatedValues.forEach(keyVal => {
						if (keyVal === "dimensions" || keyVal === "proscenium") {
							const newValue = {
								...newSectionState[keyVal],
							}
							const oldShallowValue = {
								...(previousStageValues[keyVal] || {}),
							}
							const oldDeepValue = (selectedStage?.[stageCategory] || {})[keyVal]

							if (
								newValue === undefined ||
								getObjectValues(newValue).every(
									val => val === "" || val === undefined || val === null,
								)
							) {
								if (oldDeepValue === undefined) {
									if (previousStageValues[keyVal] !== undefined) {
										delete previousStageValues[keyVal]
									}
								} else if (
									getObjectValues(oldDeepValue).every(
										val => val === "" || val === null || val === undefined,
									)
								) {
									previousStageValues[keyVal] = { ...oldDeepValue }
								} else {
									previousStageValues[keyVal] = {
										width: null,
										depth: null,
										height: null,
									}
								}

								return
							}

							if (
								Object.entries(newValue).find(
									([key, val]) => (val || "") !== (oldShallowValue[key] || ""),
								)
							) {
								previousStageValues[keyVal] = {
									width: newValue.width || null,
									depth: newValue.depth || null,
									height: newValue.height || null,
								}
							}
						} else {
							const newValue = newSectionState[keyVal]
							const oldShallowValue = previousStageValues[keyVal]
							const oldDeepValue = (selectedStage?.[stageCategory] || {})[keyVal]

							if (newValue !== "") {
								if (newValue !== oldShallowValue) {
									previousStageValues[keyVal] = newValue
								}

								return
							}

							if (oldDeepValue === undefined) {
								if (previousStageValues[keyVal] !== undefined) {
									delete previousStageValues[keyVal]
								}
								return
							}

							if (oldDeepValue === "") {
								previousStageValues[keyVal] = ""
							}

							previousStageValues[keyVal] = null
						}
					}, {})

					return {
						...prev,
						stage: {
							...prev.stage,
							[stageCategory]: {
								...previousStageValues,
							},
						},
					}
				})
			}
		},
		[stageCategory, setState, updatedValues, selectedStage],
	)

	useEffect(() => {
		if (stage.id !== currentStageId) {
			setUpdatedValues([])
			setSectionState(stage[stageCategory || {}])
			setCurrentStageId(stage.id)
		}
	}, [stage, setCurrentStageId, currentStageId, stageCategory])

	useDebounce(sectionState, updateProductionState, 500)

	return (
		<>
			<Grid container xs={12} gap={3}>
				<HeaderContainer container lg alignContent="start">
					<Grid xs={12}>
						<h4>{t(stageSubcategory.split("Data")[0])}</h4>
					</Grid>
					<Grid xs={12} container gap={2}>
						<Grid xs={12}>
							<NewButton
								buttonType="Outline"
								text={t("addAttachment")}
								borderColor="secondary"
								iconElement={<AttachFile />}
								buttonStyles={css`
									width: 170px;
								`}
								onClick={() =>
									setAttachmentState(prev => ({
										...prev,
										category: stageCategory,
										categorySection: stageSubcategory,
										addAttachmentModalOpen: true,
										addEditModalState: {
											...prev.addEditModalState,
										},
									}))
								}
							/>
						</Grid>
						<Grid xs={12}>
							<p>
								{t("addStageAttachmentBlurb", {
									section: t(stageSubcategory.split("Data")[0]),
								})}
							</p>
						</Grid>
					</Grid>
				</HeaderContainer>
				<Grid container xs={12} lg gap={2}>
					{stage !== null &&
						STAGE_SUBCATEGORY_KEYS[stageSubcategory].map(val => {
							return val === "dimensions" || val === "proscenium" ? (
								<Grid container xs={12} gap={2} key={`${stageSubcategory}-${val}`}>
									<Grid xs={12}>
										<DimProText>{t(val)}</DimProText>
									</Grid>
									<Grid container xs={12} gap={2}>
										<Grid xs>
											<FormField
												value={sectionState[val]?.width || ""}
												onChange={e => {
													updateDimensionsState(val, "width", e)
												}}
												sx={{
													flexGrow: "1",
												}}
												label={t("width")}
												fullWidth
												data-cy={`production-${val.toLowerCase()}width`}
											/>
										</Grid>
										<Grid xs>
											<FormField
												value={sectionState[val]?.depth || ""}
												onChange={e => {
													updateDimensionsState(val, "depth", e)
												}}
												sx={{
													flexGrow: "1",
												}}
												label={t("depth")}
												fullWidth
												data-cy={`production-${val.toLowerCase()}depth`}
											/>
										</Grid>
										<Grid xs>
											<FormField
												value={sectionState[val]?.height || ""}
												onChange={e => {
													updateDimensionsState(val, "height", e)
												}}
												sx={{
													flexGrow: "1",
												}}
												label={t("height")}
												fullWidth
												data-cy={`production-${val.toLowerCase()}height`}
											/>
										</Grid>
									</Grid>
									{val === "proscenium" && (
										<Grid xs={12}>
											<StyledDivider />
										</Grid>
									)}
								</Grid>
							) : (
								<Grid container xs={12} gap={2} key={`${stageSubcategory}-${val}`}>
									<Grid xs={12}>
										<FormField
											value={sectionState[val] || ""}
											onChange={e => {
												updateState(val, e)
											}}
											multiline
											label={t(val)}
											fullWidth
											maxRows={16}
											data-cy={`${stageCategory
												.replace(/Data$/, "")
												.toLowerCase()}-${val.toLowerCase()}`}
										/>
									</Grid>
									<Grid xs={12}>
										<AttachmentsPreview category={stageCategory} field={val} />
									</Grid>
								</Grid>
							)
						})}
				</Grid>
			</Grid>
			{!hideDivider && <GridDivider />}
		</>
	)
}

const GridDivider = () => {
	return (
		<Grid xs={12}>
			<StyledDivider />
		</Grid>
	)
}

const StyledDivider = styled(Divider)`
	margin: 24px 0px 16px 0px;
	${mediaQuery("lg")`
		margin-left: 24px;
		margin-right: 24px;
	`}
`

const DimProText = styled("h5")`
	font-family: Signika-Medium;
	font-weight: 500;
	font-size: 14px;
	line-height: 20px;
	color: ${({ theme }) => theme.colorPalette.surface.on};
`

const HeaderContainer = styled(Grid)`
	gap: 24px;
	user-select: none;

	max-width: 288px;

	${mediaQuery("lg")`
		max-width: none;
	`}

	& p {
		color: ${({ theme }) => theme.colorPalette.secondary.value};
		font: 500 11px/16px Signika-Medium;
	}

	& h4 {
		font: 400 24px/32px Roboto-Regular;
		color: ${({ theme }) => theme.colorPalette.surface.on};
	}
`
