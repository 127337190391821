import { useTranslation } from "react-i18next"
import NewModal from "@/components/molecules/Modal"
import { FormEventHandler, useCallback } from "react"
import { Cancel, Close } from "@mui/icons-material"
import { useTheme } from "@mui/material"
import { css } from "styled-components"
import { useProductionContext } from "@/context/production"
import { ensureVarIsArray } from "@/util/dataValidation"

export const DeleteVenueContactModal: React.FC = () => {
	const { t } = useTranslation()
	const { colorPalette } = useTheme()

	const {
		state: { venue, deleteVenueContactIndex, deleteContactModalOpen },
		updateVenueContacts,
		setState,
	} = useProductionContext()

	const handleClose = useCallback(
		() => setState((s: ProductionStateInterface) => ({ ...s, deleteContactModalOpen: false })),
		[setState],
	)

	const handleDeleteVenueContact: FormEventHandler<HTMLDivElement> = useCallback(
		e => {
			e.preventDefault()
			updateVenueContacts({
				translationKey: "contactDeleted",
				contacts: ensureVarIsArray(venue.contacts).filter(
					(_, idx) => idx !== deleteVenueContactIndex,
				),
			})
			handleClose()
		},
		[updateVenueContacts, venue.contacts, handleClose, deleteVenueContactIndex],
	)

	return (
		<NewModal
			size="sm"
			open={deleteContactModalOpen}
			closeModal={handleClose}
			titleIcon={<Cancel htmlColor={colorPalette.error.container.on} />}
			title={t("deletePermanently?")}
			borderColor={colorPalette.error.container.on}
			subHeader={t("actionCannotBeUndone")}
			onSubmit={handleDeleteVenueContact}
			component="form"
			id="delete-contact-modal"
			footerActions={{
				end: [
					"Cancel",
					{
						iconElement: <Close htmlColor={colorPalette.error.container.on} />,
						text: t("delete"),
						buttonType: "Outline",
						buttonStyles: css`
							border-color: ${colorPalette.error.container.on};
							color: ${colorPalette.error.container.on};
						`,
						textStyles: css`
							color: ${colorPalette.error.container.on};
						`,
						type: "submit",
						form: "delete-contact-modal",
					},
				],
			}}
		/>
	)
}
