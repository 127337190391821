import { styled } from "@mui/material"
import useTechPack from "../../../hooks/useTechPack"
import useWindowDimensions from "../../../hooks/useWindowDimensions"
import { mediaQuery } from "@/util/stylingUtils"
import { BREAKPOINTS } from "@/util/constants/BREAKPOINTS"
import AvatarImage from "../../molecules/AvatarImage"
import { useTranslation } from "react-i18next"
import { getDisplayName } from "@/util/userUtils"

export default function Contacts() {
	const { t } = useTranslation()
	const { width } = useWindowDimensions()
	const { personnel } = useTechPack()
	const imageSize = width < BREAKPOINTS.lg ? 60 : 100
	const sortedPersonnel = personnel.sort((a, b) => (a.order ?? 0) - (b.order ?? 0))

	return (
		<>
			{sortedPersonnel.map(person => {
				const { userId, title, email, name, phone, fileAttachments } = person

				const profilePictureUrl = fileAttachments.find(
					val =>
						typeof val.file.publicUrls?.urlPreview === "string" &&
						val.file.status?.upload === "Complete",
				)
				const displayName = getDisplayName(name, false)

				return (
					<ContactSectionContainer key={userId} id={`contacts-${displayName.replace(/ /g, "")}`}>
						<LeftContactContainer>
							<AvatarImage
								staff={{
									id: userId,
									imageUrl:
										profilePictureUrl === undefined
											? profilePictureUrl
											: profilePictureUrl.file.publicUrls?.urlPreview,
									person: {
										name: {
											displayName: displayName,
										},
									},
								}}
								height={imageSize}
								width={imageSize}
								techPack={true}
							/>
						</LeftContactContainer>
						<RightContactContainer>
							<ContactTitle>
								<strong>{t(title)}</strong>
							</ContactTitle>
							<ContactName>{displayName}</ContactName>
							{!!phone && (
								<>
									<a href={`tel:${phone}`}>{phone}</a>
									<br />
								</>
							)}
							{!!email && <a href={`mailto:${email}`}>{email}</a>}
						</RightContactContainer>
					</ContactSectionContainer>
				)
			})}
		</>
	)
}

const ContactSectionContainer = styled("div")`
	align-items: center;
	display: flex;
	margin-top: 30px;
	width: 50%;

	${mediaQuery("lg")`
		width: 100%;
	`}
`
const LeftContactContainer = styled("div")`
	align-items: center;
	display: flex;
	margin-right: 2rem;
	height: 100px;
	width: 100px;

	${mediaQuery("lg")`
		height: 60px;
		width: 60px;
		margin-right: 1rem;
	`}
`
const RightContactContainer = styled("div")`
	font-family: "Signika-Regular" !important;
	font-size: 18px;
	white-space: pre-wrap;
	word-wrap: break-word;
	word-break: break-word;
	width: 100%;

	& > a {
		color: ${({ theme }) => theme.colorPalette.primary.value};
		font-family: "Signika-Regular" !important;
	}

	${mediaQuery("lg")`
		font-family: "Signika-Regular" !important;
		font-size: 13px;
	`}
`
const ContactTitle = styled("p")`
	margin: 0;

	> strong {
		font-family: "Signika-Regular" !important;
	}

	${mediaQuery("lg")`
		font-size: 15px;
	`}
`
const ContactName = styled("p")`
	font-family: "Signika-Regular" !important;
	margin: 0;
`
