import NewModal from "../../molecules/Modal"
import { VisibilityOffOutlined } from "@mui/icons-material"
import { useTranslation } from "react-i18next"
import { css, useTheme } from "styled-components"
import { toast } from "react-toastify"
import { useVenuesContext } from "@/context/venues"

type DeactivateStageModalProps = {
	modalOpen: boolean
	closeModal: () => void
	deactivateStageId: string | null
}

export const DeactivateStageModal: React.FC<DeactivateStageModalProps> = ({
	modalOpen,
	closeModal,
	deactivateStageId,
}) => {
	const theme = useTheme()
	const { t } = useTranslation()
	const { patchStage } = useVenuesContext()

	const handleSubmit = (e: React.FormEvent) => {
		e.preventDefault()

		if (deactivateStageId === null) {
			toast.error(t("tryAgain"))
		} else {
			patchStage(
				{
					stageId: deactivateStageId,
					updateData: {
						isActive: 0,
					},
				},
				{
					onSuccess: () => {
						closeModal()
					},
				},
			)
		}
	}

	return (
		<NewModal
			size="sm"
			open={modalOpen}
			closeModal={closeModal}
			titleIcon={<VisibilityOffOutlined htmlColor={theme.colorPalette.error.container.on} />}
			title={t("deactivateStage?")}
			borderColor={theme.colorPalette.error.container.on}
			subHeader={t("deactivateStageWarning")}
			onSubmit={handleSubmit}
			component="form"
			id="deactivate-modal"
			footerActions={{
				end: [
					{
						text: t("Cancel"),
						buttonType: "Text",
						onClick: closeModal,
						"data-cy": "venuestagemodal-cancel",
					},
					{
						iconElement: <VisibilityOffOutlined />,
						text: t("deactivate"),
						buttonType: "Outline",
						buttonStyles: css`
							border-color: ${theme.colorPalette.error.container.on};
							color: ${theme.colorPalette.error.container.on};
						`,
						textStyles: css`
							color: ${theme.colorPalette.error.container.on};
						`,
						type: "submit",
						form: "deactivate-modal",
						"data-cy": "venuestagemodal-submit",
					},
				],
			}}
		/>
	)
}
