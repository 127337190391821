import {
	Fade,
	Modal as MuiModal,
	ModalProps as MuiModalProps,
	SxProps,
	styled,
} from "@mui/material"
import { forwardRef, ForwardRefRenderFunction, ReactElement, useRef } from "react"
import NewButton, { ButtonProps } from "../atoms/NewButton"
import { useTranslation } from "react-i18next"
import { mediaQuery } from "@/util/stylingUtils"
import { BREAKPOINTS } from "@/util/constants/BREAKPOINTS"

/** Modal sizes pulled from figma */
const MODAL_SIZE = {
	sm: "312",
	md: "552",
	lg: "624",
	xl: "800",
	"2xl": "904",
} as const

export type ModalProps = {
	/** Sets width and corresponding spacing styles of the modal
	 * - Input a number for a custom width in px instead of using presets
	 */
	size: keyof typeof MODAL_SIZE | number

	/** Boolean value on if the modal is currently open */
	open: boolean

	/** Function that closes the modal */
	closeModal: () => void

	/** HTML color of the outer border of the modal (1px solid) */
	borderColor?: string

	/** SVG Icon displayed at the top of the modal */
	titleIcon?: JSX.Element

	/** Title of the element and text displayed as header below the titleIcon */
	title?: string

	/** Text or span element to go in the <p> tag below the title */
	subHeader?: string | ReactElement

	/** Text or span element placed in the <small> tag below the subHeader */
	hint?: string | ReactElement

	/** Content of the Modal */
	children?: React.ReactNode

	/** Props for buttons to be used in the footer of the modal */
	footerActions?: {
		/** List of button props that floats left
		 * - "Cancel" is replaced with a default cancel button that closes the modal
		 * */
		start?: (ButtonProps<any> | "Cancel")[]

		/** List of button props that floats right
		 * - "Cancel" is replaced with a default cancel button that closes the modal
		 * */
		end?: (ButtonProps<any> | "Cancel")[]
	}

	containerSx?: SxProps
} & Omit<MuiModalProps, "children" | "onClose" | "open">

const Modal: ForwardRefRenderFunction<HTMLDivElement, ModalProps> = (
	{
		open,
		title,
		closeModal,
		size,
		titleIcon,
		footerActions,
		children,
		borderColor,
		subHeader,
		hint,
		containerSx,
		...rest
	},
	ref,
) => {
	const { t } = useTranslation()
	const divRef = useRef<HTMLDivElement>(null)

	return (
		<MuiModal
			open={open}
			onClose={closeModal}
			closeAfterTransition
			{...rest}
			ref={ref}
			sx={{
				overflow: "scroll",
				paddingTop: "5vw",
				paddingBottom: "5vw",
				...(rest.sx ?? {}),
			}}
		>
			<Fade in={open}>
				<ModalContainer size={size} borderColor={borderColor} ref={divRef} sx={containerSx}>
					{(title || titleIcon) && (
						<TitleContainer titleIcon={titleIcon}>
							{titleIcon}
							{title && <h2>{title}</h2>}
						</TitleContainer>
					)}
					{(subHeader || hint) && (
						<SubTitleContainer size={size} hasChildren={children !== undefined}>
							{subHeader && <p>{subHeader}</p>}
							{hint && <small>{hint}</small>}
						</SubTitleContainer>
					)}

					{children && <ContentContainer id="content-container">{children}</ContentContainer>}

					<FooterContainer>
						<FooterPartial variant={"start"}>
							{footerActions?.start?.map((value, idx) => {
								if (value === "Cancel") {
									return (
										<NewButton
											key={idx}
											text="Cancel"
											onClick={closeModal}
											buttonType="Text"
											data-cy-component="modal-StartCancelButton"
										/>
									)
								}
								return <NewButton key={idx} {...value} />
							})}
						</FooterPartial>

						<FooterPartial variant={"end"}>
							{footerActions?.end?.map((value, idx) => {
								if (value === "Cancel") {
									return (
										<NewButton
											key={idx}
											text={t("Cancel")}
											onClick={closeModal}
											buttonType="Text"
											data-cy-component="modal-EndCancelButton"
										/>
									)
								}
								return <NewButton key={idx} {...value} />
							})}
						</FooterPartial>
					</FooterContainer>
				</ModalContainer>
			</Fade>
		</MuiModal>
	)
}

const FooterContainer = styled("div")`
	width: 100%;
	display: flex;
	justify-content: space-between;
	align-items: center;
	gap: 0px;
	padding: 24px 0px 0px 0px;

	${mediaQuery("lg")`
		flex-direction: column;
		justify-content: start;
		align-items: center;
	`}
`

const FooterPartial = styled("div", {
	shouldForwardProp: prop => prop !== "variant",
})<{ variant: "start" | "end" }>`
	width: auto;
	height: 100%;
	display: flex;
	justify-content: ${({ variant }) => variant};
	align-items: center;
	gap: 8px;

	${mediaQuery("lg")`
		width: 100%;
		
	`}
`

const ContentContainer = styled("div")`
	width: 100%;
	padding: 0px;
`

const TitleContainer = styled("div", {
	shouldForwardProp: prop => prop !== "titleIcon",
})<{ titleIcon?: ReactElement }>`
	display: flex;
	flex-direction: column;
	justify-content: start;
	align-items: center;

	user-select: none;

	width: 100%;

	padding: 0px 0px 16px 0px;
	gap: 16px;

	& > svg.MuiSvgIcon-root {
		font-size: 24px;
		color: ${({ titleIcon, theme }) =>
			titleIcon?.props["htmlColor"] === undefined
				? theme.colorPalette.secondary.value
				: titleIcon.props["htmlColor"]};
	}

	& > h2 {
		font: 400 24px/32px Signika-Regular;
		text-align: center;
		color: ${({ theme }) => theme.colorPalette.surface.on};
	}
`

const SubTitleContainer = styled("div", {
	shouldForwardProp: prop => prop !== "hasChildren" && prop !== "size",
})<{
	hasChildren?: boolean
	size: keyof typeof MODAL_SIZE | number
}>`
	display: flex;
	flex-direction: column;
	justify-content: start;
	align-items: center;

	user-select: none;
	gap: 16px;
	padding: ${({ hasChildren }) => (hasChildren ? "0px 0px 16px 0px" : "0px")};

	@media (max-width: ${BREAKPOINTS.md}px) {
		padding: ${({ hasChildren }) => (hasChildren ? "8px 0px 16px 0px" : "0px")};
	}

	& > p {
		font: 400 14px/20px Signika-Regular;
		color: ${({ theme }) => theme.colorPalette.surface.onVariant};
		text-align: center;
		padding: ${({ size }) =>
			size === "sm" ? "0px 16px 0px 16px" : size === "md" ? "0px 32px 0px 32px" : "0px"};

		@media (max-width: ${BREAKPOINTS.md}px) {
			padding: ${({ size }) =>
				size === "sm" ? "0px 16px 0px 16px" : size === "md" ? "0px 16px 0px 16px" : "0px"};
		}

		transition: padding 300ms ease-out !important;
	}

	& > small {
		font: 400 12px/16px Signika-Regular;
		color: ${({ theme }) => theme.colorPalette.surface.onVariant};
		text-align: left;
		padding: ${({ size }) => (size === "md" ? "0px 32px 0px 32px" : "0px")};

		@media (max-width: ${BREAKPOINTS.md}px) {
			padding: ${({ size }) => (size === "md" ? "0px 16px 0px 16px" : "0px")};
		}

		&::before {
			content: "*";
		}

		transition: padding 300ms ease-out !important;
	}

	& b {
		font: 600 14px/20px Signika-Semibold;
	}
`

const ModalContainer = styled("div", {
	shouldForwardProp: prop => prop !== "size" && prop !== "borderColor",
})<{
	size: keyof typeof MODAL_SIZE | number
	borderColor?: string
}>`
	outline: none;
	position: relative;

	top: clamp(0px, 50%, 50%);
	left: 50%;
	transform: translate(-50%, clamp(calc((50vh - 5vw) / -1), -50%, -50%));
	padding: 24px;

	width: ${({ size }) => (typeof size === "number" ? size : MODAL_SIZE[size])}px;
	max-width: ${({ size }) => (typeof size === "number" ? size + "px" : "90%")};

	border: ${({ borderColor }) => (borderColor ? `1px solid ${borderColor}` : "none")};

	border-radius: 28px;
	background-color: ${({ theme }) => theme.colorPalette.surface.container.high};
	box-shadow: 0px 1px 3px 0px #0000004d, 0px 4px 8px 3px #00000026;

	transition: height 300ms ease-out !important;
`

export default forwardRef(Modal)
